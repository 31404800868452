import PropTypes from 'prop-types';
import React from 'react';
import { FiMenu } from 'react-icons/fi';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import * as actions from '../actions';
import BreadcrumbList from './Breadcrumbs/BreadcrumbList';
import BreadcrumbListItem from './Breadcrumbs/BreadcrumbListItem';
import { all } from '../data';
import { capitalizeFirstLetter } from '../helpers';

const Breadcrumb = ({ location, toggleSideMenu }) => {
  const { pathname } = location;
  const urlParts = pathname.split('/');
  const urlsPartsLength = urlParts.length;
  const item = all.find(a => a.path === pathname);
  const main = urlParts[1];

  return (
    <BreadcrumbList>
      <button
        aria-label="Toggle side menu"
        className="menu-button"
        onClick={toggleSideMenu}
        title="Toggle side menu"
      >
        <FiMenu />
      </button>
      <BreadcrumbListItem>
        <Link
          aria-label="Parker Klein's Homepage"
          itemProp="item"
          to="/"
          title="Parker Klein's Homepage"
        >
          <h3 itemProp="name">Parker Klein</h3>
        </Link>
        <meta itemProp="position" content="1" />
      </BreadcrumbListItem>
      <li>/</li>
      {!main ? (
        <li>
          <h1>Home</h1>
        </li>
      ) : urlsPartsLength > 2 ? (
        <BreadcrumbListItem>
          <Link
            aria-label={capitalizeFirstLetter(main)}
            itemProp="item"
            to={`/${main}`}
            title={capitalizeFirstLetter(main)}
          >
            <h3 itemProp="name">{capitalizeFirstLetter(main)}</h3>
          </Link>
          <meta itemProp="position" content="2" />
        </BreadcrumbListItem>
      ) : (
        <li>
          <h1>{capitalizeFirstLetter(main)}</h1>
        </li>
      )}
      {!!item && <li>/</li>}
      {!!item && (
        <li>
          <h1>{item.name}</h1>
        </li>
      )}
    </BreadcrumbList>
  );
};

Breadcrumb.propTypes = {
  location: PropTypes.object.isRequired,
  toggleSideMenu: PropTypes.func.isRequired
};

export default withRouter(connect(null, { ...actions })(Breadcrumb));

export default {
  category: 'book',
  name: 'The Bullet Journal Method',
  author: 'Ryder Carroll',
  date: '10/12/22',
  path: '/notes/the-bullet-journal-method',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-bullet-journal-method-ryder-carroll.jpg',
  tags: ['Management', 'Self-help'],
  time: 37,
  rating: 7.9,
  review: '',
  link: 'https://www.amazon.com/Bullet-Journal-Method-Present-Design/dp/0525533338',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  twosLink: 'https://www.twosapp.com/6321b3063bff6898777ff07a',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};

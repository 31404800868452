export default {
  author: 'Robert B. Cialdini, PH.D.',
  category: 'book',
  date: '9/21/19',
  link: 'https://www.amazon.com/Influence-Psychology-Persuasion-Robert-Cialdini/dp/006124189X',
  name: 'Influence',
  path: '/notes/influence',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-influence-robert-b-cialdini-phd.jpg',
  tags: ['Marketing', 'Sales', 'Psychology'],
  time: 16,
  rating: 7.4,
  review:
    'A book on how to influence people and the history and science behind it. There are many factors that cause us to be influences and knowing these tips is helpful.'
};

export default {
  author: 'Lea Verou',
  category: 'book',
  date: '4/24/18',
  link: 'https://amzn.to/2lgl2Pl',
  name: 'CSS Secrets',
  path: '/notes/css-secrets',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-css-secrets-lea-verou.jpg',
  tags: ['Web Development', 'CSS'],
  time: 4,
  rating: 6.6,
  review:
    "Great resource for someone who is new to CSS or uses it all the time. A great refresher and you'll take away a few tricks you may have missed or forgottenn about."
};

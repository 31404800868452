export default {
  category: 'write',
  date: '11/24/20',
  name: 'How to raise the community’s level of intelligence',
  path: '/write/how-to-raise-the-communitys-level-of-intelligence',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-how-to-raise-the-communitys-level-of-intelligence.jpg',
  podcastLink: '',
  youtubeLink: '',
twosLink: '',
  mediumLink: '',
  patreonLink: '',
  tags: ['Write'],
  time: 1
};

export default {
  category: 'book',
  name: 'The Power of Writing It Down',
  author: 'Allison Fallon',
  date: '9/8/22',
  path: '/notes/the-power-of-writing-it-down',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-power-of-writing-it-down-allison-fallon.jpg',
  tags: ['Writing', 'Self-help'],
  time: 17,
  rating: 6.2,
  review: '',
  link: 'https://www.amazon.com/Power-Writing-Down-Simple-Reimagine/dp/0310359341',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  twosLink: 'https://www.twosapp.com/62f4f4ede9a40b19fb4ea003',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};

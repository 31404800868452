export default {
  author: 'William H. McRaven',
  category: 'book',
  date: '1/8/19',
  link: 'https://amzn.to/2lauIuz',
  name: 'Make Your Bed',
  path: '/notes/make-your-bed',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-make-your-bed-william-mcraven.jpg',
  tags: ['Self-help'],
  time: 8,
  rating: 7.1,
  review:
    'Short book about how doing the small things right sets you up for doing bigger things better. Caution: you may start making your bed every morning.',
  mediumLink: '',
  patreonLink: '',
  podcastLink: 'https://anchor.fm/parkerklein/episodes/Make-Your-Bed-by-William-H--McRaven-edvn6k',
  podcastEmbed:
    'https://anchor.fm/parkerklein/embed/episodes/Make-Your-Bed-by-William-H--McRaven-edvn6k',
  youtubeLink: 'https://youtu.be/2SZDKwNX0CU',
  youtubeEmbed: 'https://www.youtube.com/embed/2SZDKwNX0CU',
  releaseDate: '5/18/20'
};

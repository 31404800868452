export default {
  author: 'Matthew Kelly',
  category: 'book',
  date: '12/6/18',
  link: 'https://amzn.to/2k1n4m7',
  name: 'Perfectly Yourself',
  path: '/notes/perfectly-yourself',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-perfectly-yourself-matthew-kelly.jpg',
  tags: ['Self-help', 'Religion', 'Christianity'],
  time: 9,
  rating: 6.7,
  review:
    'A book about being your best self. Focus on what you can control and be internally driven. You know what you want and you have the power and discipline to do those things.'
};

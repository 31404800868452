export default {
  author: 'J. D. Vance',
  category: 'book',
  date: '2/20/17',
  name: 'HillBilly Elegy',
  path: '/notes/hillbilly-elegy',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-hillbilly-elegy-j-d-vance.jpg',
  tags: ['Memoir', 'Social Science', 'Politics'],
  time: 0,
  link: 'https://www.amazon.com/Hillbilly-Elegy-Memoir-Family-Culture/dp/0062300547',
  mediumLink: '',
  patreonLink: '',
  podcastLink: '',
  podcastEmbed: '',
  youtubeLink: '',
twosLink: '',
  youtubeEmbed: '',
  releaseDate: '',
  rating: 5.8,
  review:
    'A fascinating read on how disadvantaged people are in middle and south America. Technology will, hopefully, help even out this gap through access to information and new perspectives.'
};

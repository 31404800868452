export default {
  author: 'Joe Girard',
  category: 'book',
  date: '3/12/17',
  link: 'https://amzn.to/2jNJEOO',
  name: 'How to Sell Anything to Anyone',
  path: '/notes/how-to-sell-anything-to-anyone',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-how-to-sell-anything-to-anyone-joe-girard.jpg',
  tags: ['Business', 'Sales'],
  time: 2,
  mediumLink: '',
  patreonLink: '',
  podcastLink:
    'https://anchor.fm/parkerklein/episodes/How-to-Sell-Anything-to-Anyone-by-Joe-Girard-e92n1s',
  podcastEmbed:
    'https://anchor.fm/parkerklein/embed/episodes/How-to-Sell-Anything-to-Anyone-by-Joe-Girard-e92n1s',
  youtubeLink: 'https://youtu.be/5qbLqTAKfC4',
  youtubeEmbed: 'https://www.youtube.com/embed/5qbLqTAKfC4',
  releaseDate: '11/18/19',
  rating: 7.1,
  review:
    'Techniques on how to establish a sales loop and provide real value to your customers. Written by one of the best car salesmen of all-time. He discusses how he did it and how you can too.'
};

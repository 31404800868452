export default {
  category: 'Work',
  startDate: '9/1/17',
  endDate: '6/1/19',
  name: 'Trustwork',
  position: 'Engineer Hustler',
  androidUrl: 'https://play.google.com/store/apps/details?id=com.trustwork.trustwork',
  iOSUrl: 'https://itunes.apple.com/us/app/trustwork/id1383914588',
  logo: 'https://twos.s3.amazonaws.com/trustwork-logo.jpg',
  url: 'https://www.trustwork.com',
  path: '/work/trustwork',
  tags: ['Work'],
  tech: [
    'JavaScript',
    'React',
    'React Native',
    'Styled Components',
    'MongoDB',
    'Ruby on Rails',
    'Heroku',
    'AWS',
    'Redux',
    'Elasticsearch',
    'Pusher',
    'Heap'
  ],
  accomplishments: [
    'Built a re-usable UI library used by front-end engineers to rapidly develop our web and mobile apps.',
    'Helped build Trustwork onboarding, marketplace, profiles, payments, reviews, businesses, posts, and messaging.',
    'Mentored one front-end developer.',
    'Helped maintain the front-end engineering docs.'
  ],
  location: 'San Francisco, CA, Austin, TX, and Bellevue, WA'
};

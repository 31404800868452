export default {
  author: 'Epictetus',
  category: 'book',
  date: '7/29/18',
  link: 'https://amzn.to/2jMe97X',
  name: 'Enchiridion',
  path: '/notes/enchiridion',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-enchiridion-epictetus.jpg',
  tags: ['Ethics', 'Philosophy'],
  time: 13,
  rating: 8.3,
  review:
    'I love stoicism and can never get enough of their poise around others and their self-discipline. Super helpful for not losing your mind in public and treating yourself well. Very practical philosophy.',
  mediumLink: '',
  patreonLink: '',
  podcastLink: 'https://anchor.fm/parkerklein/episodes/Enchiridion-by-Epictetus-e9ijoj',
  podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/Enchiridion-by-Epictetus-e9ijoj',
  youtubeLink: 'https://youtu.be/Lj6fu1Bjwio',
  youtubeEmbed: 'https://www.youtube.com/embed/Lj6fu1Bjwio',
  releaseDate: '12/16/19'
};

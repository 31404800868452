export default {
  author: 'Andrew Hunt',
  category: 'book',
  date: '8/20/17',
  link: 'https://www.amazon.com/Pragmatic-Programmer-Journeyman-Master/dp/020161622X',
  name: 'The Pragmatic Programmer',
  path: '/notes/the-pragmatic-programmer',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-pragmatic-programmer-andrew-hunt.jpg',
  tags: ['Computer Science', 'Software Development'],
  time: 9,
  rating: 8.2,
  review:
    "One of my favorite books on programming. A very thorough book on principles and tips to consider and use while programming. It'll make your life and your coworkers lives easier."
};

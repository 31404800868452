export default {
  author: 'Tim Ferriss',
  category: 'Book',
  date: '8/15/18',
  link: 'https://amzn.to/2lwjil8',
  name: 'The 4-Hour Workweek',
  path: '/notes/the-4-hour-workweek',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-4-hour-workweek-tim-ferriss.jpg',
  tags: ['Business'],
  time: 14,
  rating: 7.6,
  review:
    'Changes your perspective around work and working smarter. Focus on the things that allow you to make the biggest impact. Free up more time to live your life.'
};

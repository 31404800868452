import React from 'react';
import {
  FaAmazon,
  FaApple,
  FaCoffee,
  FaEnvelope,
  FaFacebookF,
  FaGithub,
  FaInstagram,
  FaLinkedin,
  FaMedium,
  FaPatreon,
  FaPodcast,
  FaSpotify,
  FaTwitter,
  FaYoutube
} from 'react-icons/fa';
import { SiSubstack } from 'react-icons/si';

const Links = () => (
  <div className="side-menu-links">
    <a
      aria-label="Parker Klein's YouTube channel"
      className="icon-link youtube"
      data-tip="YouTube"
      href="https://www.youtube.com/channel/UCmVKO9VkVgRmF5We3mF0YWg"
      id="YouTube"
      rel="noopener noreferrer"
      target="_blank"
      title="Parker Klein's YouTube channel"
    >
      <FaYoutube />
    </a>
    <a
      aria-label="Parker Klein's Instagram page"
      className="icon-link instagram"
      data-tip="Instagram"
      href="https://www.instagram.com/twosballer"
      id="Instagram"
      rel="noopener noreferrer"
      target="_blank"
      title="Parker Klein's Instagram page"
    >
      <FaInstagram />
    </a>
    <a
      aria-label="Parker Klein's Patreon page"
      className="icon-link patreon"
      data-tip="Patreon"
      href="https://www.patreon.com/parkerklein"
      id="Patreon"
      rel="noopener noreferrer"
      target="_blank"
      title="Parker Klein's Patreon page"
    >
      <FaPatreon />
    </a>
    <a
      aria-label="Parker Klein's SubStack account"
      className="icon-link substack"
      data-tip="SubStack"
      href="https://parkerklein.substack.com/"
      rel="noopener noreferrer"
      target="_blank"
      title="Parker Klein's SubStack account"
    >
      <SiSubstack />
    </a>
    <a
      aria-label="Parker Klein's Buy Me A Coffee page"
      className="icon-link coffee"
      data-tip="Buy Me A Coffee"
      href="https://www.buymeacoffee.com/xJUZB6b"
      id="BuyMeACoffee"
      rel="noopener noreferrer"
      target="_blank"
      title="Parker Klein's Buy Me A Coffee page"
    >
      <FaCoffee />
    </a>
    <a
      aria-label="Parker Klein's Amazon affiliate link"
      className="icon-link amazon"
      data-tip="Amazon"
      href="https://amzn.to/2JUTDuQ"
      id="Amazon"
      rel="noopener noreferrer"
      target="_blank"
      title="Parker Klein's Amazon affiliate link"
    >
      <FaAmazon />
    </a>
    <a
      aria-label="Parker Klein's Notes Spotify page"
      className="icon-link spotify"
      data-tip="Spotify"
      href="https://open.spotify.com/show/0vpDlWIKLgD1Myk6VjTawH"
      id="Spotify"
      rel="noopener noreferrer"
      target="_blank"
      title="Parker Klein's Notes Spotify page"
    >
      <FaSpotify />
    </a>
    <a
      aria-label="Parker Klein's Twitter account"
      className="icon-link twitter"
      data-tip="Twitter"
      href="http://twitter.com/twosballer"
      rel="noopener noreferrer"
      target="_blank"
      title="Parker Klein's Twitter account"
    >
      <FaTwitter />
    </a>
    <a
      aria-label="Parker Klein's LinkedIn account"
      className="icon-link linkedin"
      data-tip="LinkedIn"
      href="http://www.linkedin.com/in/parkeraklein"
      id="LinkedIn"
      rel="noopener noreferrer"
      target="_blank"
      title="Parker Klein's LinkedIn account"
    >
      <FaLinkedin />
    </a>
    <a
      aria-label="Parker Klein's Facebook account"
      className="icon-link facebook"
      data-tip="Facebook"
      href="https://www.facebook.com/parker.klein.9/"
      rel="noopener noreferrer"
      target="_blank"
      title="Parker Klein's Facebook account"
    >
      <FaFacebookF />
    </a>
    <a
      aria-label="Parker Klein's Notes Podcast"
      className="icon-link podcast"
      data-tip="Podcast"
      href="https://anchor.fm/parkerklein"
      id="Anchor"
      rel="noopener noreferrer"
      target="_blank"
      title="Parker Klein's Notes Podcast"
    >
      <FaPodcast />
    </a>
    <a
      aria-label="Parker Klein's iOS Developer Account"
      className="icon-link apple"
      data-tip="Apple"
      href="https://itunes.apple.com/us/developer/twos-technologies-llc/id1097350933"
      id="Apple"
      rel="noopener noreferrer"
      target="_blank"
      title="Parker Klein's iOS Developer Account"
    >
      <FaApple />
    </a>
    <a
      aria-label="Parker Klein's Github page"
      className="icon-link github"
      data-tip="Github"
      href="http://github.com/SanKlein"
      id="Github"
      rel="noopener noreferrer"
      target="_blank"
      title="Parker Klein's Github page"
    >
      <FaGithub />
    </a>
    <a
      aria-label="Parker Klein's Medium page"
      className="icon-link medium"
      data-tip="Medium"
      href="https://parkerklein.medium.com"
      id="Medium"
      rel="noopener noreferrer"
      target="_blank"
      title="Parker Klein's Medium page"
    >
      <FaMedium />
    </a>
    <a
      aria-label="Parker Klein's email address"
      className="icon-link"
      data-tip="Email"
      href="mailto:paklein3@gmail.com?subject=hi"
      id="Email"
      itemProp="email"
      rel="noopener noreferrer"
      title="Parker Klein's email address"
    >
      <FaEnvelope />
    </a>
    <a
      aria-label="Parker Klein's Twos profile"
      className="icon-link"
      data-tip="Twos"
      href="https://www.TwosApp.com/@parker"
      id="Twos"
      rel="noopener noreferrer"
      title="Parker Klein's Twos profile"
    >
      <img
        alt="Twos: days, lists, & things"
        src="https://twos.s3.amazonaws.com/twos-thread-background-transparent-300.png"
        height="34px"
        width="23px"
      />
    </a>
  </div>
);

export default Links;

// src/localStorage.js

// load state from local storage
export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('parkerklein');
    if (serializedState === null) return undefined;
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

// save state in local storage
export const saveState = state => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('parkerklein', serializedState);
  } catch (err) {
    return err;
  }
};

export default {
  category: 'book',
  name: 'The Tao of Wu',
  author: 'The RZA',
  date: '1/17/21',
  path: '/notes/the-tao-of-wu',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-tao-of-wu-the-rza.jpg',
  tags: ['Art', 'Philosophy', 'Taoism', 'Music', 'Hip-hop'],
  time: 9,
  rating: 7.2,
  review: '',
  link: 'https://www.amazon.com/Tao-Wu-RZA/dp/1594484856',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
twosLink: 'https://www.TwosApp.com/5ff35c7be0f3403bc78954bf',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: ''
};

export default {
  category: 'book',
  name: 'Willpower',
  author: 'Roy Baumeister',
  date: '7/12/23',
  path: '/notes/willpower',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-willpower-roy-baumeister.jpg',
  tags: ['Health', 'Diet', 'Mental health', 'Self-help', 'Motivation'],
  time: 10,
  rating: 8.3,
  review: '',
  link: 'https://www.amazon.com/Willpower-Rediscovering-Greatest-Human-Strength/dp/0143122231',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  twosLink: 'https://www.twosapp.com/6497030b173e72720110b4c1',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};

export default {
  category: 'write',
  date: '7/7/20',
  name: 'Do the opposite',
  path: '/write/do-the-opposite',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-do-the-opposite.jpg',
  podcastLink: '',
  youtubeLink: '',
twosLink: '',
  mediumLink: 'https://parkerklein.medium.com/do-the-opposite-72a6af104292',
  patreonLink: '',
  tags: ['Write'],
  time: 1
};

export default {
  category: 'book',
  name: 'Things Fall Apart',
  author: 'Chinua Achebe',
  date: '4/2/20',
  path: '/notes/things-fall-apart',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-things-fall-apart-chinua-achebe.jpg',
  tags: ['Fiction'],
  time: 1,
  rating: 5.4,
  review:
    'A story about a village in Africa, their history, their beliefs, and their struggle with intruders.',
  link: 'https://www.amazon.com/Things-Fall-Apart-Chinua-Achebe/dp/0385474547',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
twosLink: '',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: ''
};

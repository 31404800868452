export default {
  author: 'Josh Waitzkin',
  category: 'book',
  date: '12/31/18',
  link: 'https://amzn.to/2lnnSlr',
  name: 'The Art of Learning',
  path: '/notes/the-art-of-learning',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-art-of-learning-josh-waitzkin.jpg',
  tags: ['Sports', 'Biography'],
  time: 10,
  rating: 7.3,
  review:
    'A book about deliberate practice and becoming the best at chess. Deliberate practice is a great way to approach learning and I highly recommend reading this if you are unfamiliar with the concept so you can learn more, more quickly.',
  mediumLink: '',
  patreonLink: '',
  podcastLink: 'https://anchor.fm/parkerklein/episodes/The-Art-of-Learning-by-Josh-Waitzkin-ebul1i',
  podcastEmbed:
    'https://anchor.fm/parkerklein/embed/episodes/The-Art-of-Learning-by-Josh-Waitzkin-ebul1i',
  youtubeLink: 'https://youtu.be/YBPJ6-LjWVw',
  youtubeEmbed: 'https://www.youtube.com/embed/YBPJ6-LjWVw',
  releaseDate: '4/13/20'
};

import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FaFilter } from 'react-icons/fa';
import { FiX } from 'react-icons/fi';
import { connect } from 'react-redux';

import * as actions from '../actions';
import { tags } from '../data';

const Search = ({
  changeSearch,
  clearSearch,
  noFilters,
  search,
  setSort,
  setTag,
  sort,
  tag,
  withSort
}) => {
  const [showFilters, setShowFilters] = useState(false);

  if (showFilters) global.window.body.style.overflow = 'hidden';
  else {
    if (global.window && global.window.body && global.window.body.style) {
      global.window.body.style.overflow = 'scroll';
    }
  }

  return (
    <div>
      <div className="row">
        <div className="search-row">
          <input
            aria-label="Search"
            className="search"
            onChange={e => changeSearch(e.target.value)}
            placeholder="Search..."
            value={search}
            type="text"
          />
          {search && (
            <button
              alt="Clear Search"
              aria-label="Clear Search"
              className="clear"
              data-tip="Clear"
              onClick={() => clearSearch()}
              title="Clear Search"
            >
              <FiX />
            </button>
          )}
        </div>
        {!noFilters && (
          <button
            aria-label="Filters"
            className="filter-icon"
            data-tip="Filters"
            onClick={() => setShowFilters(true)}
            title="Filters"
          >
            <FaFilter />
          </button>
        )}
        {showFilters && (
          <div className="overlay" onClick={() => setShowFilters(false)}>
            <div className="modal-content">
              <div className="modal-header">
                <button
                  aria-label="Close modal"
                  className="close-modal"
                  data-tip="Close modal"
                  onClick={() => setShowFilters(false)}
                  title="Close modal"
                >
                  <FiX />
                </button>
                <div className="modal-title">Order by:</div>
                <div className="row sort-row">
                  <button
                    alt="Order by Date"
                    aria-label="Order by Date"
                    className={`tag ${sort === 'date' ? 'tag-selected' : ''}`}
                    onClick={() => {
                      setSort('date');
                      setShowFilters(false);
                    }}
                    title="Order by Date"
                  >
                    Date
                  </button>{' '}
                  <button
                    alt="Order by Title"
                    aria-label="Order by Title"
                    className={`tag ${sort === 'title' ? 'tag-selected' : ''}`}
                    onClick={() => {
                      setSort('title');
                      setShowFilters(false);
                    }}
                    title="Order by Title"
                  >
                    Title
                  </button>{' '}
                  <button
                    alt="Order by Author"
                    aria-label="Order by Author"
                    className={`tag ${sort === 'author' ? 'tag-selected' : ''}`}
                    onClick={() => {
                      setSort('author');
                      setShowFilters(false);
                    }}
                    title="Order by Author"
                  >
                    Author
                  </button>{' '}
                  <button
                    alt="Order by Rating"
                    aria-label="Order by Rating"
                    className={`tag ${sort === 'rating' ? 'tag-selected' : ''}`}
                    onClick={() => {
                      setSort('rating');
                      setShowFilters(false);
                    }}
                    title="Order by Rating"
                  >
                    Rating
                  </button>{' '}
                  <button
                    alt="Order by Read Time"
                    aria-label="Order by Read Time"
                    className={`tag ${sort === 'time' ? 'tag-selected' : ''}`}
                    onClick={() => {
                      setSort('time');
                      setShowFilters(false);
                    }}
                    title="Order by Read Time"
                  >
                    Read Time
                  </button>
                </div>
                <div className="modal-title">Filter by:</div>
              </div>
              <div className="tags">
                <button
                  className={`tag ${tag === '' ? 'tag-selected' : ''}`}
                  onClick={() => setTag('')}
                >
                  All
                </button>
                {tags.map(t => (
                  <button
                    className={`tag ${tag === t ? 'tag-selected' : ''}`}
                    key={t}
                    onClick={() => setTag(tag === t ? '' : t)}
                  >
                    {t}
                  </button>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
      {!!tag && (
        <button className="tag tag-selected tag-filter" onClick={() => setTag('')}>
          {tag}
          <FiX />
        </button>
      )}
    </div>
  );
};

Search.propTypes = {
  changeSearch: PropTypes.func.isRequired,
  clearSearch: PropTypes.func.isRequired,
  noFilters: PropTypes.bool,
  search: PropTypes.string.isRequired,
  setSort: PropTypes.func.isRequired,
  setTag: PropTypes.func.isRequired,
  sort: PropTypes.string.isRequired,
  tag: PropTypes.string.isRequired,
  withSort: PropTypes.bool
};

Search.defaultProps = {
  noFilters: false,
  withSort: false
};

const mapStateToProps = ({ filters: { search, sort, tag } }) => ({
  search,
  sort,
  tag
});

export default connect(mapStateToProps, { ...actions })(Search);

export default {
  author: 'Paramahansa Yogananda',
  category: 'book',
  date: '11/26/19',
  name: 'The Autobiography of a Yogi',
  path: '/notes/the-autobiography-of-a-yogi',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-this-autobiography-of-a-yogi-paramahansa-yogananda.jpg',
  tags: ['Philosophy', 'Autobiography', 'Religion'],
  time: 9,
  link: 'https://www.amazon.com/Autobiography-Yogi-P-Yogananda/dp/8120725247',
  mediumLink: '',
  patreonLink: '',
  podcastLink: '',
  podcastEmbed: '',
  youtubeLink: '',
twosLink: '',
  youtubeEmbed: '',
  releaseDate: '',
  rating: 5.6,
  review:
    'The story of a yogi as he learns and comes to America to teach. Lots of information about meditation ("yoga") and the spiritual world.'
};

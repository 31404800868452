export default {
  category: 'write',
  date: '2/19/20',
  name: 'Time Heals All',
  path: '/write/time-heals-all',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-time-heals-all.jpg',
  podcastLink: '',
  youtubeLink: '',
twosLink: '',
  mediumLink: '',
  patreonLink: '',
  tags: ['Write'],
  time: 1
};

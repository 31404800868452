import PropTypes from 'prop-types';
import React from 'react';

const BreadcrumbListItem = ({ children }) => (
  <li itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
    {children}
  </li>
);

BreadcrumbListItem.propTypes = {
  children: PropTypes.any
};

export default BreadcrumbListItem;

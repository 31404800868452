export default {
  category: 'book',
  name: 'Never Split the Difference',
  author: 'Chris Voss',
  date: '1/15/20',
  path: '/notes/never-split-the-difference',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-never-split-the-difference-chris-voss.jpg',
  tags: ['Business', 'Management', 'Money', 'Negotiation'],
  time: 5,
  rating: 8.2,
  review:
    'Great book on negotiation and how to conduct yourself in conflict. Full of tactics and examples. Really a wonderful book for anyone.',
  link: 'https://www.amazon.com/Never-Split-Difference-Negotiating-Depended/dp/0062407805',
  mediumLink: '',
  patreonLink: '',
  podcastLink: 'https://anchor.fm/parkerklein/episodes/Never-Split-the-Difference-by-Chris-Voss-eed4al',
  podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/Never-Split-the-Difference-by-Chris-Voss-eed4al',
  twosLink: 'https://www.twosapp.com/5dffbe8186fc4e59036e5d71',
  youtubeLink: 'https://youtu.be/ZlA4zaE8dgI',
  youtubeEmbed: 'https://www.youtube.com/embed/ZlA4zaE8dgI',
  releaseDate: '6/1/20'
};

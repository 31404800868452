export default {
  author: 'William Braxton Irvine',
  category: 'book',
  date: '11/22/18',
  link: 'https://amzn.to/30F8qkj',
  name: 'A Guide to the Good Life',
  path: '/notes/a-guide-to-the-good-life',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-a-guide-to-the-good-life-william-b-irvine.jpg',
  tags: ['Philosophy'],
  time: 23,
  rating: 5.4,
  review:
    "Quality book on Stoicism. Not my favorite book on Stocisim. If you are interested in the philosophy I would direct your to Marcus Aurelius' Meditations or Seneca's On the Shortness of Life. This is more of a modern day take on the philosophy with references to what those men discussed."
};

// src/reducers/index.js

import { combineReducers } from 'redux';

import body from './bodyReducer';
import subject from './subjectReducer';
import email from './emailReducer';
import emailSent from './emailSentReducer';
import error from './errorReducer';
import filters from './filtersReducer';
import loading from './loadingReducer';
import note from './noteReducer';
import sideMenu from './sideMenuReducer';
import signedUp from './signedUpReducer';
import wrote from './wroteReducer';

const rootReducer = combineReducers({
  body,
  subject,
  email,
  emailSent,
  error,
  filters,
  loading,
  note,
  sideMenu,
  signedUp,
  wrote
});

export default rootReducer;

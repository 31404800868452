export default {
  category: 'book',
  name: 'The Daily Stoic',
  author: 'Ryan Holiday',
  date: '12/26/21',
  path: '/notes/the-daily-stoic',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-daily-stoic-ryan-holiday.jpg',
  tags: ['Stoicism', 'Philosophy', 'Self-help'],
  time: 103,
  rating: 8.6,
  review: '',
  link: 'https://www.amazon.com/Daily-Stoic-Meditations-Wisdom-Perseverance/dp/0735211736',
  mediumLink: '',
  patreonLink: '',
  twosLink: 'https://www.TwosApp.com/601b244548d6bf367c20ca22',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: ''
};

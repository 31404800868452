export default {
  category: 'write',
  date: '12/27/19',
  name: 'Goals vs Resolutions',
  path: '/write/goals-vs-resolutions',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-goals-vs-resolutions.jpg',
  podcastLink: '',
  youtubeLink: '',
twosLink: '',
  mediumLink: '',
  patreonLink: '',
  tags: ['Write'],
  time: 2
};

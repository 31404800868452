export default {
  author: 'David Schwartz',
  category: 'book',
  date: '3/3/17',
  link: 'https://amzn.to/2lxD5R9',
  name: 'The Magic of Thinking Big',
  path: '/notes/the-magic-of-thinking-big',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-magic-of-thinking-big-david-schwartz.jpg',
  podcastLink:
    'https://anchor.fm/parkerklein/episodes/The-Magic-of-Thinking-Big-by-David-Schwartz--Parker-Kleins-Notes--Book-Notes-e4lbkq',
  podcastEmbed:
    'https://anchor.fm/parkerklein/embed/episodes/The-Magic-of-Thinking-Big-by-David-Schwartz--Parker-Kleins-Notes--Book-Notes-e4lbkq',
  tags: ['Self-help'],
  time: 9,
  youtubeLink: 'https://youtu.be/jWEy6i0z_FM',
  youtubeEmbed: 'https://www.youtube.com/embed/jWEy6i0z_FM',
  releaseDate: '7/17/19',
  rating: 8.1,
  review:
    'Opened my eyes to the power of positive, our own ability to choose our thoughts, and impact that has on our life and our actions. Great book for people who want to live happier or dream big.'
};

export default {
  author: 'Eckhart Tolle',
  category: 'book',
  date: '4/5/18',
  link: 'https://amzn.to/2RXpoac',
  name: 'The Power of Now',
  path: '/notes/the-power-of-now',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-power-of-now-eckhart-tolle.jpg',
  podcastLink:
    'https://anchor.fm/parkerklein/episodes/The-Power-of-Now-by-Eckhart-Tolle-Book-Notes-e4hp1g',
  podcastEmbed:
    'https://anchor.fm/parkerklein/embed/episodes/The-Power-of-Now-by-Eckhart-Tolle-Book-Notes-e4hp1g',
  tags: ['Self-help', 'Spirituality'],
  time: 2,
  youtubeLink: 'https://youtu.be/gWbIna2GVqE',
  youtubeEmbed: 'https://www.youtube.com/embed/gWbIna2GVqE',
  releaseDate: '7/5/19',
  rating: 7.6,
  review:
    'How to be present and what that actually means. The joy that being present brings and what you can do if you get lost in throught.'
};

export default {
  author: 'Christopher Alexander',
  category: 'book',
  date: '4/19/18',
  link: 'https://amzn.to/2k3Jhjs',
  name: 'The Timeless Way Of Building',
  path: '/notes/the-timeless-way-of-building',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-timeless-way-of-building-christopher-alexander.jpg',
  tags: ['Engineering', 'Design'],
  time: 31,
  rating: 5.8,
  review:
    'A lot of design and construction has a logical form based on its use and purpose. Listen to its natural way and go from there. This naturally create beauty.'
};

export default {
  category: 'book',
  name: 'Antifragile',
  author: 'Nassim Taleb',
  date: '9/18/20',
  path: '/notes/antifragile',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-antifragile-nassim-taleb.jpg',
  tags: ['Philosophy', 'Psychology'],
  time: 12,
  rating: 7.8,
  review: '',
  link: 'https://www.amazon.com/Antifragile-Things-That-Disorder-Incerto/dp/0812979680',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
twosLink: '',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: ''
};

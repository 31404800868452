export default {
  category: 'write',
  date: '9/15/19',
  name: 'Realizations From Real Negotiations',
  path: '/write/realizations-from-real-negotiations',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-realizations-from-real-negotiations.jpg',
  podcastLink: '',
  youtubeLink: '',
twosLink: '',
  mediumLink: '',
  patreonLink: '',
  tags: ['Write'],
  time: 1
};

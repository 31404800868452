export default {
  author: 'Stephen Hawking',
  category: 'book',
  date: '3/17/17',
  link: 'https://amzn.to/2xNeAlG',
  name: 'A Brief History of Time',
  path: '/notes/a-brief-history-of-time',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-a-brief-history-of-time-stephen-hawking.jpg',
  tags: ['Science'],
  time: 1,
  rating: 4.5,
  review:
    "Didn't get too much out of it. Probably because Stephen Hawking's is infinitely times smarter than I am. Really cool facts on how vast our universe is and how unlikely it is that we are here. If you like science, you'll enjoy it."
};

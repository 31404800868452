export default {
  author: 'Ryan Holiday',
  category: 'book',
  date: '5/14/19',
  link: 'https://amzn.to/2k20oSI',
  name: 'The Obstacle is the Way',
  path: '/notes/the-obstacle-is-the-way',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-obstacle-is-the-way-ryan-holiday.jpg',
  tags: ['Business', 'Self-help'],
  time: 27,
  rating: 7.9,
  review:
    'We gain so much from taking on obstacles. They give us something to do, they provide meaning for us, they help us grow.'
};

export default {
  category: 'book',
  name: 'The 4 Hour Body',
  author: 'Tim Ferriss',
  date: '1/8/20',
  path: '/notes/the-4-hour-body',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-four-hour-body-tim-ferriss.jpg',
  tags: ['Fitness', 'Health', 'Diet'],
  time: 7,
  rating: 7.8,
  review:
    'Great book on the slow carb diet, sex, and building muscle. Great exercise routines to follow and diets to follow with proven results.',
  link: 'https://www.amazon.com/Hour-Body-Uncommon-Incredible-Superhuman/dp/030746363X',
  mediumLink: '',
  patreonLink: '',
  podcastLink: 'https://anchor.fm/parkerklein/episodes/The-4-Hour-Body-by-Tim-Ferriss-efaae3',
  podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/The-4-Hour-Body-by-Tim-Ferriss-efaae3',
  youtubeLink: 'https://youtu.be/qdXjSqXftt0',
  youtubeEmbed: 'https://www.youtube.com/embed/qdXjSqXftt0',
  releaseDate: '7/7/20'
};

export default {
  category: 'write',
  date: '2/22/19',
  name: 'How to Become a Programmer',
  path: '/write/how-to-become-a-programmer',
  photo: 'https://twos.s3.amazonaws.com/parker-klein-how-to-become-a-programmer.png',
  podcastLink: '',
  youtubeLink: '',
twosLink: '',
  mediumLink: '',
  patreonLink: '',
  tags: ['Write'],
  time: 1
};

export default {
  category: 'write',
  date: '7/11/19',
  name: 'Self-Love',
  path: '/write/self-love',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-self-love.png',
  podcastLink: '',
  youtubeLink: '',
twosLink: '',
  mediumLink: '',
  patreonLink: '',
  tags: ['Write'],
  time: 1
};

export default {
  category: 'write',
  date: '1/20/20',
  name: 'What do you see?',
  path: '/write/what-do-you-see',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-what-do-you-see.png',
  podcastLink: '',
  youtubeLink: '',
twosLink: '',
  mediumLink: '',
  patreonLink: '',
  tags: ['Write'],
  time: 1
};

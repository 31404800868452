import React from 'react';
import { FiMail } from 'react-icons/fi';
import { Link } from 'react-router-dom';

import Helmet from '../components/Helmet';
import {
  apps,
  books,
  // podcasts,
  // videos,
  work,
  writings,
} from '../data';
import { sortData } from '../helpers';

const app = apps[0];
const job = work[0];
const twos = apps.find(app => app.name === 'Twos');
const book = sortData(books, 'date')[0];
const write = sortData(writings, 'date')[0];
// const video = sortData(videos, 'releaseDate')[0];
// const podcast = sortData(podcasts, 'releaseDate')[0];

const NotFoundPage = () => (
  <main className="main-content">
    <Helmet
      description="Welcome to Parker Klein's homepage. Explore Parker's notes, writings, videos, podcasts, experience, and what he is up to now."
      title="Page Not Found"
    />
    <h2 className="title">Page Not Found</h2>
    <h3 className="note">Oops, looks like this page doesn't exist yet.</h3>
    <p className="note">
      Please,{' '}
      <Link
        aria-label="Contact Parker Klein"
        className="note-link"
        to="/contact"
        id="ContactMe"
        title="Contact Parker Klein"
      >
        contact me
      </Link>{' '}
      if this doesn't look right :)
    </p>
    <p className="note">Thank you,</p>
    <p className="note">Parker</p>
    <div className="home-content">
      <Link
        aria-label="Parker Klein Now"
        className="home-button"
        to="/now"
        id="Now"
        title="Parker Klein Now"
      >
        <img alt="Parker Klein Now" src={twos.logo} />
        <h3 className="title button-title">Now</h3>
      </Link>
      <Link
        aria-label="About Parker Klein"
        className="home-button"
        to="/about"
        id="About"
        title="About Parker Klein"
      >
        <img
          alt="Parker Klein"
          src="https://twos.s3.amazonaws.com/parkerklein/parker-klein-photo-v2.jpg"
        />
        <h3 className="title button-title">About</h3>
      </Link>
      <Link
        aria-label="Parker Klein's Apps"
        className="home-button"
        to="/apps"
        id="Apps"
        title="Parker Klein's Apps"
      >
        <img alt={`${app.name} | ${app.tagline}`} src={app.logo} />
        <h3 className="title button-title">Apps</h3>
      </Link>
      <Link
        aria-label="Parker Klein's Podcast"
        className="home-button"
        to="/notes"
        id="Podcast"
        title="Parker Klein's Podcast"
      >
        <img alt={`${book.name} by ${book.author}`} src={book.photo} />
        <h3 className="title button-title">Notes</h3>
      </Link>
      <Link
        aria-label="Parker Klein's Podcast"
        className="home-button"
        to="/write"
        id="Podcast"
        title="Parker Klein's Podcast"
      >
        <img alt={`${write.name} by Parker Klein`} src={write.photo} />
        <h3 className="title button-title">Write</h3>
      </Link>
      {/*<Link
        aria-label="Parker Klein's YouTube Videos"
        className="home-button"
        to="/videos"
        id="Videos"
        title="Parker Klein's Youtube Videos"
      >
        <img alt={`${video.name} by ${video.author}`} src={video.photo} />
        <h3 className="title button-title">Videos</h3>
      </Link>
      <Link
        aria-label="Parker Klein's Podcast"
        className="home-button"
        to="/podcast"
        id="Podcast"
        title="Parker Klein's Podcast"
      >
        <img alt={`${podcast.name} by ${podcast.author}`} src={podcast.photo} />
        <h3 className="title button-title">Podcast</h3>
      </Link>*/}
      <Link
        aria-label="Parker Klein's Work"
        className="home-button"
        to="/work"
        id="Work"
        title="Parker Klein's Work"
      >
        <img alt={job.name} src={job.logo} />
        <h3 className="title button-title">Work</h3>
      </Link>
      <Link
        aria-label="Contact Parker"
        className="home-button"
        to="/contact"
        id="Contact"
        title="Contact Parker"
      >
        <div className="home-icon">
          <FiMail />
        </div>
        <h3 className="title button-title">Contact</h3>
      </Link>
    </div>
  </main>
);

export default NotFoundPage;

export default {
  category: 'book',
  name: 'The Search for Truth',
  author: 'Michael Singer',
  date: '6/27/24',
  path: '/notes/the-search-for-truth',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-search-for-truth-michael-singer.jpg',
  tags: ['Religion', 'Spirituality', 'Philosophy'],
  time: 25,
  rating: 7.1,
  review: '',
  link: 'https://www.amazon.com/Search-Truth-Books-something-say/dp/0914374036',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  twosLink: 'https://www.twosapp.com/665d0483523b56ee7b8bb393',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};

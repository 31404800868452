export default {
  category: 'write',
  date: '8/21/19',
  name: 'One Day at a Time',
  path: '/write/one-day-at-a-time',
  photo: 'https://twos.s3.amazonaws.com/parker-klein-one-day-at-a-time.png',
  podcastLink: '',
  youtubeLink: '',
twosLink: '',
  mediumLink: '',
  patreonLink: '',
  tags: ['Write'],
  time: 1
};

export default {
  author: 'Atul Gawande',
  category: 'book',
  date: '2/30/17',
  name: 'The Checklist Manifesto',
  path: '/notes/the-checklist-manifesto',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-checklist-manifesto-atul-gawande.jpg',
  tags: ['Medicine'],
  time: 0,
  link: 'https://www.amazon.com/Checklist-Manifesto-How-Things-Right/dp/0312430000',
  mediumLink: '',
  patreonLink: '',
  podcastLink: '',
  podcastEmbed: '',
  youtubeLink: '',
twosLink: '',
  youtubeEmbed: '',
  releaseDate: '',
  rating: 7.4,
  review:
    'Interesting book about checklists and how incorporating them into your life can save lives and make your life easier.'
};

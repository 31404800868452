export default {
  category: 'book',
  name: 'Decoded',
  author: 'Jay-Z',
  date: '4/16/20',
  path: '/notes/decoded',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-decoded-jay-z.jpg',
  tags: ['Music', 'Biography'],
  time: 2,
  rating: 6.6,
  review:
    "Stories of Jay-Z growing up, coming up in the rap game, and the meaning behind his and other rapper's lyrics.",
  link: 'https://www.amazon.com/Decoded-Jay-Z/dp/0812981154',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
twosLink: '',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: ''
};

export default {
  category: 'book',
  name: 'The Cold Start Problem',
  author: 'Andrew Chen',
  date: '6/9/22',
  path: '/notes/the-cold-start-problem',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-cold-start-problem-andrew-chen.jpg',
  tags: ['Business', 'Technology', 'Entrepreneurship'],
  time: 24,
  rating: 6.8,
  review: '',
  link: 'https://www.amazon.com/Cold-Start-Problem-Andrew-Chen/dp/0062969749',
  mediumLink: 'https://parkerklein.medium.com/the-cold-start-problem-how-to-start-and-scale-network-effects-by-andrew-chen-813f0668c70f',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
twosLink: 'https://www.TwosApp.com/626e7158ef7ddfc7150b73c9',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};

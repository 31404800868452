export default {
  category: 'book',
  name: 'Vagabonding',
  author: 'Rolf Potts',
  date: '3/13/21',
  path: '/notes/vagabonding',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-vagabonding-rolf-potts.jpg',
  tags: ['Travel'],
  time: 12,
  rating: 6.2,
  review: '',
  link: 'https://www.amazon.com/Vagabonding-Uncommon-Guide-Long-Term-Travel/dp/0812992180',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
twosLink: 'https://www.TwosApp.com/603bfb8e45f6f2d54964da1a',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: ''
};

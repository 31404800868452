export default {
  author: 'Christian Keur',
  category: 'book',
  date: '11/19/19',
  name: 'iOS Programming',
  path: '/notes/ios-programming',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-ios-programming-christian-keur.jpg',
  tags: ['Software Development', 'Computer Science', 'Swift'],
  time: 11,
  link: 'https://www.amazon.com/iOS-Programming-Ranch-Guide-Guides/dp/0134682335',
  mediumLink: '',
  patreonLink: '',
  podcastLink: '',
  podcastEmbed: '',
  youtubeLink: '',
twosLink: '',
  youtubeEmbed: '',
  releaseDate: '',
  rating: 5.6,
  review:
    'Great introductionn to iOS programming. Walks you through tons of examples in Swift while introducing you to the fundamentals of iOS libraries and Swift.'
};

export default {
  category: 'book',
  name: 'The Elements of Style',
  author: 'William Strunk',
  date: '9/22/20',
  path: '/notes/the-elements-of-style',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-elements-of-style-william-strunk.jpg',
  tags: ['Grammer', 'Writing'],
  time: 3,
  rating: 6.9,
  review: '',
  link: 'https://www.amazon.com/Elements-Style-Fourth-William-Strunk/dp/020530902X/ref=pd_lpo_14_img_0/141-8773159-0098750?_encoding=UTF8&pd_rd_i=020530902X&pd_rd_r=1a39b29c-c4a8-43ab-ae1d-a7ce573c8b4c&pd_rd_w=Pt6of&pd_rd_wg=hg219&pf_rd_p=7b36d496-f366-4631-94d3-61b87b52511b&pf_rd_r=23AYKP5AVCJS0VJQE5FT&psc=1&refRID=23AYKP5AVCJS0VJQE5FT',
  mediumLink: 'https://parkerklein.medium.com/the-elements-of-style-by-e-b-white-77825ff89229',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
twosLink: 'https://www.twosapp.com/5a80d7d4f6c2810004a4432d',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: ''
};

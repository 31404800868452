export default {
  author: 'Ryan Holiday',
  category: 'book',
  date: '11/23/18',
  link: 'https://amzn.to/2jMXfWM',
  name: 'Ego is the Enemy',
  path: '/notes/ego-is-the-enemy',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-ego-is-the-enemy-ryan-holiday.jpg',
  tags: ['Business', 'Self-help'],
  time: 38,
  rating: 8.9,
  twosLink: 'https://www.twosapp.com/5bf83b1e3ceef0189eb730d5',
  review:
    'Wonderful boook on being humble, putting in the work to improve, learning from others, listening to yourself, and working on the things you can control.'
};

export default {
  author: 'Patrick Lencioni',
  category: 'book',
  date: '7/12/19',
  link: 'https://amzn.to/2l6SgR5',
  name: 'The Advantage',
  path: '/notes/the-advantage',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-advantage-patrick-lencioni.jpg',
  podcastLink:
    'https://anchor.fm/parkerklein/episodes/The-Advantage-by-Patrick-Lencioni--Parker-Kleins-Notes--Book-Notes-e51m0d',
  podcastEmbed:
    'https://anchor.fm/parkerklein/embed/episodes/The-Advantage-by-Patrick-Lencioni--Parker-Kleins-Notes--Book-Notes-e51m0d',
  tags: ['Business'],
  time: 3,
  youtubeLink: 'https://youtu.be/24_pVLFrNm0',
  youtubeEmbed: 'https://youtube.com/embed/24_pVLFrNm0',
  releaseDate: '8/20/19',
  rating: 6.7,
  review: 'A book about leading, communicating, and motivating effectively.'
};

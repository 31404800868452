export default {
  category: 'apps',
  startDate: '3/12/17',
  endDate: '',
  name: 'Müse',
  tagLine: 'Daily Reflection App',
  description: 'Develop a habit of daily reflection with meaningful prompts.',
  screenshots: [
    'https://twos.s3.amazonaws.com/muse1.png',
    'https://twos.s3.amazonaws.com/muse2.png',
    'https://twos.s3.amazonaws.com/muse3.png',
    'https://twos.s3.amazonaws.com/muse4.png'
  ],
  iOSUrl: 'https://apps.apple.com/us/app/m%C3%BCse-daily-reflection-journal/id1237483776',
  logo: 'https://twos.s3.amazonaws.com/muse-logo.png',
  url: 'https://www.reflectwithmuse.com',
  facebookLink: 'https://www.facebook.com/reflectwithmuse/',
  twitterLink: 'https://twitter.com/reflectwithmuse',
  instagramLink: 'https://www.instagram.com/reflectwithmuse',
  productHuntLink: 'https://www.producthunt.com/posts/muse-5',
  path: '/apps/muse',
  tags: ['Apps'],
  tech: [
    'React',
    'React Native',
    'HTML',
    'CSS',
    'Express.js',
    'Node.js',
    'MongoDB',
    'Heroku',
    'AWS',
    'Redux'
  ],
  team: [
    { name: 'Parker Klein', url: 'https://www.parkerklein.com', role: 'Creator' },
    { name: 'Olivier Ifrah', url: 'https://www.olivierifrah.com', role: 'Branding' },
    { name: 'Makayla Robidoux', url: '', role: 'Social Media Manager' }
  ],
  future: 'Improve onboarding workflow, re-launch on Android, and introduce exercises.'
};

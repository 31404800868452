export default {
  category: 'book',
  name: 'Big Magic',
  author: 'Elizabeth Gilbert',
  date: '12/27/23',
  path: '/notes/big-magic',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-big-magic-elizabeth-gilbert.jpg',
  tags: ['Business', 'Creativity', 'Art', 'Self-help', 'Writing'],
  time: 6,
  rating: 6.4,
  review: '',
  link: 'https://www.amazon.com/Big-Magic-Creative-Living-Beyond/dp/1594634726',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  twosLink: 'https://www.twosapp.com/6589051f68e61f3f6147c00b',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};

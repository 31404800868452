export default {
  category: 'book',
  name: 'How to Get Rich',
  author: 'Felix Dennis',
  date: '10/25/24',
  path: '/notes/how-to-get-rich',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-how-to-get-rich-felix-dennis.jpg',
  tags: ['Business', 'Money', 'Leadership', 'Entrepreneurship'],
  time: 59,
  rating: 7.8,
  review: '',
  link: 'https://www.amazon.com/How-Get-Rich-Greatest-Entrepreneurs/dp/1591842719',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  twosLink: 'https://www.TwosApp.com/6702a70d05f2a83122a28e09',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};

export default {
  author: 'Supreme Understanding',
  category: 'book',
  date: '4/1/17',
  name: 'How to Hustle and Win',
  path: '/notes/how-to-hustle-and-win',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-how-to-hustle-and-win-supreme-understanding.jpg',
  tags: ['Politics', 'Philosophy'],
  time: 0,
  link: 'https://www.amazon.com/How-Hustle-Win-Survival-Ghetto/dp/098161700X',
  mediumLink: '',
  patreonLink: '',
  podcastLink: '',
  podcastEmbed: '',
  youtubeLink: '',
twosLink: '',
  youtubeEmbed: '',
  releaseDate: '',
  rating: 3.8,
  review:
    'The title says it all. A book about how to hustle and turn your dreams into reality. There is always a way, you just need to put the work in.'
};

import initialState from './initialState';
import ActionTypes from '../actions/actionTypes';

const bodyReducer = (state = initialState.body, action) => {
  switch (action.type) {
    case ActionTypes.CHANGE_BODY:
      return action.body;

    case ActionTypes.SEND_EMAIL_SUCCESS:
    case ActionTypes.RESET_EMAIL:
      return '';

    default:
      return state;
  }
};

export default bodyReducer;

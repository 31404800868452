export default {
  category: 'write',
  date: '9/11/19',
  name: '10 Reasons To Use Aware',
  path: '/write/10-reasons-to-use-aware',
  photo: 'https://twos.s3.amazonaws.com/parker-klein-aware-free-daily-habit-tracker.png',
  podcastLink: '',
  youtubeLink: '',
twosLink: '',
  mediumLink: 'https://parkerklein.medium.com/10-reasons-to-use-aware-8a68eab940d4',
  patreonLink: '',
  tags: ['Write'],
  time: 1
};

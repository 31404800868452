export default {
  author: 'Eliyahu M. Goldratt',
  category: 'book',
  date: '4/20/18',
  link: 'https://amzn.to/2lzDHpt',
  name: 'The Goal',
  path: '/notes/the-goal',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-goal-eliyahu-goldratt.jpg',
  tags: ['Business', 'Management'],
  time: 3,
  rating: 6.4,
  twosLink: 'https://www.twosapp.com/5ad9f33f6ed20800046b27f9',
  mediumLink: 'https://parkerklein.medium.com/the-goal-a-process-of-ongoing-improvement-by-eliyahu-m-goldratt-7870c8395b22',
  review:
    'Interesting book on assembly lines and eliminating bottlenecks. We want to be efficient and effective. Great book on management.'
};

export default {
  category: 'book',
  name: 'A Curious Mind',
  author: 'Brian Grazer',
  date: '10/27/20',
  path: '/notes/a-curious-mind',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-a-curious-mind-brian-grazer.jpg',
  tags: ['Biography'],
  time: 2,
  rating: 4.3,
  review: '',
  link: 'https://www.amazon.com/Curious-Mind-Secret-Bigger-Life/dp/1476730776',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
twosLink: '',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: ''
};

export default {
  category: 'book',
  name: 'What I Talk About When I Talk About Running',
  author: 'Haruki Murakami',
  date: '9/2/20',
  path: '/notes/what-i-talk-about-when-i-talk-about-running',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-what-i-talk-about-when-i-talk-about-running-haruki-murakami.jpg',
  tags: ['Memoir', 'Art', 'Writing', 'Sports'],
  time: 2,
  rating: 5.5,
  review: '',
  link: 'https://www.amazon.com/What-Talk-About-When-Running/dp/0307389839',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
twosLink: '',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: ''
};

export default {
  category: 'write',
  date: '11/22/19',
  name: 'MVS',
  path: '/write/mvs',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-mvs.jpg',
  podcastLink: '',
  youtubeLink: '',
twosLink: '',
  mediumLink: '',
  patreonLink: '',
  tags: ['Write'],
  time: 1
};

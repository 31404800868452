export default {
  author: 'Bob Rotella',
  category: 'book',
  date: '12/21/18',
  link: 'https://amzn.to/2lwSbX6',
  name: 'Putting Out Of Your Mind',
  path: '/notes/putting-out-of-your-mind',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-putting-out-of-your-mind-bob-rotella.jpg',
  tags: ['Sports', 'Golf'],
  time: 6,
  mediumLink: 'https://parkerklein.medium.com/putting-out-of-your-mind-by-bob-rotella-99d9dc4aad0d',
  patreonLink: '',
  podcastLink:
    'https://anchor.fm/parkerklein/episodes/Putting-Out-Of-Your-Mind-by-Bob-Rotella-e9ed05',
  podcastEmbed:
    'https://anchor.fm/parkerklein/embed/episodes/Putting-Out-Of-Your-Mind-by-Bob-Rotella-e9ed05',
  youtubeLink: 'https://youtu.be/xX4o_1_NfvI',
  youtubeEmbed: 'https://www.youtube.com/embed/xX4o_1_NfvI',
  releaseDate: '12/9/19',
  rating: 8.5,
  review:
    'A must read for golfers, especially if you struggle with putting. Nice and short. Helpful for non-golfers, too. The ideas can be applied to life to live with more grace.'
};

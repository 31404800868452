export default {
  category: 'book',
  name: 'Slicing Pie Handbook',
  author: 'Mike Moyer',
  date: '12/2/23',
  path: '/notes/slicing-pie-handbook',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-slicing-pie-handbook-mike-moyer.jpg',
  tags: ['Business', 'Money', 'Entrepreneurship'],
  time: 32,
  rating: 7.6,
  review: '',
  link: 'https://www.amazon.com/Slicing-Pie-Handbook-Perfectly-Bootstrapped/dp/0692584625/',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  twosLink: 'https://www.twosapp.com/655c08530566c4716873c3fa',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};

export default {
  category: 'write',
  date: '4/23/21',
  name: 'Approach with love',
  path: '/write/approach-with-love',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-approach-with-love.png',
  podcastLink: '',
  youtubeLink: '',
twosLink: '',
  mediumLink: '',
  patreonLink: '',
  tags: ['Write'],
  time: 2
};

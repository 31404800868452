export default {
  category: 'book',
  name: 'The Body Keeps The Score',
  author: 'Bessel A. Can see Kolk',
  date: '12/24/23',
  path: '/notes/the-body-keeps-the-score',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-body-keeps-the-score-bessel-can-see-kolk.jpg',
  tags: ['Health', 'Mental health', 'Trauma', 'Psychology'],
  time: 19,
  rating: 7.2,
  review: '',
  link: 'https://www.amazon.com/Body-Keeps-Score-Healing-Trauma/dp/0143127748',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  twosLink: 'https://www.twosapp.com/639e7c7ba3959b925cc07923',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};

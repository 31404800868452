export default {
  author: 'Anne Lamott',
  category: 'book',
  date: '5/13/17',
  name: 'Bird by Bird',
  path: '/notes/bird-by-bird',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-bird-by-bird-anne-lamott.jpg',
  tags: ['Writing'],
  time: 1,
  link: 'https://amzn.to/2lfsREU',
  mediumLink: '',
  patreonLink: '',
  podcastLink: 'https://anchor.fm/parkerklein/episodes/Bird-By-Bird-by-Anne-Lamott-e5ft6a',
  podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/Bird-By-Bird-by-Anne-Lamott-e5ft6a',
  youtubeLink: 'https://youtu.be/g6aGF4Js8O8',
  youtubeEmbed: 'https://www.youtube.com/embed/g6aGF4Js8O8',
  releaseDate: '9/21/19',
  rating: 7.2,
  review:
    'Short book on writing. Helps you get out of your head and take action. Shows you how you can fix things later on, the important step is to begin.'
};

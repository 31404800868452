export default {
  category: 'book',
  name: 'Anything You Want',
  author: 'Derek Sivers',
  date: '7/21/20',
  path: '/notes/anything-you-want',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-anything-you-want-derek-sivers.jpg',
  tags: ['Entrepreneurship', 'Business'],
  time: 1,
  rating: 6.8,
  review: '',
  link: 'https://www.amazon.com/Anything-You-Want-Lessons-Entrepreneur/dp/1591848261/ref=tmm_hrd_title_0?_encoding=UTF8&qid=&sr=',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
twosLink: '',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: ''
};

export default {
  category: 'write',
  date: '12/29/19',
  name: 'Books I Read in 2019',
  path: '/write/books-i-read-in-2019',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-books-i-read-in-2019.jpg',
  podcastLink: '',
  youtubeLink: '',
twosLink: '',
  mediumLink: '',
  patreonLink: '',
  tags: ['Write'],
  time: 1
};

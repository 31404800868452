// src/actions/actionTypes.js

export default {
  CHANGE_EMAIL: 'CHANGE_EMAIL',
  CHANGE_SUBJECT: 'CHANGE_SUBJECT',
  CHANGE_BODY: 'CHANGE_BODY',
  CHANGE_SEARCH: 'CHANGE_SEARCH',
  CLEAR_ERROR: 'CLEAR_ERROR',
  CLEAR_NOTE: 'CLEAR_NOTE',
  CLEAR_SEARCH: 'CLEAR_SEARCH',
  CLEAR_WRITE: 'CLEAR_WRITE',
  RESET_EMAIL: 'RESET_EMAIL',
  SEND_EMAIL_REQUEST: 'SEND_EMAIL_REQUEST',
  SEND_EMAIL_SUCCESS: 'SEND_EMAIL_SUCCESS',
  SEND_EMAIL_FAILURE: 'SEND_EMAIL_FAILURE',
  SET_ERROR: 'SET_ERROR',
  SET_NOTE: 'SET_NOTE',
  SET_SORT: 'SET_SORT',
  SET_TAG: 'SET_TAG',
  SET_WROTE: 'SET_WROTE',
  SIGN_UP_REQUEST: 'SIGN_UP_REQUEST',
  SIGN_UP_SUCCESS: 'SIGN_UP_SUCCESS',
  SIGN_UP_FAILURE: 'SIGN_UP_FAILURE',
  TOGGLE_SIDE_MENU: 'TOGGLE_SIDE_MENU'
};

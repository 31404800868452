export default {
  author: 'Alfred Adler',
  category: 'book',
  date: '11/19/18',
  link: 'https://amzn.to/2lz8nXR',
  name: 'Understanding Human Nature',
  path: '/notes/understanding-human-nature',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-understanding-human-nature-alfred-adler.jpg',
  tags: ['Psychology'],
  time: 23,
  rating: 8.2,
  review:
    "A book on human nature, lol. A fascinating book on how much of our personalities aren't up to us. They are determined during the first few weeks and months of our lives. A different way of seeing people's issues and working with them."
};

import axios from 'axios';

import ActionTypes from './actionTypes';

export const resetEmail = () => dispatch => dispatch({ type: ActionTypes.RESET_EMAIL });

export const changeSearch = search => dispatch =>
  dispatch({ type: ActionTypes.CHANGE_SEARCH, search });

export const changeBody = body => dispatch => dispatch({ type: ActionTypes.CHANGE_BODY, body });

export const changeSubject = subject => dispatch =>
  dispatch({ type: ActionTypes.CHANGE_SUBJECT, subject });

export const clearSearch = () => dispatch => dispatch({ type: ActionTypes.CLEAR_SEARCH });

export const changeEmail = email => dispatch => dispatch({ type: ActionTypes.CHANGE_EMAIL, email });

export const signUp = email => dispatch => {
  dispatch({ type: ActionTypes.SIGN_UP_REQUEST }); // send create user request

  return axios.post('/api/user', { email }).then(
    () => dispatch({ type: ActionTypes.SIGN_UP_SUCCESS }),
    () => dispatch({ type: ActionTypes.SIGN_UP_FAILURE, error: 'Sorry, this email was already used.' }) // create user failed
  );
};

export const sendEmail = (email, subject, body) => dispatch => {
  dispatch({ type: ActionTypes.SEND_EMAIL_REQUEST }); // send email request

  return axios.post('/api/email', { email, subject, body }).then(
    () => dispatch({ type: ActionTypes.SEND_EMAIL_SUCCESS }),
    () => dispatch({ type: ActionTypes.SEND_EMAIL_FAILURE, error: 'Sorry, email failed to send.' }) // email failed
  );
};

export const setError = error => dispatch => dispatch({ type: ActionTypes.SET_ERROR, error });

export const setNote = note => dispatch => dispatch({ type: ActionTypes.SET_NOTE, note });

export const clearNote = () => dispatch => dispatch({ type: ActionTypes.CLEAR_NOTE });

export const setSort = sort => dispatch => dispatch({ type: ActionTypes.SET_SORT, sort });

export const setTag = tag => dispatch => dispatch({ type: ActionTypes.SET_TAG, tag });

export const setWrite = write => dispatch => dispatch({ type: ActionTypes.SET_WROTE, write });

export const clearWrite = () => dispatch => dispatch({ type: ActionTypes.CLEAR_WRITE });

export const toggleSideMenu = () => dispatch => dispatch({ type: ActionTypes.TOGGLE_SIDE_MENU });

import initialState from './initialState';
import ActionTypes from '../actions/actionTypes';

const emailSentReducer = (state = initialState.emailSent, action) => {
  switch (action.type) {
    case ActionTypes.CHANGE_BODY:
    case ActionTypes.CHANGE_SUBJECT:
    case ActionTypes.CHANGE_EMAIL:
    case ActionTypes.RESET_EMAIL:
      return false;

    case ActionTypes.SEND_EMAIL_SUCCESS:
      return true;

    default:
      return state;
  }
};

export default emailSentReducer;

import React from 'react';
import { Helmet } from 'react-helmet';

import './App.css';
import ScrollToTop from './components/ScrollToTop';
import Page from './pages';

const App = () => {
  return (
    <ScrollToTop>
      <Helmet titleTemplate={`Parker Klein | %s`} defaultTitle="Personal Website" />
      <Page />
    </ScrollToTop>
  );
};

export default App;

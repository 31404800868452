export default {
  author: 'Delia Owens',
  category: 'book',
  date: '6/17/19',
  link: 'https://amzn.to/2jS5j8H',
  name: 'Where the Crawdads Sing',
  path: '/notes/where-the-crawdads-sing',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-where-the-crawdads-sing-delia-owens.jpg',
  tags: ['Fiction'],
  time: 1,
  rating: 8.9,
  review:
    "Wonderful novel about a poor girl who loves nature and the world and want to share what see learns with others. She doesn't need much and you don't either. What's fair is fair.",
  mediumLink: '',
  patreonLink: '',
  podcastLink:
    'https://anchor.fm/parkerklein/episodes/Where-the-Crawdads-Sing-by-Delia-Owens-eakb78',
  podcastEmbed:
    'https://anchor.fm/parkerklein/embed/episodes/Where-the-Crawdads-Sing-by-Delia-Owens-eakb78',
  youtubeLink: 'https://youtu.be/7BAZ5VaiZ4g',
  youtubeEmbed: 'https://www.youtube.com/embed/7BAZ5VaiZ4g',
  releaseDate: '2/3/20'
};

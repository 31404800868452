export default {
  category: 'write',
  date: '8/15/19',
  name: 'What happens when we slow down?',
  path: '/write/what-happens-when-we-slow-down',
  photo: 'https://twos.s3.amazonaws.com/parker-klein-what-happens-when-we-slow-down.jpg',
  podcastLink: '',
  youtubeLink: '',
twosLink: '',
  mediumLink: '',
  patreonLink: '',
  tags: ['Write'],
  time: 2
};

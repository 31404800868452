export default {
  category: 'write',
  date: '2/3/21',
  name: 'Love vs Fear',
  path: '/write/love-vs-fear',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-love-vs-fear.jpg',
  podcastLink: '',
  youtubeLink: '',
twosLink: 'https://www.twosapp.com/601b184bcd003d0fa71fea97',
  mediumLink: 'https://medium.com/twosapp/love-vs-fear-39632311a606',
  patreonLink: '',
  tags: ['Write'],
  time: 2
};

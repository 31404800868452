export default {
  author: 'Cal Newport',
  category: 'book',
  date: '11/7/19',
  name: "So Good They Can't Ignore You",
  path: '/notes/so-good-they-cant-ignore-you',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-so-good-they-cant-ignore-you-cal-newport.jpg',
  tags: ['Business'],
  time: 5,
  link: 'https://www.amazon.com/Good-They-Cant-Ignore-You/dp/1455509124',
  mediumLink: '',
  patreonLink: '',
  podcastLink:
    'https://anchor.fm/parkerklein/episodes/So-Good-They-Cant-Ignore-You-by-Cal-Newport-eat898',
  podcastEmbed:
    'https://anchor.fm/parkerklein/embed/episodes/So-Good-They-Cant-Ignore-You-by-Cal-Newport-eat898',
  youtubeLink: 'https://youtu.be/s-BUqTHxdmI',
  youtubeEmbed: 'https://www.youtube.com/embed/s-BUqTHxdmI',
  releaseDate: '2/17/20',
  rating: 7.6,
  review:
    'A book about deliberate practice. About putting in the work and focusing on getting better at a craft people will be willing to pay you for.'
};

export default {
  author: 'Sun Tzu',
  category: 'book',
  date: '6/12/17',
  name: 'The Art of War',
  path: '/notes/the-art-of-war',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-art-of-war-sun-tzu.jpg',
  tags: ['Education', 'Philosophy'],
  time: 0,
  link: 'https://www.amazon.com/Art-War-Sun-Tzu/dp/1599869772',
  mediumLink: '',
  patreonLink: '',
  podcastLink: '',
  podcastEmbed: '',
  youtubeLink: '',
twosLink: '',
  youtubeEmbed: '',
  releaseDate: '',
  rating: 6.6,
  review: 'Short book with principles about war and beating your enemy.'
};

import React from 'react';
import { Link } from 'react-router-dom';

import { months } from './constants';

export function filterData(data, search) {
  if (!search) return data;
  const lowercaseSearch = search.toLowerCase();
  return data.filter(
    d =>
      (d.name || '').toLowerCase().includes(lowercaseSearch) ||
      (d.author || '').toLowerCase().includes(lowercaseSearch) ||
      (d.category || '').toLowerCase().includes(lowercaseSearch) ||
      (d.tags || []).findIndex(tag => tag.toLowerCase().includes(lowercaseSearch)) >= 0
  );
}

// export const getListDate = date => {
//   const splitDate = date.split('/');
//   const month = splitDate[0];
//   const day = splitDate[1];
//   const year = splitDate[2];
//
//   let newDate = new Date();
//   newDate.setDate(day - 1);
//   newDate.setMonth(month - 1);
//   newDate.setFullYear(year);
//
//   return newDate;
// };

export function sortAuthorAlphabetically(a, b) {
  const textA = a.author.toUpperCase();
  const textB = b.author.toUpperCase();
  return textA < textB ? -1 : textA > textB ? 1 : 0;
}

export function sortNameAlphabetically(a, b) {
  const textA = a.name.toUpperCase();
  const textB = b.name.toUpperCase();
  return textA < textB ? -1 : textA > textB ? 1 : 0;
}

export function sortByDate(a, b) {
  const partsA = a.split('/');
  const partsB = b.split('/');

  const yearA = Number(partsA[2]);
  const yearB = Number(partsB[2]);
  const monthA = Number(partsA[0]);
  const monthB = Number(partsB[0]);
  const dayA = Number(partsA[1]);
  const dayB = Number(partsB[1]);

  if (yearA > yearB) {
    return -1;
  } else if (yearA < yearB) {
    return 1;
  } else if (monthA > monthB) {
    return -1;
  } else if (monthA < monthB) {
    return 1;
  } else if (dayA > dayB) {
    return -1;
  } else if (dayA < dayB) {
    return 1;
  } else {
    return 0;
  }
}

export function sortDate(a, b) {
  return sortByDate(a.date, b.date);
}

export function sortReleaseDate(a, b) {
  return sortByDate(a.releaseDate, b.releaseDate);
}

export function sortTime(a, b) {
  const timeA = a.time;
  const timeB = b.time;
  return timeA < timeB ? -1 : timeA > timeB ? 1 : 0;
}

export function sortRating(a, b) {
  const ratingA = a.rating;
  const ratingB = b.rating;
  return ratingA < ratingB ? 1 : ratingA > ratingB ? -1 : 0;
}

export function sortData(data, sort) {
  let sortedDated = data;

  switch (sort) {
    case 'author':
      sortedDated = sortedDated.sort(sortAuthorAlphabetically);
      break;
    case 'date':
      sortedDated = sortedDated.sort(sortDate);
      break;
    case 'rating':
      sortedDated = sortedDated.sort(sortRating);
      break;
    case 'releaseDate':
      sortedDated = sortedDated.sort(sortReleaseDate);
      break;
    case 'time':
      sortedDated = sortedDated.filter(d => d.time).sort(sortTime);
      break;
    case 'title':
      sortedDated = sortedDated.sort(sortNameAlphabetically);
      break;
    default:
      sortedDated = sortedDated.sort(sortDate);
      break;
  }

  return sortedDated;
}

/* eslint-disable */
export const checkEmail = str =>
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    str
  );
/* eslint-enable */

export const capitalizeFirstLetter = str =>
  str ? str.charAt(0).toUpperCase() + str.substr(1) : '';

export const capitalizeEveryWord = str => (str ? str.replace(/\w\S*/g, capitalizeFirstLetter) : '');

export const formatDate = date => {
  const splitDate = date.split('/');
  const month = splitDate[0];
  const year = splitDate[2];

  return `${months[month - 1]} 20${year}`;
};

export const calculateNote = note => {
  if (typeof note === 'string') {
    return (
      <p className="note" itemProp="text">
        {note}
      </p>
    );
  } else if (Array.isArray(note)) {
    return (
      <p className="note">
        {note.map(n => {
          if (typeof n === 'string') return `${n} `;
          return (
            <Link
              aria-label={`${capitalizeEveryWord(n.text)} by Parker Klein`}
              className={`note-link${n.noRight ? '' : ' margin-right'}`}
              key={n.link}
              target="_blank"
              title={`${capitalizeEveryWord(n.text)} by Parker Klein`}
              to={n.link}
            >
              {n.text}
            </Link>
          );
        })}
      </p>
    );
  } else {
    return <img className="note-photo" alt={note.alt} src={note.src} />;
  }
};

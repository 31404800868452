export default {
  author: 'Tom Brady',
  category: 'book',
  date: '9/17/19',
  link: 'https://www.amazon.com/TB12-Method-Lifetime-Sustained-Performance/dp/1501180738',
  name: 'The TB12 Method',
  path: '/notes/the-tb12-method',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-tb12-method-tom-brady.jpg',
  tags: ['Health', 'Fitness', 'Diet', 'Biography', 'Sports'],
  time: 5,
  rating: 8.3,
  review:
    'How Tom Brady has maintained elite performance for so long. How he eats, trains, stretches, and recovers. Great tool for athletes and people who want their bodies to feel good.',
  mediumLink: '',
  patreonLink: '',
  podcastLink: 'https://anchor.fm/parkerklein/episodes/The-TB12-Method-by-Tom-Brady-eebfuf',
  podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/The-TB12-Method-by-Tom-Brady-eebfuf',
  youtubeLink: 'https://youtu.be/Ib0vqjHFsyc',
  youtubeEmbed: 'https://www.youtube.com/embed/Ib0vqjHFsyc',
  releaseDate: '5/25/20'
};

export default {
  author: 'Malcolm Gladwell',
  category: 'book',
  date: '2/9/15',
  name: 'Outliers',
  path: '/notes/outliers',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-outliers-malcolm-gladwell.jpg',
  tags: ['Business', 'Money', 'Management', 'Leadership'],
  time: 0,
  link: 'https://www.amazon.com/Outliers-Story-Success-Malcolm-Gladwell/dp/0316017930',
  mediumLink: '',
  patreonLink: '',
  podcastLink: '',
  podcastEmbed: '',
  youtubeLink: '',
twosLink: '',
  youtubeEmbed: '',
  releaseDate: '',
  rating: 8.2,
  review: 'Great book and research behind the 10,000 hour rule and how skill compounds over time.'
};

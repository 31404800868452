export default {
  category: 'book',
  name: 'Wherever You Go, There You Are',
  author: 'Jon Kabat-Zinn',
  date: '12/19/21',
  path: '/notes/wherever-you-go-there-you-are',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-wherever-you-go-there-you-are-jon-kabat-zinn.jpg',
  tags: ['Meditation', 'Spirituality'],
  time: 9,
  rating: 9.1,
  review: '',
  link: 'https://www.amazon.com/Wherever-You-There-Are-Mindfulness/dp/1401307787',
  mediumLink: '',
  patreonLink: '',
  twosLink: 'https://www.twosapp.com/61a99d351e9fd423c03e3a5f',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: ''
};

export default {
  author: 'Clayton Christensen',
  category: 'book',
  date: '7/13/19',
  link: 'https://amzn.to/2lnsTuv',
  name: 'How Will You Measure Your Life?',
  path: '/notes/how-will-you-measure-your-life',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-how-will-you-measure-your-life-clayton-christensen.jpg',
  tags: ['Business', 'Management', 'Leadership'],
  time: 6,
  rating: 6.7,
  review:
    'A book about finding meaning in your life, what constitutes as success, how to love what you do. Will help you live a happier, more intentional life.',
  mediumLink: '',
  patreonLink: '',
  podcastLink:
    'https://anchor.fm/parkerklein/episodes/How-Will-You-Measure-Your-Life--by-Clayton-Christensen-e9u1cv',
  podcastEmbed:
    'https://anchor.fm/parkerklein/embed/episodes/How-Will-You-Measure-Your-Life--by-Clayton-Christensen-e9u1cv',
  youtubeLink: 'https://youtu.be/_v2KbOtTjN0',
  youtubeEmbed: 'https://www.youtube.com/embed/_v2KbOtTjN0',
  releaseDate: '12/31/19'
};

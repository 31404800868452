export default {
  category: 'write',
  date: '5/18/17',
  name: 'Higher Standard',
  path: '/write/higher-standard',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-higher-standard.jpg',
  podcastLink: '',
  youtubeLink: '',
twosLink: '',
  mediumLink: '',
  patreonLink: '',
  tags: ['Write'],
  time: 1
};

export default {
  category: 'write',
  date: '5/8/17',
  name: 'Blip',
  path: '/write/blip',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-blip.jpg',
  podcastLink: '',
  youtubeLink: '',
twosLink: '',
  mediumLink: 'https://parkerklein.medium.com/blip-4b8f67feda4e',
  patreonLink: '',
  tags: ['Write'],
  time: 1
};

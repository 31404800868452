export default {
  category: 'write',
  date: '1/20/22',
  name: 'Liberty',
  path: '/write/liberty',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-liberty.jpg',
  podcastLink: '',
  youtubeLink: '',
  twosLink: 'https://www.TwosApp.com/600756e17f538ed6253b7e6a',
  mediumLink: 'https://medium.com/twosapp/liberty-67fac55654f0',
  patreonLink: '',
  tags: ['Write'],
  time: 2,
};

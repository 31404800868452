export default {
  category: 'book',
  name: "Poor Charlie's Almanack",
  author: 'Charles Munger',
  date: '5/31/20',
  path: '/notes/poor-charlies-almanack',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-poor-charlies-almanack-charles-munger.jpg',
  tags: ['Business', 'Money', 'Investing'],
  time: 10,
  rating: 8.7,
  review: 'Quality wisdom sharing. Loved the last lecture where he talked about human psychology tendencies and what we can do to combat them.',
  link: 'https://www.amazon.com/Poor-Charlies-Almanack-Charles-Expanded/dp/1578645018',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
twosLink: '',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: ''
};

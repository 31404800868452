export default {
  author: 'Walter Issacson',
  category: 'book',
  date: '11/23/15',
  name: 'Steve Jobs',
  path: '/notes/steve-jobs',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-steve-jobs-walter-issacson.jpg',
  tags: ['Biography', 'Technology'],
  time: 0,
  link: 'https://www.amazon.com/Steve-Jobs-Walter-Isaacson/dp/1451648537',
  mediumLink: '',
  patreonLink: '',
  podcastLink: '',
  podcastEmbed: '',
  youtubeLink: '',
twosLink: '',
  youtubeEmbed: '',
  releaseDate: '',
  rating: 7.4,
  review:
    "The story of Steve Jobs. A man driven to provide value to his customers through products. Apple and Pixar built incredible products and were highly innovative. Couldn't have happened without him."
};

export default {
  category: 'write',
  date: '6/10/19',
  name: 'Have you ever tried to be a rapper?',
  path: '/write/have-you-ever-tried-to-be-a-rapper',
  photo: 'https://twos.s3.amazonaws.com/parker-klein-have-you-ever-tried-to-be-a-rapper.jpg',
  podcastLink: '',
  youtubeLink: '',
twosLink: '',
  mediumLink: '',
  patreonLink: '',
  tags: ['Write'],
  time: 1
};

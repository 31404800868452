export default {
  author: 'Al Ries and Jack Trout',
  category: 'book',
  date: '4/29/17',
  link: 'https://amzn.to/2lvLLaH',
  name: 'The 22 Immutable Laws of Marketing',
  path: '/notes/the-22-immutable-laws-of-marketing',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-22-immutable-laws-of-marketing-al-ries.jpg',
  tags: ['Business', 'Marketing', 'Sales'],
  time: 1,
  rating: 7.4,
  review:
    "Great tips to market effectively. How to grab your audience and build a market around your product even if it doesn't already exist. Great resource for entrepreneurs."
};

export default {
  category: 'write',
  date: '1/12/21',
  name: 'What will be',
  path: '/write/what-will-be',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-what-will-be.jpg',
  podcastLink: '',
  youtubeLink: '',
twosLink: '',
  mediumLink: '',
  patreonLink: '',
  tags: ['Write'],
  time: 1
};

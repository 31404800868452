export default {
  author: 'Ben Horowitz',
  category: 'book',
  date: '2/21/19',
  link: 'https://amzn.to/2ly1tlI',
  name: 'The Hard Thing About Hard Things',
  path: '/notes/the-hard-thing-about-hard-things',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-hard-thing-about-hard-things-ben-horowitz.jpg',
  tags: ['Business', 'Management', 'Leadership'],
  time: 14,
  rating: 5.8,
  review:
    'Story of Ben Horowitz career. Solid story teller, love the hip-hop references. Nothing is easy and a lot of the time you have to figure it out as you fly.',
  twosLink: 'https://www.twosapp.com/5c6eabed00351c55d59fafcb',
  mediumLink: 'https://parkerklein.medium.com/the-hard-thing-about-hard-things-by-ben-horowitz-a5d17d7afc6',
};

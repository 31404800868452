export default {
  category: 'book',
  name: 'In Praise of Shadows',
  author: 'Junichiro Tanizaki',
  date: '1/12/20',
  path: '/notes/in-praise-of-shadows',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-junichiro-tanizaki-in-praise-of-shadows.jpg',
  tags: ['Philosophy'],
  time: 1,
  rating: 4.3,
  review:
    'A book about appreciating the little things and learning to see the beauty in what is. Draws on the differences between Japan and America.',
  link: 'https://www.amazon.com/Praise-Shadows-Junichiro-Tanizaki/dp/0918172020',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
twosLink: '',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: ''
};

export default {
  author: 'Ashlee Vance',
  category: 'book',
  date: '9/30/15',
  name: 'Elon Musk',
  path: '/notes/elon-musk',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-elon-musk-ashlee-vance.jpg',
  tags: ['History', 'Biography', 'Entrepreneurship'],
  time: 0,
  link: 'https://www.amazon.com/Elon-Musk-SpaceX-Fantastic-Future/dp/006230125X',
  mediumLink: '',
  patreonLink: '',
  podcastLink: '',
  podcastEmbed: '',
  youtubeLink: '',
twosLink: '',
  youtubeEmbed: '',
  releaseDate: '',
  rating: 7.7,
  review:
    'Story of Elon Musk up until the beginning of Telsa and SpaceX. Elon is a savage. Came from very little, is constantly risking everything, extremely hard working. Inspiring story.'
};

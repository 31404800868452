export default {
  category: 'write',
  date: '12/7/21',
  name: '6 Ways to Stop Forgetting Things with Twos',
  path: '/write/6-ways-to-stop-forgetting-things-with-twos',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-6-ways-to-stop-forgetting-things-with-twos.jpg',
  podcastLink: '',
  youtubeLink: 'https://www.youtube.com/watch?v=UMu8P0qBR-g',
  mediumLink: 'https://medium.com/twosapp/6-ways-to-stop-forgetting-things-with-twos-%EF%B8%8F-83cd05923b81',
  patreonLink: '',
  tags: ['Write'],
  time: 6
};

export default {
  author: 'Josef Müller-Brockmann',
  category: 'book',
  date: '8/10/19',
  link: 'https://amzn.to/31x3kqE',
  name: 'Grid Systems in Graphic Design',
  path: '/notes/grid-systems-in-graphic-design',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-grid-systems-in-graphic-design-josef-müller-brockmann.jpg',
  tags: ['Design', 'Graphic Design', 'Typography'],
  time: 6,
  rating: 4.2,
  review:
    'Introduction to graphic design concepts. Will change the way you look at how books and articles are laid out.'
};

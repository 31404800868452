export default {
  category: 'book',
  name: 'The Clean Coder',
  author: 'Robert Martin',
  date: '11/11/20',
  path: '/notes/the-clean-coder',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-clean-coder-robert-martin.jpg',
  tags: ['Programming', 'Computer Science', 'Software Development'],
  time: 3,
  rating: 7.4,
  review: '',
  link: '',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
twosLink: '',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: ''
};

export default {
  category: 'book',
  name: 'Common Sense',
  author: 'Thomas Paine',
  date: '12/31/22',
  path: '/notes/common-sense',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-common-sense-thomas-paine.jpg',
  tags: ['History', 'Politics', 'Social Science'],
  time: 5,
  rating: 6.1,
  review: '',
  link: 'https://www.amazon.com/Common-Sense-Thomas-Paine/dp/B09GJMCCR9',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  twosLink: 'https://www.twosapp.com/639469db63e93181817df05c',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};

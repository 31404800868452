export default {
  category: 'Work',
  startDate: '6/1/14',
  endDate: '8/1/14',
  name: 'Teradata',
  position: 'Product Support Engineer Intern',
  androidUrl: '',
  iOSUrl: '',
  logo: 'https://twos.s3.amazonaws.com/teradata-logo.jpg',
  url: 'https://www.teradata.com',
  path: '/work/teradata',
  tags: ['Work'],
  tech: ['Perl', 'Teradata', 'Hadoop'],
  accomplishments: ['Wrote and documented test scripts for customers’ servers.'],
  location: 'San Diego, CA'
};

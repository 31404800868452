export default {
  category: 'book',
  name: 'The E-Myth Revisited',
  author: 'Michael Gerber',
  date: '7/7/20',
  path: '/notes/the-e-myth-revisited',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-e-myth-revisited-michael-gerber.jpg',
  tags: ['Business', 'Management', 'Leadership', 'Entrepreneurship'],
  time: 3,
  rating: 7.9,
  review: '',
  link: 'https://www.amazon.com/Myth-Revisited-Small-Businesses-About/dp/0887307280',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
twosLink: '',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: ''
};

export default {
  category: 'book',
  name: 'The Creative Act',
  author: 'Rick Rubin',
  date: '11/8/23',
  path: '/notes/the-creative-act',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-creative-act-rick-rubin.jpg',
  tags: ['Psychology', 'Art', 'Creativity', 'Music', 'Spirituality'],
  time: 32,
  rating: 8.8,
  review: '',
  link: 'https://www.amazon.com/Creative-Act-Way-Being/dp/0593652886',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  twosLink: 'https://www.twosapp.com/652d88080290487080a006cd',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};

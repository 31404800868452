export default {
  author: 'Jocelyn Glei',
  category: 'book',
  date: '3/31/16',
  link: 'https://amzn.to/2lmkKpX',
  name: 'Make Your Mark',
  path: '/notes/make-your-mark',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-make-your-mark-99u.jpg',
  tags: ['Business', 'Entrepreneurship'],
  time: 3,
  mediumLink: '',
  patreonLink: '',
  podcastLink: 'https://anchor.fm/parkerklein/episodes/Make-Your-Mark-by-Jocelyn-Glei-e893rj',
  podcastEmbed:
    'https://anchor.fm/parkerklein/embed/episodes/Make-Your-Mark-by-Jocelyn-Glei-e893rj',
  youtubeLink: 'https://youtu.be/JDt_BrZOobg',
  youtubeEmbed: 'https://www.youtube.com/embed/JDt_BrZOobg',
  releaseDate: '10/27/19',
  rating: 6.8,
  review:
    'A helpful book for figuring out your target market and how you can provide value to them. Will help guide your decisions making everything else easier.'
};

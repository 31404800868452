export default {
  category: 'book',
  name: 'Radical Acceptance',
  author: 'Tara Brach',
  date: '9/7/23',
  path: '/notes/radical-acceptance',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-radical-acceptance-tara-brach.jpg',
  tags: ['Health', 'Psychology', 'Self-help'],
  time: 40,
  rating: 8.3,
  review: '',
  link: 'https://www.amazon.com/Radical-Acceptance-Embracing-Heart-Buddha/dp/0553380990',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  twosLink: 'https://www.twosapp.com/64d22f58a7c97471086f88c5',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};

export default {
  category: 'write',
  date: '12/29/19',
  name: 'Goals for 2020',
  path: '/write/goals-for-2020',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-goals-for-2020.jpg',
  podcastLink: '',
  youtubeLink: '',
twosLink: '',
  mediumLink: '',
  patreonLink: '',
  tags: ['Write'],
  time: 1
};

export default {
  author: 'Dave Eggers',
  category: 'book',
  date: '8/30/16',
  name: 'The Circle',
  path: '/notes/the-circle',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-circle-dave-eggers.jpg',
  tags: ['Fiction'],
  time: 0,
  link: 'https://www.amazon.com/Circle-Dave-Eggers/dp/159413961X',
  mediumLink: '',
  patreonLink: '',
  podcastLink: '',
  podcastEmbed: '',
  youtubeLink: '',
twosLink: '',
  youtubeEmbed: '',
  releaseDate: '',
  rating: 7.2,
  review:
    'Fascinating book about what the future could look like if we all tracked and broadcasted everything we did as content.'
};

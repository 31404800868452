export default {
  author: 'Rob Fitzpatrick',
  category: 'book',
  date: '8/25/19',
  link: 'https://www.amazon.com/Mom-Test-customers-business-everyone/dp/1492180742',
  name: 'The Mom Test',
  path: '/notes/the-mom-test',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-mom-test-rob-fitzpatrick.jpg',
  tags: ['Business', 'Entrepreneurship', 'User Research'],
  time: 4,
  rating: 8.2,
  review:
    'A book about how to conduct user research. Great tool for people doing user research. Great tool for founders or people that work at a startup. Learn how to talk about your product and how to learn from your users.',
  mediumLink: '',
  patreonLink: '',
  podcastLink: 'https://anchor.fm/parkerklein/episodes/The-Mom-Test-by-Rob-Fitzpatrick-ed0hs5',
  podcastEmbed:
    'https://anchor.fm/parkerklein/embed/episodes/The-Mom-Test-by-Rob-Fitzpatrick-ed0hs5',
  youtubeLink: 'https://youtu.be/4NYlVMuwV9s',
  youtubeEmbed: 'https://www.youtube.com/embed/4NYlVMuwV9s',
  releaseDate: '5/11/20'
};

export default {
  category: 'book',
  name: 'The Millionaire Next Door',
  author: 'Thomas Stanley',
  date: '11/20/23',
  path: '/notes/the-millionaire-next-door',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-millionaire-next-door-thomas-stanley.jpg',
  tags: ['Finance', 'Money', 'Investing', 'Parenting'],
  time: 31,
  rating: 8.1,
  review: '',
  link: 'https://www.amazon.com/Millionaire-Next-Door-Surprising-Americas/dp/1589795474',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  twosLink: 'https://www.twosapp.com/6548be4642e85aaaee8fe3a7',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};

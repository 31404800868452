export default {
  category: 'apps',
  startDate: '1/22/17',
  endDate: '3/11/17',
  name: '7 Levels Deep',
  tagLine: 'Discover Your Why',
  description: 'An exercise to discover why you do what you do.',
  screenshots: [
    'https://twos.s3.amazonaws.com/parker-klein-7-levels-deep-screenshot-1.jpg',
    'https://twos.s3.amazonaws.com/parker-klein-7-levels-deep-screenshot-2.jpg',
    'https://twos.s3.amazonaws.com/parker-klein-7-levels-deep-screenshot-3.jpg',
    'https://twos.s3.amazonaws.com/parker-klein-7-levels-deep-screenshot-4.jpg'
  ],
  iOSUrl: 'https://apps.apple.com/us/app/7-levels-deep-find-your-why/id1214661840',
  logo: 'https://twos.s3.amazonaws.com/7-levels-deep-logo.png',
  url: 'http://www.7levelsdeep.com',
  path: '/apps/7-levels-deep',
  tags: ['Apps'],
  tech: ['React', 'React Native', 'HTML', 'CSS', 'Heroku'],
  future: 'Improve analytics and data management for users.'
};

import PropTypes from 'prop-types';
import React from 'react';
import { FaAmazon, FaMedium, FaPatreon, FaPodcast, FaYoutube } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import * as actions from '../actions';
import EmptyMessage from '../components/EmptyMessage';
import Helmet from '../components/Helmet';
import Search from '../components/Search';
import { books } from '../data';
import { filterData, sortData } from '../helpers';

const NotesPage = ({ search, sort, tag }) => {
  const booksCollection = [];
  books.map(book => booksCollection.push(`${book.name} by ${book.author}`));

  let filteredBooks = books;

  if (tag) {
    filteredBooks = filteredBooks.filter(book => {
      return book.tags.includes(tag);
    });
  }

  filteredBooks = filterData(filteredBooks, search);
  filteredBooks = sortData(filteredBooks, sort);

  // console.log('filteredBooks', filteredBooks);

  return (
    <main className="main-content">
      <ReactTooltip effect="solid" />
      <Helmet
        description={`Parker Klein's collection of notes. Notes on ${booksCollection.join(', ')}`}
        title="Notes"
      />
      {/* <h2 className="title">Notes</h2> */}
      <Search />
      <ul aria-labelledby="notes-header">
        {(tag || search) && <p className="results">Results ({filteredBooks.length})</p>}
        {filteredBooks.length === 0 && <EmptyMessage />}
        {filteredBooks.map(list => (
          <li
            className="list-item row"
            id={list.path}
            itemScope
            itemType="http://schema.org/Book"
            key={list.path}
          >
            {list.photo && (
              <Link
                className="photo"
                aria-label={`${list.name} by ${list.author}`}
                to={list.path}
                tabIndex={-1}
                title={`${list.name} by ${list.author}`}
              >
                <img
                  alt={`${list.name} by ${list.author}`}
                  height={190}
                  itemProp="image"
                  src={list.photo}
                  width={129}
                />
              </Link>
            )}
            <div className="column">
              <Link
                aria-label={`${list.name} by ${list.author}`}
                to={list.path}
                title={`${list.name} by ${list.author}`}
              >
                <h2 className="title" itemProp="name">
                  {list.name}
                </h2>
                <h3 className="author" itemProp="author">
                  {list.author}
                </h3>
                {/* <p className="rating" itemProp="rating">
                  Rating: {list.rating}
                </p> */}
                <p className="date" itemProp="dateCreated">
                  {sort === 'time' ? `${list.time} min read` : list.date}
                </p>
                <meta itemProp="genre" content={list.tags.join(', ')} />
                <meta itemProp="url" content={`https://www.parkerklein.com${list.path}`} />
              </Link>
              <div className="row links">
                {list.twosLink && (
                  <a
                    aria-label={`Read Parker Klein's Notes on ${list.name} by ${list.author} on Twos`}
                    className="icon-link twos"
                    data-tip={`${list.name} on Twos`}
                    href={list.twosLink}
                    itemProp="url"
                    rel="noopener noreferrer"
                    target="_blank"
                    title={`Read Parker Klein's Notes on ${list.name} by ${list.author} on Twos`}
                  >
                    <img
                      alt="Twos: days, lists, & things"
                      src="https://twos.s3.amazonaws.com/twos-thread-background-transparent-300.png"
                      height="28px"
                      width="20px"
                    />
                  </a>
                )}
                {list.youtubeLink && (
                  <a
                    aria-label={`Watch Parker Klein's Notes on ${list.name} by ${list.author} on YouTube`}
                    className="icon-link youtube"
                    data-tip={`${list.name} on YouTube`}
                    href={list.youtubeLink}
                    itemProp="url"
                    rel="noopener noreferrer"
                    target="_blank"
                    title={`Watch Parker Klein's Notes on ${list.name} by ${list.author} on YouTube`}
                  >
                    <FaYoutube />
                  </a>
                )}
                {list.patreonLink && (
                  <a
                    aria-label={`Support Parker Klein's notes on ${list.name} by ${list.author} on Patreon`}
                    className="icon-link patreon"
                    data-tip={`${list.name} on Patreon`}
                    href={list.patreonLink}
                    itemProp="url"
                    rel="noopener noreferrer"
                    target="_blank"
                    title={`Support Parker Klein's notes on ${list.name} by ${list.author} on Patreon`}
                  >
                    <FaPatreon />
                  </a>
                )}
                {list.link && (
                  <a
                    aria-label={`Buy ${list.name} by ${list.author} on Amazon`}
                    className="icon-link amazon"
                    data-tip={`Buy ${list.name} on Amazon`}
                    href={list.link}
                    itemProp="url"
                    rel="noopener noreferrer"
                    target="_blank"
                    title={`Buy ${list.name} by ${list.author} on Amazon`}
                  >
                    <FaAmazon />
                  </a>
                )}
                {list.podcastLink && (
                  <a
                    aria-label={`Listen to Parker Klein's Notes podcast on ${list.name} by ${list.author}`}
                    className="icon-link podcast"
                    data-tip={`Listen to ${list.name}`}
                    href={list.podcastLink}
                    itemProp="url"
                    rel="noopener noreferrer"
                    target="_blank"
                    title={`Listen to Parker Klein's Notes podcast on ${list.name} by ${list.author}`}
                  >
                    <FaPodcast />
                  </a>
                )}
                {list.mediumLink && (
                  <a
                    aria-label={`Read Parker Klein's notes on ${list.name} by ${list.author} on Medium`}
                    className="icon-link medium"
                    data-tip={`Read ${list.name} on Medium`}
                    href={list.mediumLink}
                    itemProp="url"
                    rel="noopener noreferrer"
                    target="_blank"
                    title={`Read Parker Klein's notes on ${list.name} by ${list.author} on Medium`}
                  >
                    <FaMedium />
                  </a>
                )}
              </div>
            </div>
          </li>
        ))}
      </ul>
    </main>
  );
};

NotesPage.propTypes = {
  search: PropTypes.string.isRequired,
  setTag: PropTypes.func.isRequired,
  sort: PropTypes.string.isRequired,
  tag: PropTypes.string.isRequired
};

const mapStateToProps = ({ filters: { search, sort, tag } }) => ({
  search,
  sort,
  tag
});

export default connect(mapStateToProps, { ...actions })(NotesPage);

import React from 'react';
import { Link } from 'react-router-dom';

import Helmet from '../components/Helmet';

const AboutPage = () => (
  <main className="main-content">
    <Helmet
      description="Parker Klein is a software developer, reader, note taker, podcaster, youtuber, and writer. Parker has created Twos, Müse, Aware, and 7 Levels Deep, and has worked for Pizza Nova, Teradata, Qualcomm, and Trustwork."
      title="About Parker Klein"
    />
    {/* <h2 className="title">About</h2> */}
    <p className="date">Last updated: 9/16/22</p>
    <p className="note">
      My mission is to help people do what they love. I do this through conversations, understanding, and example.
    </p>
    <p className="note">
      I build{' '}
      <Link
        aria-label="Parker Klein's apps"
        className="note-link"
        id="Apps"
        title="Parker Klein's apps"
        to="/apps"
      >
        apps
      </Link>,{' '}
      <Link
        aria-label="Parker Klein's writings"
        className="note-link"
        id="Write"
        title="Parker Klein's writings"
        to="/write"
      >
        write
      </Link>, read and take{' '}
      <Link
        aria-label="Parker Klein's notes"
        className="note-link"
        id="Notes"
        title="Parker Klein's notes"
        to="/notes"
      >
        notes
      </Link>.
    </p>
    <p className="note">
      I am building{' '}
      <Link
        aria-label="Twos: Remember *Things*"
        className="note-link"
        to="/apps/twos"
        id="Twos"
        itemProp="url"
        title="Twos: Remember *things*"
      >
        Twos
      </Link>, an app to remember *things*.
    </p>
    <p className="note">
      I created a daily reflection journal called{' '}
      <Link
        aria-label="Müse - Daily Reflection App"
        className="note-link"
        to="/apps/muse"
        id="Müse"
        title="Müse - Daily Reflection App"
      >
        Müse
      </Link>, a daily activity tracker called {' '}
      <Link
        aria-label="Aware - Daily Activity Tracker"
        className="note-link"
        to="/apps/aware"
        id="Aware"
        title="Aware - Daily Activity Tracker"
      >
        Aware
      </Link>, and an exercise to discover your why called{' '}
      <Link
        aria-label="7 Levels Deep - Discover Your Why"
        className="note-link"
        to="/apps/7-levels-deep"
        id="7LevelsDeep"
        itemProp="url"
        title="7 Levels Deep - Discover Your Why"
      >
        7 Levels Deep
      </Link>.
    </p>
    <p className="note">
      I work at{' '}
      <Link aria-label="Twos Technologies" className="note-link" to="/apps/twos" id="TwosTechnologies" title="Twos Technologies">
        Twos
      </Link>.
    </p>
    <p className="note">
      I have worked at{' '}
      <Link
        aria-label="Google"
        className="note-link"
        to="/work/google"
        id="Google"
        title="Google"
      >
        Google
      </Link>,{' '}
      <Link
        aria-label="Trustwork"
        className="note-link"
        to="/work/trustwork"
        id="Trustwork"
        title="Trustwork"
      >
        Trustwork
      </Link>,{' '}
      <Link
        aria-label="Qualcomm"
        className="note-link"
        to="/work/qualcomm"
        id="Qualcomm"
        title="Qualcomm"
      >
        Qualcomm
      </Link>, and{' '}
      <Link
        aria-label="Teradata"
        className="note-link"
        to="/work/teradata"
        id="Teradata"
        title="Teradata"
      >
        Teradata
      </Link>, and{' '}
      <a
        aria-label="Pizza Nova"
        className="note-link"
        href="https://www.google.com/search?gs_ssp=eJzj4tTP1TdILy83M1NgNGB0YPDiKsisqkpUyMsvSwQAZAYHuA&q=pizza+nova&oq=pizzanova&aqs=chrome.1.69i57j46i10i131i199i433i465i512j46i10i175i199i512j0i10i457i512j0i512j46i10i199i465i512j46i10i175i199i512j0i10i512l2.2712j0j7&sourceid=chrome&ie=UTF-8"
        id="PizzaNova"
        rel="noopener noreferrer"
        target="_blank"
        title="Pizza Nova"
      >
        Pizza Nova
      </a>.
    </p>
    <p className="note">
      I went to{' '}
      <a
        aria-label="Vanderbilt University"
        className="note-link"
        href="https://www.vanderbilt.edu/"
        id="Vanderbilt"
        rel="noopener noreferrer"
        target="_blank"
        title="Vanderbilt University"
      >
        Vanderbilt University
      </a>{' '}
      and studied Computer Science and Engineering Management.
    </p>
    <p className="note">
      I grew up in San Diego, CA with the best mom, dad, and big brother in the world, playing sports,
      games, and rap music videos.
    </p>
  </main>
);

export default AboutPage;

export default {
  author: 'Eric Schmidt',
  category: 'book',
  date: '7/9/15',
  name: 'How Google Works',
  path: '/notes/how-google-works',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-how-google-works-eric-schmidt.jpg',
  tags: ['Biography', 'Business'],
  time: 0,
  link: 'https://www.amazon.com/How-Google-Works-Eric-Schmidt/dp/1455582328',
  mediumLink: '',
  patreonLink: '',
  podcastLink: '',
  podcastEmbed: '',
  youtubeLink: '',
twosLink: '',
  youtubeEmbed: '',
  releaseDate: '',
  rating: 5.8,
  review:
    "An interesting book on the early internals of Google. How they ran, how things were decided internally, and systems and processes they set up to make employee's lives effective and fun."
};

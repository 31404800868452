export default {
  author: 'James Allen',
  category: 'book',
  date: '7/28/17',
  link: 'https://amzn.to/2xMRlIt',
  name: 'As a Man Thinketh',
  path: '/notes/as-a-man-thinketh',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-as-a-man-thinketh-james-allen.jpg',
  tags: ['Spirituality'],
  time: 14,
  mediumLink: '',
  patreonLink: '',
  podcastLink: 'https://anchor.fm/parkerklein/episodes/As-A-Man-Thinketh-by-James-Allen-e7vgf3',
  podcastEmbed:
    'https://anchor.fm/parkerklein/embed/episodes/As-A-Man-Thinketh-by-James-Allen-e7vgf3',
  youtubeLink: 'https://youtu.be/BHDFL2aZQ-4',
  youtubeEmbed: 'https://www.youtube.com/embed/BHDFL2aZQ-4',
  releaseDate: '10/22/19',
  rating: 9.4,
  review:
    'This book blew my mind wide open. I had to take breaks from reading to get a hold of myself. I had never realized the power we have to observe and control our thoughts. And to do this in order to benefit ourselves and the people around us. Short and powerful read.'
};

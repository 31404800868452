export default {
  category: 'book',
  name: 'Play Bigger',
  author: 'Al Ramadan',
  date: '7/22/22',
  path: '/notes/play-bigger',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-play-bigger-al-ramadan.jpeg',
  tags: ['Business', 'Entrepreneurship', 'Management', 'Leadership', 'Marketing'],
  time: 1,
  rating: 8.7,
  review: '',
  link: 'https://www.amazon.com/Play-Bigger-Dreamers-Innovators-Dominate/dp/0062407619',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
twosLink: 'https://www.twosapp.com/62b98bcfc29c91e2cd9c7b49',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};

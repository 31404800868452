export default {
  author: 'Robert T. Kiyosaki',
  category: 'book',
  date: '6/6/17',
  link: 'https://amzn.to/2jZ5YFq',
  name: 'Rich Dad Poor Dad',
  path: '/notes/rich-dad-poor-dad',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-rich-dad-poor-dad-robert-kiyosaki.jpg',
  tags: ['Business', 'Finance'],
  time: 11,
  mediumLink: '',
  twosLink: 'https://www.TwosApp.com/59374b091be7f4040017c5f1',
  patreonLink: '',
  podcastLink: 'https://anchor.fm/parkerklein/episodes/Rich-Dad-Poor-Dad-by-Robert-Kiyosaki-e96pus',
  podcastEmbed:
    'https://anchor.fm/parkerklein/embed/episodes/Rich-Dad-Poor-Dad-by-Robert-Kiyosaki-e96pus',
  youtubeLink: 'https://youtu.be/hM2k5XSzKYI',
  youtubeEmbed: 'https://www.youtube.com/embed/hM2k5XSzKYI',
  releaseDate: '11/25/19',
  rating: 7.8,
  review:
    'A book about utilizing money to create more money. About thinking abundantly. About doing things that will increase your overall value. Believing money will eventually come as long as you take action.'
};

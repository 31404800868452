export default {
  category: 'book',
  name: 'The Five Dysfunctions of a Team',
  author: 'Patrick Lencioni',
  date: '1/11/24',
  path: '/notes/the-five-dysfunctions-of-a-team',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-five-dysfunctions-of-a-team-patrick-lencioni.jpg',
  tags: ['Business', 'Leadership', 'Management'],
  time: 8,
  rating: 7.6,
  review: '',
  link: 'https://www.amazon.com/Five-Dysfunctions-Team-Leadership-Fable/dp/0787960756',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  twosLink: 'https://www.twosapp.com/658c9f6eaa62155319a740d7',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};

export default {
  category: 'book',
  name: 'Radical Candor',
  author: 'Kim Scott',
  date: '6/24/23',
  path: '/notes/radical-candor',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-radical-candor-kim-scott.jpg',
  tags: ['Business', 'Management', 'Leadership'],
  time: 8,
  rating: 7.9,
  review: '',
  link: 'https://www.amazon.com/Radical-Candor-Revised-Kick-Ass-Humanity/dp/1250235375',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  twosLink: 'https://www.twosapp.com/646fb7188ee0d24c7f9e44ad',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};

import initialState from './initialState';
import ActionTypes from '../actions/actionTypes';

const loadingReducer = (state = initialState.loading, action) => {
  switch (action.type) {
    case ActionTypes.SEND_EMAIL_REQUEST:
    case ActionTypes.SIGN_UP_REQUEST:
      return true;

    case ActionTypes.SEND_EMAIL_FAILURE:
    case ActionTypes.SEND_EMAIL_SUCCESS:
    case ActionTypes.SIGN_UP_FAILURE:
    case ActionTypes.SIGN_UP_SUCCESS:
      return false;

    default:
      return state;
  }
};

export default loadingReducer;

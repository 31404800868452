export default {
  category: 'book',
  name: 'How to Read a Book',
  author: 'Mortimer J. Adler',
  date: '4/19/23',
  path: '/notes/how-to-read-a-book',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-how-to-read-a-book-morimer-j-adler.jpg',
  tags: ['History'],
  time: 17,
  rating: 7.2,
  review: '',
  link: 'https://www.amazon.com/How-Read-Book-Classic-Intelligent/dp/0671212095',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  twosLink: 'https://www.TwosApp.com/63b0b21fe5048f33460f5571',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};

export default {
  author: 'David McCullough',
  category: 'book',
  date: '12/24/17',
  name: 'The Wright Brothers',
  path: '/notes/the-wright-brothers',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-wright-brothers-david-mccullough.jpg',
  tags: ['Engineering', 'History'],
  time: 0,
  link: 'https://www.amazon.com/Wright-Brothers-David-McCullough/dp/1476728755',
  mediumLink: '',
  patreonLink: '',
  podcastLink: '',
  podcastEmbed: '',
  youtubeLink: '',
twosLink: '',
  youtubeEmbed: '',
  releaseDate: '',
  rating: 7.8,
  review:
    'The story of the Wright brothers as they created the first plane and became famous. Great story, well written, inspiring humans.'
};

export default {
  category: 'book',
  name: 'Everybody Writes',
  author: 'Ann Handley',
  date: '6/20/21',
  path: '/notes/everybody-writes',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-everybody-writes-ann-handley.jpg',
  tags: ['Business', 'Marketing'],
  time: 16,
  rating: 0,
  review: '',
  link: 'https://www.amazon.com/Everybody-Writes-Go-Creating-Ridiculously/dp/1118905555',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
twosLink: 'https://www.TwosApp.com/609c74ac351c0481bf772628',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: ''
};

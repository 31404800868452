export default {
  category: 'book',
  name: 'Product-Led Growth',
  author: 'Wes Bush',
  date: '10/12/21',
  path: '/notes/product-led-growth',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-product-led-growth-wes-bush.jpg',
  tags: ['Business', 'Design', 'Marketing', 'Technology'],
  time: 7,
  rating: 7.9,
  review: '',
  link: 'https://www.amazon.com/Product-Led-Growth-Build-Product-Itself-ebook/dp/B07P6288ZF',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
twosLink: 'https://www.TwosApp.com/6154ed2d1f9e7afae9eb8fdb',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: ''
};

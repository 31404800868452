export default {
  author: 'Mitch Albom',
  category: 'book',
  date: '11/25/15',
  name: 'Tuesdays with Morrie',
  path: '/notes/tuesdays-with-morrie',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-tuesdays-with-morrie-mitch-albom.jpg',
  tags: ['Self-help', 'Grief', 'Death'],
  time: 0,
  link: 'https://www.amazon.com/Tuesdays-Morrie-Greatest-Lesson-Anniversary/dp/076790592X',
  mediumLink: '',
  patreonLink: '',
  podcastLink: '',
  podcastEmbed: '',
  youtubeLink: '',
twosLink: '',
  youtubeEmbed: '',
  releaseDate: '',
  rating: 7.7,
  review:
    'A story about a professor and one of his students as the professor prepares to die. The professor passes on his wisdom he has accrued in life.'
};

export default {
  author: 'David Allen',
  category: 'book',
  date: '11/12/17',
  link: 'https://amzn.to/2lcxRKh',
  name: 'Getting Things Done',
  path: '/notes/getting-things-done',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-getting-things-done-david-allen.jpg',
  tags: ['Business', 'Productivity'],
  time: 3,
  mediumLink: '',
  patreonLink: '',
  podcastLink: 'https://anchor.fm/parkerklein/episodes/Getting-Things-Done-by-David-Allen-e6evu6',
  podcastEmbed:
    'https://anchor.fm/parkerklein/embed/episodes/Getting-Things-Done-by-David-Allen-e6evu6',
  youtubeLink: 'https://youtu.be/prRuAE2zYqU',
  youtubeEmbed: 'https://www.youtube.com/embed/prRuAE2zYqU',
  releaseDate: '10/9/19',
  rating: 7.3,
  review:
    "A whole system on helping you get organized so you can free up your mind and time to get more done. If you don't have a system, read this book. If you have a system, read this book to optimize it and keep on track."
};

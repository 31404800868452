export default {
  category: 'book',
  name: 'Jonathan Livingston Seagull',
  author: 'Richard Bach',
  date: '3/4/20',
  path: '/notes/jonathan-livingston-seagull',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-jonathan-livingston-seagull-richard-bach.jpg',
  tags: ['Fiction'],
  time: 2,
  rating: 8.6,
  review:
    'Wonderful story about a seagull who learns to fly and be himself. We create our own limitations and he discovers what freedom we really have and shares it with others.',
  link: 'https://www.amazon.com/Jonathan-Livingston-Seagull-Richard-Bach/dp/0743278909',
  mediumLink: '',
  patreonLink: '',
  podcastLink:
    'https://anchor.fm/parkerklein/episodes/Jonathan-Livingston-Seagull-by-Richard-Bach-ebh7ce',
  podcastEmbed:
    'https://anchor.fm/parkerklein/embed/episodes/Jonathan-Livingston-Seagull-by-Richard-Bach-ebh7ce',
  youtubeLink: 'https://youtu.be/ayQtwOp0RO4',
  youtubeEmbed: 'https://www.youtube.com/embed/ayQtwOp0RO4',
  releaseDate: '3/16/20'
};

export default {
  author: 'Dr. Norman Vincent Peale',
  category: 'book',
  date: '6/1/19',
  link: 'https://amzn.to/2lCgVxd',
  name: 'The Power of Positive Thinking',
  path: '/notes/the-power-of-positive-thinking',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-power-of-positive-thinking-dr-norman-vincent-peale.jpg',
  tags: ['Self-help', 'Religion', 'Spirituality', 'Christianity'],
  time: 16,
  rating: 8.2,
  review:
    "Great book about the benefits of being positive and choosing to think positively. A must read for people who find their minds wandering towards unpleasant thoughts or don't understand that, through practice, they can control what they think."
};

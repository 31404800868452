import initialState from './initialState';
import ActionTypes from '../actions/actionTypes';

const noteReducer = (state = initialState.note, action) => {
  switch (action.type) {
    case ActionTypes.SET_NOTE:
      return action.note;

    case ActionTypes.CLEAR_NOTE:
      return '';

    default:
      return state;
  }
};

export default noteReducer;

import React from 'react';
import { Link } from 'react-router-dom';
import { FaAppStore, FaGooglePlay } from 'react-icons/fa';
import { FiExternalLink } from 'react-icons/fi';
import ReactTooltip from 'react-tooltip';

import Helmet from '../components/Helmet';
import { work } from '../data';
import { formatDate } from '../helpers';

const Work = () => {
  const workCollection = [];
  work.map(job => workCollection.push(`${job.name}`));

  return (
    <main className="main-content">
      <ReactTooltip effect="solid" />
      <Helmet
        description={`Parker Klein's software work. Parker created ${workCollection.join(', ')}`}
        title="Work"
      />
      {/* <h2 className="title">Work</h2> */}
      <ul>
        {work.map(job => (
          <li
            className="list-item row"
            key={`${job.name} ${job.position}`}
            itemScope
            itemType="http://schema.org/Organization"
          >
            <Link
              className="photo"
              aria-label={job.name}
              to={job.path}
              tabIndex={-1}
              title={job.name}
            >
              <img alt={job.name} height={100} itemProp="logo" src={job.logo} width={100} />
            </Link>
            <div className="column">
              <Link aria-label={job.name} to={job.path} title={job.name}>
                <h2 className="title" itemProp="name">
                  {job.name}
                </h2>
                <h3 className="author">{job.position}</h3>
                <p className="date">
                  {`${formatDate(job.startDate)} - ${job.endDate
                    ? formatDate(job.endDate)
                    : 'Present'}`}
                </p>
              </Link>
              <div className="row links">
                {job.url && (
                  <a
                    aria-label={`${job.name} website`}
                    className="icon-link"
                    data-tip={`${job.name}'s website`}
                    href={job.url}
                    id="url"
                    itemProp="url"
                    rel="noopener noreferrer"
                    target="_blank"
                    title={`${job.name} website`}
                  >
                    <FiExternalLink />
                  </a>
                )}
                {job.iOSUrl && (
                  <a
                    aria-label={`${job.name} on the iOS App Store`}
                    className="icon-link app-store"
                    data-tip={`Download ${job.name}`}
                    href={job.url}
                    id="iOSAppStoreUrl"
                    itemProp="url"
                    rel="noopener noreferrer"
                    target="_blank"
                    title={`${job.name} on the iOS App Store`}
                  >
                    <FaAppStore />
                  </a>
                )}
                {job.androidUrl && (
                  <a
                    aria-label={`${job.name} on the Google Play Store`}
                    className="icon-link play-store"
                    data-tip={`Download ${job.name}`}
                    href={job.url}
                    id="googlePlayUrl"
                    itemProp="url"
                    rel="noopener noreferrer"
                    target="_blank"
                    title={`${job.name} on the Google Play Store`}
                  >
                    <FaGooglePlay />
                  </a>
                )}
              </div>
              <meta itemProp="image" content={job.logo} />
              <meta itemProp="url" content={job.url} />
            </div>
          </li>
        ))}
      </ul>
    </main>
  );
};

export default Work;

export default {
  category: 'book',
  name: 'The Way of the Superior Man',
  author: 'David Deida',
  date: '3/15/22',
  path: '/notes/the-way-of-the-superior-man',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-way-of-the-superior-man-david-deida.jpg',
  tags: ['Social Science', 'Psychology'],
  time: 9,
  rating: 8.7,
  review: '',
  link: 'https://www.amazon.com/Way-Superior-Man-Challenges-Anniversary/dp/1622038320',
  mediumLink: 'https://parkerklein.medium.com/the-way-of-the-superior-man-a-spiritual-guide-to-mastering-the-challenges-of-women-work-and-e8efd779709',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
twosLink: 'https://www.twosapp.com/6209b8fd042248500edaa50a',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};

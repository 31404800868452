export default {
  category: 'book',
  name: 'The Virgin Way',
  author: 'Richard Branson',
  date: '6/10/20',
  path: '/notes/the-virgin-way',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-virgin-way-richard-branson.jpg',
  tags: ['Business', 'Leadership', 'Management', 'Entrepreneurship'],
  time: 1,
  rating: 6.8,
  review: '',
  link: 'https://www.amazon.com/Virgin-Way-Its-Worth-Doing/dp/1591847982',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
twosLink: '',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: ''
};

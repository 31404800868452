export default {
  category: 'Work',
  startDate: '5/1/15',
  endDate: '8/1/15',
  name: 'Qualcomm',
  position: 'Software Engineer Intern',
  androidUrl: '',
  iOSUrl: '',
  logo: 'https://twos.s3.amazonaws.com/qualcomm-logo.png',
  url: 'https://www.qualcomm.com',
  path: '/work/qualcomm-intern',
  tags: ['Work'],
  tech: [
    'JavaScript',
    'HTML',
    'CSS',
    'Cordova',
    'PhoneGap',
    'Express',
    'Node.js',
    'Express.js',
    'jQuery',
    'MySQL'
  ],
  accomplishments: [
    'Developed a web and mobile application to automate device testing and analyze results.'
  ],
  location: 'San Diego, CA'
};

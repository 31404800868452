export default {
  author: 'Daniel Kahneman',
  category: 'book',
  date: '10/18/19',
  name: 'Thinking, Fast and Slow',
  path: '/notes/thinking-fast-and-slow',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-thinking-fast-and-slow-daniel-kahneman.jpg',
  tags: ['Business', 'Management', 'Leadership', 'Psychology'],
  time: 32,
  link: 'https://www.amazon.com/Thinking-Fast-Slow-Daniel-Kahneman/dp/0374533555',
  mediumLink: '',
  patreonLink: '',
  podcastLink: '',
  podcastEmbed: '',
  youtubeLink: '',
twosLink: '',
  youtubeEmbed: '',
  releaseDate: '',
  rating: 7.5,
  review:
    'Fascinating book on behavioral economics and how we think, what we miss, and the mistakes we make. Makes it hard to trust yourself and will make you take the time to think through your decisions and huntches.'
};

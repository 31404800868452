export default {
  category: 'write',
  date: '9/13/21',
  name: 'How I Remember Every Thing with Twos',
  path: '/write/how-i-remember-every-thing-with-twos',
  photo: 'https://twos.s3.amazonaws.com/b783faf9-7b27-4aca-b1ad-bfaa4a0a47ea_442552-Albert-Einstein-Quote-Paper-is-to-write-things-down-that-we-need.jpg',
  podcastLink: '',
  youtubeLink: '',
twosLink: '',
  mediumLink: 'https://parkerklein.medium.com/how-i-remember-every-thing-with-twos-%EF%B8%8F-644c3a520e74',
  patreonLink: '',
  tags: ['Write'],
  time: 4
};

export default {
  author: 'Mark Manson',
  category: 'book',
  date: '11/17/18',
  link: 'https://amzn.to/2k2oi0o',
  name: 'The Subtle Art of Not Giving a F*ck',
  path: '/notes/the-subtle-art-of-not-giving-a-fuck',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-subtle-art-of-not-giving-a-fuck-mark-manson.jpg',
  podcastLink:
    'https://anchor.fm/parkerklein/episodes/The-Subtle-Art-of-Not-Giving-a-Fck-by-Mark-Manson--Parker-Kleins-Notes-e53av1',
  podcastEmbed:
    'https://anchor.fm/parkerklein/embed/episodes/The-Subtle-Art-of-Not-Giving-a-Fck-by-Mark-Manson--Parker-Kleins-Notes-e53av1',
  tags: ['Self-help'],
  time: 3,
  youtubeLink: 'https://youtu.be/PF-5z4zs6ko',
  youtubeEmbed: 'https://www.youtube.com/embed/PF-5z4zs6ko',
  releaseDate: '8/24/19',
  rating: 9.1,
  review:
    "We care so much about things that don't even matter. They consume so much of our mindshare. They stop us from doing things and they aren't even real. Do you, do it to the best of your ability, and don't worry about anything else. It all works out."
};

export default {
  category: 'write',
  date: '12/10/17',
  name: 'You Are You',
  path: '/write/you-are-you',
  photo: 'https://twos.s3.amazonaws.com/parker-klein-you-are-you.jpg',
  podcastLink: '',
  youtubeLink: '',
twosLink: '',
  mediumLink: '',
  patreonLink: '',
  tags: ['Write'],
  time: 1
};

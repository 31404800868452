export default {
  category: 'book',
  name: 'Never Eat Alone',
  author: 'Keith Ferrazzi',
  date: '2/16/22',
  path: '/notes/never-eat-alone',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-never-eat-alone-keith-ferrazzi.jpg',
  tags: ['Business', 'Leadership', 'Networking'],
  time: 31,
  rating: 8.8,
  review: '',
  link: 'https://www.amazon.com/Never-Eat-Alone-Expanded-Updated/dp/0385346654',
  mediumLink: 'https://parkerklein.medium.com/never-eat-alone-by-keith-ferrazzi-548bdfa80137',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
twosLink: 'https://www.twosapp.com/61f7d515630e6708397c1775',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};

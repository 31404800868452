export default {
  author: 'Héctor García',
  category: 'book',
  date: '12/21/18',
  link: 'https://amzn.to/2l6zeu6',
  name: 'Ikigai',
  path: '/notes/ikigai',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-ikigai-hector-garcia.jpg',
  youtubeLink: 'https://youtu.be/yjcEmZcZKpg',
  youtubeEmbed: 'https://www.youtube.com/embed/yjcEmZcZKpg',
  podcastLink: 'https://anchor.fm/parkerklein/episodes/Ikigai-by-Hector-Garcia-e5clum',
  podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/Ikigai-by-Hector-Garcia-e5clum',
  tags: ['Health', 'Diet', 'Self-help'],
  time: 16,
  releaseDate: '9/14/19',
  rating: 8.8,
  twosLink: 'https://www.TwosApp.com/5c1dc4581653f000047700b9',
  review:
    'Great book on how to live a long, healthy, and happy life. Presents research and data on people who live the longs and talks about why. Mostly has to do with eating little, finding big meaning in the work you do, and staying active. Loved it.'
};

export default {
  category: 'book',
  name: 'The Power of Myth',
  author: 'Joseph Campbell',
  date: '5/22/20',
  path: '/notes/the-power-of-myth',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-power-of-myth-joseph-campbell.jpg',
  tags: ['Fiction', 'Religion'],
  time: 4,
  rating: 5.6,
  review:
    'Couple great stories and ways of thinking about how connected we all are and the stories we tell ourselves.',
  link: 'https://www.amazon.com/Power-Myth-Joseph-Campbell/dp/0385418868',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
twosLink: '',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: ''
};

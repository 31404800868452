export default {
  category: 'book',
  name: 'In Search of Excellence',
  author: 'Thomas Peters',
  date: '8/11/24',
  path: '/notes/in-search-of-excellence',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-in-search-of-excellence-thomas-peters.jpg',
  tags: ['Business', 'Management', 'Leadership'],
  time: 42,
  rating: 7.9,
  review: '',
  link: 'https://www.amazon.com/Search-Excellence-Americas-Best-Run-Companies/dp/0060548789',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  twosLink: 'https://www.twosapp.com/6699c39517ebef4695c7a4f7',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};

export default {
  category: 'book',
  name: 'Talking to Humans',
  author: 'Giff Constable',
  date: '2/9/21',
  path: '/notes/talking-to-humans',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-talking-to-humans-giff-constable.jpg',
  tags: ['Business', 'User Research', 'Entrepreneurship', 'Product Design'],
  time: 8,
  rating: 8.2,
  review: '',
  link: 'https://www.amazon.com/Talking-Humans-Success-understanding-customers-ebook/dp/B00NSUEUL4',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
twosLink: 'https://www.TwosApp.com/601c3714c5ed420c4ea65413',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: ''
};

export default {
  author: 'Viktor Frankl',
  category: 'book',
  date: '3/2/17',
  link: 'https://amzn.to/2Xf5zAu',
  name: 'Mans Search for Meaning',
  path: '/notes/mans-search-for-meaning',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-mans-search-for-meaning-viktor-frankl.jpg',
  podcastLink:
    'https://anchor.fm/parkerklein/episodes/Mans-Search-for-Meaning-by-Viktor-Frankl-Book-Notes-e37pmu',
  podcastEmbed:
    'https://anchor.fm/parkerklein/embed/episodes/Mans-Search-for-Meaning-by-Viktor-Frankl-Book-Notes-e37pmu',
  tags: ['Psychology', 'History'],
  time: 3,
  youtubeLink: 'https://www.youtube.com/watch?v=RRXDBEaQXcw',
  youtubeEmbed: 'https://www.youtube.com/embed/RRXDBEaQXcw',
  releaseDate: '6/15/19',
  rating: 9.3,
  review:
    'Incredible story of a man who survives multiple concentration camps. A book about finding meaning in your life and using meaning to overcome any pain.',
  mediumLink: 'https://medium.com/twosapp/mans-search-for-meaning-by-viktor-frankl-e51ac4034260',
  twosLink: 'https://www.twosapp.com/58b8ba17b3ae2f040078a41d',
};

import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';

const HelmetComponent = ({ description, title }) => (
  <Helmet>
    <title>{title}</title>
    <meta name="description" content={description} />
  </Helmet>
);

HelmetComponent.propTypes = {
  description: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default HelmetComponent;

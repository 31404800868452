export default {
  author: 'Paulo Coelho',
  category: 'book',
  date: '11/20/16',
  link: 'https://amzn.to/2FpOWb6',
  name: 'The Alchemist',
  path: '/notes/the-alchemist',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-alchemist-paulo-coelho.jpg',
  podcastLink:
    'https://anchor.fm/parkerklein/episodes/The-Alchemist-by-Paulo-Coelho-Book-Notes-e4dq0e',
  podcastEmbed:
    'https://anchor.fm/parkerklein/embed/episodes/The-Alchemist-by-Paulo-Coelho-Book-Notes-e4dq0e',
  tags: ['Fiction'],
  time: 1,
  youtubeLink: 'https://youtu.be/Yokp8jvRUi8',
  youtubeEmbed: 'https://www.youtube.com/embed/Yokp8jvRUi8',
  releaseDate: '6/22/19',
  rating: 9.1,
  review:
    'One of the first books I read that I really loved. Stayed as my favorite book until I read Meditations by Marcus Aurelius. A great book about listening and trusting to yourself and staying open to opportunities.'
};

export default {
  author: 'Travis Bradberry',
  category: 'book',
  date: '7/21/19',
  link: 'https://amzn.to/2Y2JmBP',
  name: 'Emotional Intelligence 2.0',
  path: '/notes/emotional-intelligence-2-0',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-emotional-intelligence-travis-bradberry.jpg',
  tags: ['Self-help', 'Relationships', 'Business', 'Leadership'],
  time: 6,
  rating: 7.2,
  review:
    'Great book on emotional intelligence. Helps you to become more aware of yourself and others in interactions. You can learn a lot from observing the people around you, which will help you interact with them better and communicate with them more effectively.',
  mediumLink: '',
  patreonLink: '',
  podcastLink: 'https://anchor.fm/parkerklein/episodes/Emotional-Intelligence-2-0-by-Travis-Bradberry-egdt8a',
  podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/Emotional-Intelligence-2-0-by-Travis-Bradberry-egdt8a',
  youtubeLink: 'https://youtu.be/LlUhWb8zkOc',
  youtubeEmbed: 'https://www.youtube.com/embed/LlUhWb8zkOc',
  releaseDate: '8/3/20'
};

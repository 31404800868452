import initialState from './initialState';
import ActionTypes from '../actions/actionTypes';

const wroteReducer = (state = initialState.wrote, action) => {
  switch (action.type) {
    case ActionTypes.SET_WROTE:
      return '';

    case ActionTypes.CLEAR_WRITE:
      return action.wrote;

    default:
      return state;
  }
};

export default wroteReducer;

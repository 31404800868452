export default {
  category: 'write',
  date: '10/24/19',
  name: 'A step in the right direction',
  path: '/write/a-step-in-the-right-direction',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-a-step-in-the-right-direction.png',
  podcastLink: '',
  youtubeLink: '',
twosLink: '',
  mediumLink: '',
  patreonLink: '',
  tags: ['Write'],
  time: 1
};

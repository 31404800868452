export default {
  category: 'write',
  date: '9/12/19',
  name: 'Why I Built Aware',
  path: '/write/why-i-built-aware',
  photo: 'https://twos.s3.amazonaws.com/aware-daily-habit-tracker.png',
  podcastLink: '',
  youtubeLink: '',
twosLink: '',
  mediumLink: 'https://parkerklein.medium.com/why-i-built-aware-968dfc388965',
  patreonLink: '',
  tags: ['Write'],
  time: 3
};

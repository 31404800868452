export default {
  category: 'Work',
  startDate: '10/7/19',
  endDate: '3/19/21',
  name: 'Google',
  position: 'Software Engineer, Front End',
  androidUrl: '',
  iOSUrl: '',
  logo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-google-logo.png',
  url: 'https://www.google.com',
  path: '/work/google',
  tags: ['Work'],
  tech: ['Objective-C', 'Swift'],
  accomplishments: [
    'Developed user facing features and fixed bugs for Google Chat and Gmail on iOS.',
    'Simplified the direct message and room creation flows for Google Chat.',
    "Performed on-duty responsibilities and managed the app's release process every 8 weeks.",
    "Helped maintain our team's documentation for onboarding, the feature launch process, A/B testing, visual element logging, and on-duty responsibilities.",
  ],
  location: 'Sunnyvale, CA'
};

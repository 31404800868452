export default {
  category: 'apps',
  startDate: '4/22/19',
  endDate: '',
  name: 'Aware',
  tagLine: 'Daily Activity Tracking',
  description: 'An easy way track and analyze your daily activities.',
  screenshots: [
    'https://twos.s3.amazonaws.com/aware-screenshot-1-twos-technologies.jpg',
    'https://twos.s3.amazonaws.com/aware-screenshot-2-twos-technologies.jpg',
    'https://twos.s3.amazonaws.com/aware-screenshot-3-twos-technologies.jpg',
    'https://twos.s3.amazonaws.com/aware-screenshot-4-twos-technologies.jpg',
    'https://twos.s3.amazonaws.com/aware-screenshot-5-twos-technologies.jpg',
    'https://twos.s3.amazonaws.com/aware-screenshot-6-twos-technologies.jpg'
  ],
  androidUrl: '',
  iOSUrl: 'https://itunes.apple.com/us/app/streaking-daily-habit-tracker/id1462378374?mt=8',
  logo: 'https://twos.s3.amazonaws.com/aware-free-daily-habit-tracker.png',
  url: 'https://www.awarepath.com',
  youtubeLink: 'https://www.youtube.com/playlist?list=PLtAbsz_bXrGLn4Ls3qKoZTPVb9_G1VXjI',
  productHuntLink: 'https://www.producthunt.com/posts/aware-6',
  path: '/apps/aware',
  tags: ['Apps'],
  tech: [
    'React',
    'React Native',
    'HTML',
    'CSS',
    'Express.js',
    'Node.js',
    'MongoDB',
    'Styled Components',
    'Heroku',
    'Redux'
  ],
  future: 'Overall personal stats and activity comparison charts.'
};

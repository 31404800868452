export default {
  category: 'book',
  name: 'Ready For Anything',
  author: 'David Allen',
  date: '5/26/23',
  path: '/notes/ready-for-anything',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-ready-for-anything-david-allen.jpg',
  tags: ['Business', 'Management', 'Productivity'],
  time: 28,
  rating: 6.8,
  review: '',
  link: 'https://www.amazon.com/Ready-Anything-Productivity-Principles-2003-09-15/dp/B01N8Q7GZP/ref=tmm_hrd_swatch_0?_encoding=UTF8&qid=1685100083&sr=8-1',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  twosLink: 'https://www.twosapp.com/643f28d9db7941326570fb42',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};

export default {
  category: 'write',
  date: '10/21/19',
  name: 'Positive Thought Exercises',
  path: '/write/positive-thought-exercises',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-positive-thought-exercises.png',
  podcastLink: '',
  youtubeLink: '',
twosLink: '',
  mediumLink: '',
  patreonLink: '',
  tags: ['Write'],
  time: 1
};

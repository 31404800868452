export default {
  author: 'James L. Adams',
  category: 'book',
  date: '2/8/19',
  link: 'https://amzn.to/2l4qhS1',
  name: 'Conceptual Blockbusting',
  path: '/notes/conceptual-blockbusting',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-conceptual-blockbusting-james-adams.jpg',
  tags: ['Business', 'Psychology'],
  time: 8,
  rating: 4.8,
  review:
    'Short book with a lot of tactics on how to think about problems differently and how to be creative.'
};

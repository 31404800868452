export default {
  category: 'write',
  date: '2/20/20',
  name: 'How People Wake Up At 4:30 AM To Work',
  path: '/write/how-people-wake-up-at-430-am-to-work',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-how-people-wake-up-at-430-am-to-work.jpg',
  podcastLink: '',
  youtubeLink: '',
twosLink: '',
  mediumLink: '',
  patreonLink: '',
  tags: ['Write'],
  time: 1
};

export default {
  category: 'write',
  date: '1/24/20',
  name: "Don't ask for it to be easy",
  path: '/write/dont-ask-for-it-to-be-easy',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-dont-ask-for-it-to-be-easy.png',
  podcastLink: '',
  youtubeLink: '',
twosLink: '',
  mediumLink: '',
  patreonLink: '',
  tags: ['Write'],
  time: 1
};

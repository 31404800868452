export default {
  category: 'book',
  name: 'Show Your Work',
  author: 'Austin Kleon',
  date: '7/22/20',
  path: '/notes/show-your-work',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-show-your-work-austin-kleon.jpg',
  tags: ['Art', 'Creativity'],
  time: 2,
  rating: 7.1,
  review: '',
  link: 'https://www.amazon.com/Show-Your-Work-Austin-Kleon/dp/076117897X',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
twosLink: '',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: ''
};

import initialState from './initialState';
import ActionTypes from '../actions/actionTypes';

const filtersReducer = (state = initialState.filters, action) => {
  switch (action.type) {
    case ActionTypes.CHANGE_SEARCH:
      return {
        ...state,
        search: action.search
      };

    case ActionTypes.CLEAR_SEARCH:
      return {
        ...state,
        search: ''
      };

    case ActionTypes.SET_SORT:
      return {
        ...state,
        sort: action.sort
      };

    case ActionTypes.SET_TAG:
      return {
        ...state,
        tag: action.tag
      };

    default:
      return state;
  }
};

export default filtersReducer;

export default {
  category: 'book',
  name: 'Ask And It Is Given',
  author: 'Esther Hicks',
  date: '11/28/22',
  path: '/notes/ask-and-it-is-given',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-ask-and-it-is-given-esther-hicks.jpg',
  tags: ['Spirituality', 'Self-help'],
  time: 19,
  rating: 8.1,
  review: '',
  link: 'https://www.amazon.com/Ask-Given-Learning-Manifest-Desires/dp/1401904599',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  twosLink: 'https://www.TwosApp.com/62328ca20ef1bae3a2ea5e32',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};

export default {
  author: 'Don Miguel Ruiz',
  category: 'book',
  date: '2/24/17',
  name: 'The Four Agreements',
  path: '/notes/the-four-agreements',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-four-agreements-don-miguel-ruiz.jpg',
  tags: ['Self-help', 'Spirituality', 'Psychology'],
  time: 0,
  link: 'https://www.amazon.com/Four-Agreements-Practical-Personal-Freedom/dp/1878424319',
  mediumLink: '',
  patreonLink: '',
  podcastLink: '',
  podcastEmbed: '',
  youtubeLink: '',
twosLink: '',
  youtubeEmbed: '',
  releaseDate: '',
  rating: 6.8,
  review:
    'Great principles to live your life by. Hopefully we all aspire to these. A book I will return to soon.'
};

export default {
  category: 'book',
  name: 'Blue Ocean Strategy',
  author: 'W. Chan Kim',
  date: '5/13/21',
  path: '/notes/blue-ocean-strategy',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-blue-ocean-strategy-w-chan-kim.jpg',
  tags: ['Marketing', 'Management', 'Leadership'],
  time: 15,
  rating: 7.5,
  review: '',
  link: 'https://www.amazon.com/Blue-Ocean-Strategy-Uncontested-Competition/dp/1591396190',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
twosLink: 'https://www.TwosApp.com/60874e97625f7bc81aae538b',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: ''
};

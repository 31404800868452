export default {
  category: 'book',
  name: 'The Purpose Driven Life',
  author: 'Rick Warren',
  date: '7/24/21',
  path: '/notes/the-purpose-driven-life',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-purpose-driven-life-rick-warren.jpg',
  tags: ['Christianity', 'Religion', 'Self-help'],
  time: 19,
  rating: 7.1,
  review: '',
  link: 'https://www.amazon.com/Purpose-Driven-Life-Rick-Warren/dp/0310205719/ref=pd_lpo_1?pd_rd_i=0310205719&psc=1',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
twosLink: 'https://www.TwosApp.com/60a9bfa17d21be455304d6c7',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: ''
};

export default {
  author: 'Michael Singer',
  category: 'book',
  date: '8/2/19',
  link: 'https://amzn.to/337vjid',
  name: 'The Untethered Soul',
  path: '/notes/the-untethered-soul',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-untethered-soul-michael-singer.jpg',
  tags: ['Psychology', 'Meditation', 'Health'],
  time: 14,
  rating: 7.8,
  review:
    'A book on meditation and spirituality. Learning about yourself, seeing things as they truly are, and accepting reality.'
};

export default {
  category: 'book',
  name: 'Search Inside Yourself',
  author: 'Chade-Meng Tan',
  date: '12/12/19',
  path: '/notes/search-inside-yourself',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-search-inside-yourself-chade-meng-tan.jpg',
  tags: ['Management', 'Leadership', 'Self-help'],
  time: 5,
  rating: 6.8,
  review:
    'Based on a course taught at Google with the same title. Great book to understand how self-awareness and emotional intelligence can improve your happiness and relationships at and away from work.',
  link: 'https://www.amazon.com/Search-Inside-Yourself-Unexpected-Achieving/dp/0062116932',
  mediumLink: '',
  patreonLink: '',
  podcastLink:
    'https://anchor.fm/parkerklein/episodes/Search-Insider-Yourself-by-Chade-Mend-Tan-ea5pn5',
  podcastEmbed:
    'https://anchor.fm/parkerklein/embed/episodes/Search-Insider-Yourself-by-Chade-Mend-Tan-ea5pn5',
  youtubeLink: 'https://youtu.be/9kxyDTpGsyE',
  youtubeEmbed: 'https://www.youtube.com/embed/9kxyDTpGsyE',
  releaseDate: '1/13/20'
};

export default {
  author: 'Brad Stone',
  category: 'book',
  date: '8/12/17',
  name: 'The Everything Store',
  path: '/notes/the-everything-store',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-everything-store-brad-stone.jpg',
  tags: ['Business', 'Leadership', 'Management'],
  time: 0,
  link: 'https://www.amazon.com/Everything-Store-Jeff-Bezos-Amazon-ebook/dp/B00BWQW73E',
  mediumLink: '',
  patreonLink: '',
  podcastLink: '',
  podcastEmbed: '',
  youtubeLink: '',
twosLink: '',
  youtubeEmbed: '',
  releaseDate: '',
  rating: 8.3,
  review:
    'Very solid book about Jeff Bezoz as he started Amazon. Dude went for it and they dominate. Great, inspirational story.'
};

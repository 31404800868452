export default {
  category: 'book',
  name: 'The Happiness Advantage',
  author: 'Shawn Achor',
  date: '8/26/21',
  path: '/notes/the-happiness-advantage',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-happiness-advantage-shawn-achor.jpg',
  tags: ['Business', 'Psychology'],
  time: 13,
  rating: 7.4,
  review: '',
  link: 'https://www.amazon.com/Happiness-Advantage-Principles-Psychology-Performance/dp/0307591549',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
twosLink: 'https://www.TwosApp.com/6122f98e72193dc251163b3e',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: ''
};

export default {
  author: 'Simon Sinek',
  category: 'book',
  date: '1/29/17',
  link: 'https://amzn.to/2SNAf74',
  name: 'Start With Why',
  path: '/notes/start-with-why',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-start-with-why-simon-sinek.jpg',
  tags: ['Business', 'Leadership', 'Entrepreneurship'],
  time: 1,
  rating: 7.4,
  review:
    'Understanding the why behind an action is highly motivating for people. Figure out why you are doing something and you will find a way.',
  mediumLink: '',
  patreonLink: '',
  podcastLink: 'https://anchor.fm/parkerklein/episodes/Start-With-Why-by-Simon-Sinek-ecvrdg',
  podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/Start-With-Why-by-Simon-Sinek-ecvrdg',
  youtubeLink: 'https://youtu.be/VsStVmtLH3o',
  youtubeEmbed: 'https://www.youtube.com/embed/VsStVmtLH3o',
  releaseDate: '4/20/20'
};

export default {
  category: 'Work',
  startDate: '8/1/16',
  endDate: '9/1/17',
  name: 'Qualcomm',
  position: 'Software Engineer',
  androidUrl: '',
  iOSUrl: '',
  logo: 'https://twos.s3.amazonaws.com/qualcomm-logo.png',
  url: 'https://www.qualcomm.com',
  path: '/work/qualcomm',
  tags: ['Work'],
  tech: ['Python', 'SQL'],
  accomplishments: [
    'Developed internal test automation tools.',
    'Lead software integration and testing of the Core component of eight chipsets.'
  ],
  location: 'San Diego, CA'
};

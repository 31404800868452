export default {
  category: 'write',
  date: '11/2/21',
  name: 'Water your garden',
  path: '/write/water-your-garden',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-water-your-garden.jpg',
  podcastLink: '',
  youtubeLink: '',
twosLink: '',
  mediumLink: '',
  patreonLink: '',
  tags: ['Write'],
  time: 1
};

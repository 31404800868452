export default {
  category: 'book',
  name: 'Learning Cocoa with Objective-C',
  author: 'Paris Buttfield-Addison',
  date: '5/5/20',
  path: '/notes/learning-cocoa-with-objective-c',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-learning-cocoa-with-objective-c-paris-buttfield-addison.jpg',
  tags: ['Programming'],
  time: 1,
  rating: 6.4,
  review:
    'Helpful book if you are actively working on one of these topics. They do a great job of walking you through with examples.',
  link: 'https://www.amazon.com/Learning-Cocoa-Objective-C-Developing-Stores/dp/149190139X',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
twosLink: '',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: ''
};

export default {
  date: '1/11/17',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-rhythm-of-life-matthew-kelly.jpg',
  path: '/notes/the-rhythm-of-life',
  name: 'The Rhythm of Life',
  author: 'Matthew Kelly',
  category: 'book',
  tags: ['Religion', 'Christianity', 'Self-help'],
  link: 'https://amzn.to/2lyqIV0',
  time: 5,
  rating: 6.8,
  review:
    'A book about life, doing what you love, and having the courage to do it. Everything else will work itself out.'
};

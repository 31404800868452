export default {
  category: 'book',
  name: 'Amp It Up',
  author: 'Frank Slootman',
  date: '8/7/22',
  path: '/notes/amp-it-up',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-amp-it-up-frank-slootman.jpg',
  tags: ['Business', 'Management', 'Leadership'],
  time: 15,
  rating: 7.4,
  review: '',
  link: 'https://www.amazon.com/Amp-Unlocking-Hypergrowth-Expectations-Intensity/dp/1119836115',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
twosLink: 'https://www.TwosApp.com/62ec697ebf8fa62c6e9135d4',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};

export default {
  author: 'Brian W. Kernighan',
  category: 'book',
  date: '2/11/18',
  link: 'https://amzn.to/2k1rmd9',
  name: 'The Practice of Programming',
  path: '/notes/the-practice-of-programming',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-practice-of-programming-brian-kernighan.jpg',
  tags: ['Software Development'],
  time: 4,
  rating: 7.2,
  review:
    'Tips and principles to use while programming. A great tool for programmers who constantly want to learn and improve their craft.',
  twosLink: 'https://www.twosapp.com/5a80cb1ef6c2810004a442f4',
  mediumLink: 'https://parkerklein.medium.com/the-practice-of-programming-by-brian-kernighan-7f026825d3fb',
};

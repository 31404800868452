export default {
  author: 'Marcus Aurelius',
  category: 'book',
  date: '3/10/17',
  link: 'https://amzn.to/2lwXrdl',
  name: 'Meditations',
  path: '/notes/meditations',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-meditations-marcus-aurelius.jpg',
  tags: ['Ethics', 'Philosophy', 'Stoicism'],
  time: 26,
  rating: 9.6,
  review:
    'My personal favorite. A book about stoicism. A book about focusing and acting on what is in your control and accepting those things which are not in your control. Incredibly settling.',
  mediumLink: '',
  patreonLink: '',
  podcastLink: 'https://anchor.fm/parkerklein/episodes/Meditations-by-Marcus-Aurelius-Part-1-egll8r',
  podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/Meditations-by-Marcus-Aurelius-Part-1-egll8r',
  youtubeLink: 'https://youtu.be/XMHoRa2e39A',
  youtubeEmbed: 'https://www.youtube.com/embed/XMHoRa2e39A',
  releaseDate: '7/20/20'
};

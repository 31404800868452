export default {
  category: 'write',
  date: '10/6/19',
  name: 'Potential',
  path: '/write/potential',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-potential.png',
  podcastLink: '',
  youtubeLink: '',
twosLink: '',
  mediumLink: 'https://parkerklein.medium.com/potential-21ac7f11f394',
  patreonLink: '',
  tags: ['Write'],
  time: 1
};

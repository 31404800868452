export default {
  category: 'book',
  name: 'The Art of Startup Fundraising',
  author: 'Alejandro Cremades',
  date: '6/9/21',
  path: '/notes/the-art-of-startup-fundraising',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-art-of-startup-fundraising-alejandro-cremades.jpg',
  tags: ['Business', 'Venture Capital', 'Entrepreneurship'],
  time: 12,
  rating: 6.5,
  review: '',
  link: 'https://www.amazon.com/Art-Startup-Fundraising-Negotiating-Entrepreneurs-ebook/dp/B01DQ3GY3Y',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
twosLink: 'https://www.TwosApp.com/60b049d01b94b23f722501f8',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: ''
};

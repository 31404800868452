export default {
  author: 'Sam Walton',
  category: 'book',
  date: '7/30/19',
  link: 'https://amzn.to/32UIKBU',
  name: 'Made in America',
  path: '/notes/made-in-america',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-sam-walton-made-in-america.jpg',
  tags: ['Biography'],
  time: 16,
  rating: 8.2,
  review:
    'The story of Sam Walton and Wal-Mart. Incredibly hard worker, driven to provide the best value to his customers, never settling for good enough. Inspiring and motivating.',
  twosLink: 'https://www.twosapp.com/5d411bd4847de85757882ecc',
  mediumLink: 'https://medium.com/twosapp/made-in-america-by-sam-walton-3444b2b686a9',
};

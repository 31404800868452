export default {
  category: 'write',
  date: '11/21/19',
  name: 'Nobody Gives a F*ck',
  path: '/write/nobody-gives-a-fuck',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-nobody-gives-a-fuck.jpg',
  podcastLink: '',
  youtubeLink: '',
twosLink: '',
  mediumLink: '',
  patreonLink: '',
  tags: ['Write'],
  time: 1
};

export default {
  category: 'book',
  name: 'The Tao of Health, Sex, and Longevity',
  author: 'Daniel Reid',
  date: '2/9/21',
  path: '/notes/the-tao-of-health-sex-and-longevity',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-tao-of-health-sex-and-longevity-daniel-reid.jpg',
  tags: ['Philosophy', 'Taoism', 'Spirituality'],
  time: 37,
  rating: 8.8,
  review: '',
  link: 'https://www.amazon.com/Tao-Health-Sex-Longevity-Practical/dp/067164811X',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
twosLink: 'https://www.TwosApp.com/5fda79d93be31468d409835e',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: ''
};

export default {
  author: 'Lao Tzu',
  category: 'book',
  date: '9/21/17',
  link: 'https://amzn.to/2lxs8Pz',
  name: 'Tao Te Ching',
  path: '/notes/tao-te-ching',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-tao-te-ching-lao-tzu.jpg',
  tags: ['Philosophy', 'Taoism'],
  time: 11,
  mediumLink: '',
  patreonLink: '',
  podcastLink: 'https://anchor.fm/parkerklein/episodes/Tao-Te-Ching-by-Lao-Tzu-e6ck87',
  podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/Tao-Te-Ching-by-Lao-Tzu-e6ck87',
  youtubeLink: 'https://youtu.be/iVHBWV80EGs',
  youtubeEmbed: 'https://www.youtube.com/embed/iVHBWV80EGs',
  releaseDate: '10/6/19',
  rating: 9.2,
  review:
    'Loved this book. Loved the connection to everything in the universe. We are just a small part of it. And should act like it.'
};

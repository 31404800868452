import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import * as actions from '../actions';
import Helmet from '../components/Helmet';
import { checkEmail } from '../helpers';

const ContactPage = ({
  email,
  emailSent,
  subject,
  body,
  setError,
  sendEmail,
  error,
  changeEmail,
  changeSubject,
  changeBody,
  loading,
  resetEmail
}) => (
  <main className="main-content">
    <Helmet
      description="Welcome to Parker Klein's homepage. Explore Parker's notes, writings, videos, podcasts, experience, and what he is up to now."
      title="Contact"
    />
    {/* <h2 className="title">Contact</h2> */}
    {emailSent ? (
      <Fragment>
        <p className="note">I'll email you back shortly :)</p>
        <button className="side-menu-button" onClick={resetEmail}>
          Send another email
        </button>
      </Fragment>
    ) : (
      <Fragment>
        <form
          onSubmit={e => {
            e.preventDefault();

            if (!email) {
              setError('Please enter your email.');
              return;
            }

            if (!checkEmail(email)) {
              setError('Please enter a valid email.');
              return;
            }

            if (!subject) {
              setError('Please enter a subject.');
              return;
            }

            if (!body) {
              setError('Please enter a message.');
              return;
            }

            sendEmail(email, subject, body);
          }}
        >
          <p className="form-label">Your email</p>
          <input
            aria-label="Email"
            onChange={e => {
              setError('');
              changeEmail(e.target.value);
            }}
            placeholder="Enter your email"
            value={email}
            type="email"
          />
          <p className="form-label">Subject</p>
          <input
            aria-label="Subject"
            onChange={e => {
              setError('');
              changeSubject(e.target.value);
            }}
            placeholder="Enter a subject"
            value={subject}
            type="text"
          />
          <p className="form-label">Message</p>
          <textarea
            aria-label="Body"
            onChange={e => {
              setError('');
              changeBody(e.target.value);
            }}
            placeholder="Enter a message"
            value={body}
            type="text"
          />
          {error && <div className="contact-error">{error}</div>}
          {loading ? (
            <p className="loading-button">Sending...</p>
          ) : (
            <button
              aria-label="Send"
              className="submit-button"
              onClick={() => {
                if (!email) {
                  setError('Please enter your email.');
                  return;
                }

                if (!checkEmail(email)) {
                  setError('Please enter a valid email.');
                  return;
                }

                if (!subject) {
                  setError('Please enter a subject.');
                  return;
                }

                if (!body) {
                  setError('Please enter a message.');
                  return;
                }

                sendEmail(email, subject, body);
              }}
              title="Send Email"
            >
              Send Email
            </button>
          )}
        </form>
      </Fragment>
    )}
  </main>
);

ContactPage.propTypes = {
  changeEmail: PropTypes.func.isRequired,
  changeSubject: PropTypes.func.isRequired,
  changeBody: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  emailSent: PropTypes.bool.isRequired,
  subject: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  setError: PropTypes.func.isRequired,
  sendEmail: PropTypes.func.isRequired,
  resetEmail: PropTypes.func.isRequired
};

const mapStateToProps = ({ email, emailSent, body, subject, error, loading }) => ({
  body,
  email,
  emailSent,
  error,
  loading,
  subject
});

export default connect(mapStateToProps, { ...actions })(ContactPage);

export default {
  author: 'James Altucher',
  category: 'book',
  date: '5/26/19',
  link: 'https://amzn.to/2l9NQsy',
  name: 'Choose Yourself',
  path: '/notes/choose-yourself',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-choose-yourself-james-altucher.jpg',
  tags: ['Business', 'Self-help'],
  time: 23,
  rating: 6.2,
  review:
    "Book about betting on yourself. Making sure you are taking action and setting you up for success in the long run. You'll fail a lot, but eventually something will work. Just keep doing the work."
};

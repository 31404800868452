import React from 'react';
import { Link } from 'react-router-dom';
import {
  FaAppStore,
  FaGooglePlay,
  FaInstagram,
  FaLinkedin,
  FaProductHunt,
  FaTwitter,
  FaYoutube
} from 'react-icons/fa';
import { FiExternalLink } from 'react-icons/fi';
import ReactTooltip from 'react-tooltip';

import Helmet from '../components/Helmet';
import { apps } from '../data';
import { formatDate } from '../helpers';

const Apps = () => {
  const appsCollection = [];
  apps.map(app => appsCollection.push(`${app.name}`));

  return (
    <main className="main-content">
      <ReactTooltip effect="solid" />
      <Helmet
        description={`Parker Klein's software apps. Parker created ${appsCollection.join(', ')}`}
        title="Apps"
      />
      {/* <h2 className="title">Apps</h2> */}
      <ul>
        {apps.map(app => (
          <li
            className="list-item row"
            key={app.name}
            itemScope
            itemType="http://schema.org/SoftwareApplication"
          >
            <Link
              className="photo"
              aria-label={`${app.name} - ${app.tagLine}`}
              to={app.path}
              tabIndex={-1}
              title={`${app.name} - ${app.tagLine}`}
            >
              <img
                alt={`${app.name} - ${app.tagLine}`}
                height={100}
                itemProp="image"
                src={app.logo}
                width={100}
              />
            </Link>
            <div className="column">
              <Link
                aria-label={`${app.name} - ${app.tagLine}`}
                to={app.path}
                title={`${app.name} - ${app.tagLine}`}
              >
                <h2 className="title" itemProp="name">
                  {app.name}
                </h2>
                <h3 className="author">{app.tagLine}</h3>
                <p className="date">
                  {`${formatDate(app.startDate)} - ${app.endDate
                    ? formatDate(app.endDate)
                    : 'Present'}`}
                </p>
                {!!app.screenshots.length && (
                  <meta itemProp="screenshot" content={app.screenshots[0]} />
                )}
                <meta itemProp="author" content="Parker Klein" />
                <meta itemProp="description" content={app.description} />
                <meta itemProp="dateCreated" content={app.startDate} />
                <meta itemProp="image" content={app.logo} />
                <meta itemProp="url" content={app.url} />
              </Link>
              <div className="row links">
                {app.url && (
                  <a
                    aria-label={`${app.name} - ${app.tagLine} Website`}
                    className="icon-link"
                    data-tip={`${app.name}'s Website`}
                    href={app.url}
                    id="url"
                    itemProp="url"
                    rel="noopener noreferrer"
                    target="_blank"
                    title={`${app.name} - ${app.tagLine} Website`}
                  >
                    <FiExternalLink />
                  </a>
                )}
                {app.iOSUrl && (
                  <a
                    aria-label={`${app.name} - ${app.tagLine} on the iOS App Store`}
                    className="icon-link app-store"
                    data-tip={`Download ${app.name}`}
                    href={app.iOSUrl}
                    id="iOSAppStoreUrl"
                    itemProp="url"
                    rel="noopener noreferrer"
                    target="_blank"
                    title={`${app.name} - ${app.tagLine} on the iOS App Store`}
                  >
                    <FaAppStore />
                  </a>
                )}
                {app.androidUrl && (
                  <a
                    aria-label={`${app.name} - ${app.tagLine} on the Google Play Store`}
                    className="icon-link play-store"
                    data-tip={`Download ${app.name}`}
                    href={app.androidUrl}
                    id="googlePlayUrl"
                    itemProp="url"
                    rel="noopener noreferrer"
                    target="_blank"
                    title={`${app.name} - ${app.tagLine} on the Google Play Store`}
                  >
                    <FaGooglePlay />
                  </a>
                )}
                {app.productHuntLink && (
                  <a
                    aria-label={`${app.name} on Product Hunt`}
                    className="icon-link product-hunt"
                    data-tip={`${app.name} on Product Hunt`}
                    href={app.productHuntLink}
                    id="ProductHunt"
                    rel="noopener noreferrer"
                    target="_blank"
                    title={`${app.name} on Product Hunt`}
                  >
                    <FaProductHunt />
                  </a>
                )}
                {app.youtubeLink && (
                  <a
                    aria-label={`${app.name} YouTube playlist`}
                    className="icon-link youtube"
                    data-tip={`${app.name} on YouTube`}
                    href={app.youtubeLink}
                    id="Youtube"
                    rel="noopener noreferrer"
                    target="_blank"
                    title={`${app.name} YouTube playlist`}
                  >
                    <FaYoutube />
                  </a>
                )}
                {app.instagramLink && (
                  <a
                    aria-label={`${app.name} Instagram account`}
                    className="icon-link instagram"
                    data-tip={`${app.name} on Instagram`}
                    href={app.instagramLink}
                    id="Instagram"
                    rel="noopener noreferrer"
                    target="_blank"
                    title={`${app.name} Instagram account`}
                  >
                    <FaInstagram />
                  </a>
                )}
                {app.twitterLink && (
                  <a
                    aria-label={`${app.name} Twitter account`}
                    className="icon-link twitter"
                    data-tip={`${app.name} on Twitter`}
                    href={app.twitterLink}
                    rel="noopener noreferrer"
                    target="_blank"
                    title={`${app.name} Twitter account`}
                  >
                    <FaTwitter />
                  </a>
                )}
                {app.linkedInLink && (
                  <a
                    aria-label={`${app.name} LinkedIn account`}
                    className="icon-link linkedin"
                    data-tip={`${app.name} on LinkedIn`}
                    href={app.linkedInLink}
                    id="LinkedIn"
                    rel="noopener noreferrer"
                    target="_blank"
                    title={`${app.name} LinkedIn account`}
                  >
                    <FaLinkedin />
                  </a>
                )}
              </div>
            </div>
          </li>
        ))}
      </ul>
    </main>
  );
};

export default Apps;

export default {
  category: 'book',
  name: 'The Prophet',
  author: 'Khalil Gibran',
  date: '11/8/20',
  path: '/notes/the-prophet',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-prophet-khalil-gibran.jpg',
  tags: ['Philosophy'],
  time: 5,
  rating: 8.4,
  review: '',
  link: 'https://www.amazon.com/Prophet-Borzoi-Book-Kahlil-Gibran/dp/0394404289',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
twosLink: '',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: ''
};

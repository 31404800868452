export default {
  author: 'Gary Keller',
  category: 'book',
  date: '5/11/17',
  link: 'https://amzn.to/2k1lGzT',
  name: 'The One Thing',
  path: '/notes/the-one-thing',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-one-thing-gary-keller.jpg',
  tags: ['Business', 'Management'],
  time: 6,
  mediumLink: '',
  patreonLink: '',
  podcastLink: 'https://anchor.fm/parkerklein/episodes/The-ONE-Thing-by-Gary-Keller-e8p540',
  podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/The-ONE-Thing-by-Gary-Keller-e8p540',
  youtubeLink: 'https://youtu.be/ysETnyqJCng',
  youtubeEmbed: 'https://www.youtube.com/embed/ysETnyqJCng',
  releaseDate: '11/4/19',
  rating: 8.2,
  review:
    "What is the one thing that if you did it everything else wouldn't be necessary? Do that one thing. Do the most important thing. Do that continuously and you're making big progress."
};

export default {
  author: 'Stephen R. Covey',
  category: 'book',
  date: '4/7/16',
  link: 'https://amzn.to/2MpKvBa',
  name: 'The 7 Habits of Highly Effective People',
  path: '/notes/the-7-habits-of-highly-effective-people',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-7-habits-of-highly-effective-people-stephen-covey.jpg',
  podcastLink:
    'https://anchor.fm/parkerklein/episodes/The-7-Habits-of-Highly-Effective-People-by-Stephen-R--Covey--Parker-Kleins-Notes--Book-Notes-e4qec5',
  podcastEmbed:
    'https://anchor.fm/parkerklein/embed/episodes/The-7-Habits-of-Highly-Effective-People-by-Stephen-R--Covey--Parker-Kleins-Notes--Book-Notes-e4qec5',
  tags: ['Business', 'Leadership', 'Management'],
  time: 2,
  twosLink: 'https://www.TwosApp.com/57067719ec510b0300f020ce',
  youtubeLink: 'https://youtu.be/uxIGrD1MLoU',
  youtubeEmbed: 'https://www.youtube.com/embed/uxIGrD1MLoU',
  releaseDate: '8/1/19',
  rating: 8.1,
  review:
    'Learn how to get the results you want and benefit others along the way. Reading it makes you feel good and puts your mindset in a great place.'
};

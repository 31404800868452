import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import {
  FaAppStore,
  FaGooglePlay,
  FaInstagram,
  FaProductHunt,
  FaTwitter,
  FaLinkedin,
  FaYoutube
} from 'react-icons/fa';
import { FiExternalLink } from 'react-icons/fi';
import ReactTooltip from 'react-tooltip';

import Helmet from '../components/Helmet';
import { apps } from '../data';
import { formatDate } from '../helpers';

const App = ({ location }) => {
  const { pathname } = location;

  const app = apps.find(app => app.path === pathname) || {};

  return (
    <main className="main-content" itemScope itemType="http://schema.org/SoftwareApplication">
      <ReactTooltip effect="solid" />
      <Helmet
        description={`${app.name} - ${app.tagLine}. ${app.description}`}
        title={`${app.name} - ${app.tagLine}`}
      />
      <div className="column">
        <div className="item mobile-column">
          <img
            className="photo"
            alt={`${app.name} - ${app.tagLine}`}
            height={200}
            itemProp="image"
            src={app.logo}
            width={200}
          />
          <div className="column">
            <h2 className="title" itemProp="name">
              {app.name}
            </h2>
            <h3 className="author">{app.tagLine}</h3>
            <p className="date" itemProp="dateCreated">
              {`${formatDate(app.startDate)} - ${app.endDate
                ? formatDate(app.endDate)
                : 'Present'}`}
            </p>
            <div className="row">
              {app.url && (
                <a
                  aria-label={`${app.name} - ${app.tagLine} Website`}
                  className="icon-link"
                  data-tip={`${app.name}'s Website`}
                  href={app.url}
                  id="url"
                  itemProp="url"
                  rel="noopener noreferrer"
                  target="_blank"
                  title={`${app.name} - ${app.tagLine} Website`}
                >
                  <FiExternalLink />
                </a>
              )}
              {app.iOSUrl && (
                <a
                  aria-label={`${app.name} - ${app.tagLine} on the iOS App Store`}
                  className="icon-link app-store"
                  data-tip={`Download ${app.name}`}
                  href={app.iOSUrl}
                  id="iOSAppStoreUrl"
                  itemProp="url"
                  rel="noopener noreferrer"
                  target="_blank"
                  title={`${app.name} - ${app.tagLine} on the iOS App Store`}
                >
                  <FaAppStore />
                </a>
              )}
              {app.androidUrl && (
                <a
                  aria-label={`${app.name} - ${app.tagLine} on the Google Play Store`}
                  className="icon-link play-store"
                  data-tip={`Download ${app.name}`}
                  href={app.androidUrl}
                  id="googlePlayUrl"
                  itemProp="url"
                  rel="noopener noreferrer"
                  target="_blank"
                  title={`${app.name} - ${app.tagLine} on the Google Play Store`}
                >
                  <FaGooglePlay />
                </a>
              )}
              {app.productHuntLink && (
                <a
                  aria-label={`${app.name} on Product Hunt`}
                  className="icon-link product-hunt"
                  data-tip={`${app.name} on Product Hunt`}
                  href={app.productHuntLink}
                  id="ProductHunt"
                  rel="noopener noreferrer"
                  target="_blank"
                  title={`${app.name} on Product Hunt`}
                >
                  <FaProductHunt />
                </a>
              )}
              {app.youtubeLink && (
                <a
                  aria-label={`${app.name} YouTube playlist`}
                  className="icon-link youtube"
                  data-tip={`${app.name} on YouTube`}
                  href={app.youtubeLink}
                  id="Youtube"
                  rel="noopener noreferrer"
                  target="_blank"
                  title={`${app.name} YouTube playlist`}
                >
                  <FaYoutube />
                </a>
              )}
              {app.instagramLink && (
                <a
                  aria-label={`${app.name} Instagram account`}
                  className="icon-link instagram"
                  data-tip={`${app.name} on Instagram`}
                  href={app.instagramLink}
                  id="Instagram"
                  rel="noopener noreferrer"
                  target="_blank"
                  title={`${app.name} Instagram account`}
                >
                  <FaInstagram />
                </a>
              )}
              {app.twitterLink && (
                <a
                  aria-label={`${app.name} Twitter account`}
                  className="icon-link twitter"
                  data-tip={`${app.name} on Twitter`}
                  href={app.twitterLink}
                  rel="noopener noreferrer"
                  target="_blank"
                  title={`${app.name} Twitter account`}
                >
                  <FaTwitter />
                </a>
              )}
              {app.linkedInLink && (
                <a
                  aria-label={`${app.name} LinkedIn account`}
                  className="icon-link linkedin"
                  data-tip={`${app.name} on LinkedIn`}
                  href={app.linkedInLink}
                  id="LinkedIn"
                  rel="noopener noreferrer"
                  target="_blank"
                  title={`${app.name} LinkedIn account`}
                >
                  <FaLinkedin />
                </a>
              )}
            </div>
            <meta itemProp="author" content="Parker Klein" />
          </div>
        </div>
        <div>
          <p itemProp="description" className="note">
            {app.description}
          </p>
          {app.team &&
            !!app.team.length && (
              <p className="note">
                Team:{' '}
                {app.team.map((teammate, index) => (
                  <Fragment key={teammate.name}>
                    {teammate.url ? (
                      <a
                        aria-label={`${teammate.name}'s website`}
                        className="note-link"
                        href={teammate.url}
                        rel="noopener noreferrer"
                        target="_blank"
                        title={`${teammate.name}'s website`}
                      >
                        {teammate.name}
                      </a>
                    ) : (
                      teammate.name
                    )}
                    <span className="note-detail">{` (${teammate.role})${index !==
                    app.team.length - 1
                      ? ', '
                      : ''}`}</span>
                  </Fragment>
                ))}
              </p>
            )}
          <p className="note">Tech: {app.tech.join(', ')}</p>
          <p className="note">Future improvements: {app.future}</p>
        </div>
        <div className="item mobile-column screenshots">
          {app.screenshots.map(screenshot => (
            <img
              className="photo"
              key={screenshot}
              alt={`${app.name} - ${app.tagLine}`}
              height={432}
              itemProp="screenshot"
              src={screenshot}
              width={200}
            />
          ))}
        </div>
      </div>
    </main>
  );
};

App.propTypes = {
  location: PropTypes.object.isRequired
};

export default withRouter(App);

export default {
  category: 'write',
  date: '9/16/19',
  name: 'Why Everything Is The Greatest',
  path: '/write/why-everything-is-the-greatest',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-why-everything-is-the-greatest.jpg',
  podcastLink: '',
  youtubeLink: '',
twosLink: '',
  mediumLink: 'https://parkerklein.medium.com/why-everything-is-the-greatest-856ccdd884c6',
  patreonLink: '',
  tags: ['Write'],
  time: 1
};

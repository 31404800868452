export default {
  author: 'Ayn Rand',
  category: 'book',
  date: '1/2/18',
  name: 'Atlas Shrugged',
  path: '/notes/atlas-shrugged',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-atlas-shrugged-ayn-rand.jpg',
  tags: ['Fiction', 'Philosophy'],
  time: 0,
  link: 'https://www.amazon.com/Atlas-Shrugged-Ayn-Rand/dp/0451191145',
  mediumLink: '',
  patreonLink: '',
  podcastLink: '',
  podcastEmbed: '',
  youtubeLink: '',
twosLink: '',
  youtubeEmbed: '',
  releaseDate: '',
  rating: 9.1,
  review:
    "Thicc. Big boi, but incredible novel. Ayn Rand puts a great spin on the world and how to thnink about our culture. Great story about standing up for yourself and your beliefs. Great insight into people's personalities."
};

export default {
  author: 'Jim Collins',
  category: 'book',
  date: '2/8/19',
  link: 'https://amzn.to/2jML5x8',
  name: 'Built to Last',
  path: '/notes/built-to-last',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-built-to-last-jim-collins.jpg',
  tags: ['Business', 'Management', 'Leadership'],
  time: 24,
  rating: 7.2,
  review:
    'Book about successful companies and what made them successful. Teaches you how to lead and what can be important. Pretty long read with a lot of examples.'
};

export default {
  category: 'book',
  name: 'Self-Reliance',
  author: 'Ralph Waldo Emerson',
  date: '4/21/24',
  path: '/notes/self-reliance',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-self-reliance-ralph-waldo-emerson.jpg',
  tags: ['Philosophy'],
  time: 25,
  rating: 7.9,
  review: '',
  link: 'https://www.amazon.com/Self-Reliance-Other-Essays-Thrift-Editions/dp/0486277909',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  twosLink: 'https://www.twosapp.com/6621b6f319e9dd09b9586b5e',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};

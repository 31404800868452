export default {
  author: 'Steve Krug',
  category: 'book',
  date: '5/14/18',
  link: 'https://amzn.to/2lf3EKL',
  name: "Don't Make Me Think",
  path: '/notes/dont-make-me-think',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-dont-make-me-think-steve-krug.jpg',
  tags: ['Web Development', 'Software Development', 'User Interface', 'Design'],
  time: 6,
  rating: 7.1,
  review:
    "Great, short book on user friendly UI and UX. Lead them on their way. Don't overwhelm them or give them a bunch of choices. Great book for developers and designers."
};

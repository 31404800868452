export default {
  category: 'book',
  name: 'The Four Agreements',
  author: 'Don Miguel Ruiz',
  date: '1/2/24',
  path: '/notes/the-four-agreements-2',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-four-agreements-don-miguel-ruiz.jpg',
  tags: ['Psychology', 'Self-help', 'Spirituality'],
  time: 23,
  rating: 8.8,
  review: '',
  link: 'https://www.amazon.com/Four-Agreements-Practical-Personal-Freedom/dp/1878424319',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  twosLink: 'https://www.twosapp.com/658e24d81399adbf72976cd9',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};

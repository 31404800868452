export default {
  author: 'Kamal Ravikant',
  category: 'book',
  date: '7/5/17',
  link: 'https://amzn.to/2lx64Vf',
  name: 'Rebirth',
  path: '/notes/rebirth',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-rebirth-kamal-ravikant.jpg',
  tags: ['Fiction'],
  time: 6,
  rating: 6.7,
  review:
    'A great story of a man who learns how to live. Who learns to do what he believes in and feels right.'
};

export default {
  category: 'book',
  name: 'Deep Work',
  author: 'Cal Newport',
  date: '3/30/24',
  path: '/notes/deep-work',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-deep-work-cal-newport.jpg',
  tags: ['Business', 'Productivity', 'Self-help'],
  time: 39,
  rating: 7.9,
  review: '',
  link: 'https://www.amazon.com/Deep-Work-Focused-Success-Distracted/dp/1455586692',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  twosLink: 'https://www.TwosApp.com/65f2404e6e3c4c243ab35f2c',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};

export default {
  category: 'book',
  name: 'Getting Real',
  author: '37signals',
  date: '2/12/22',
  path: '/notes/getting-real',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-getting-real-37-signals.jpg',
  tags: ['Programming', 'Design', 'Software Development', 'User Interface', 'Web Design', 'Web Development'],
  time: 6,
  rating: 8.5,
  review: '',
  link: 'https://www.amazon.com/Getting-Real-Smarter-Successful-Application/dp/0578012812',
  mediumLink: 'https://medium.com/twosapp/getting-real-by-37-signals-ae59e5c29bc0',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
twosLink: 'https://www.twosapp.com/61f1fca4f0b792cba5befbec',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};

export default {
  author: 'Ryan Holiday',
  category: 'book',
  date: '12/2/19',
  name: 'Stillness is the Key',
  path: '/notes/stillness-is-the-key',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-stillness-is-the-key-ryan-holiday.jpg',
  tags: ['Philosophy', 'Self-help'],
  time: 9,
  link: 'https://www.amazon.com/Stillness-Key-Ryan-Holiday/dp/0525538585',
  // mediumLink: '',
  // patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
twosLink: '',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  // releaseDate: '',
  rating: 7.9,
  review:
    "A book about quieting your mind so you can think clearly and won't be driven by worry or disabling thoughts. We will get to the real truth were we can know ourselves and create the things we love and want."
};

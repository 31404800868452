export default {
  category: 'book',
  name: 'The Surrender Experiment',
  author: 'Michael Singer',
  date: '6/1/24',
  path: '/notes/the-surrender-experiment',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-surrender-experiment-michael-singer.jpg',
  tags: ['Biography', 'Autobiography', 'Memoir', 'Spirituality', 'Self-help', 'Business', 'Leadership'],
  time: 23,
  rating: 8.6,
  review: '',
  link: 'https://www.amazon.com/Surrender-Experiment-Journey-Lifes-Perfection/dp/080414110X',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  twosLink: 'https://www.TwosApp.com/6647e94f8749a01e6a142c2d',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};

export default {
  category: 'book',
  name: 'Venture Deals',
  author: 'Brad Feld',
  date: '1/31/22',
  path: '/notes/venture-deals',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-venture-deals-brad-feld.jpg',
  tags: ['Entrepreneurship', 'Business', 'Negotiating', 'Finance', 'Venture Capital', 'Fundraising'],
  time: 11,
  rating: 7.5,
  review: '',
  link: 'https://www.amazon.com/Venture-Deals-Smarter-Lawyer-Capitalist/dp/1119594820/ref=sr_1_1?gclid=Cj0KCQiArt6PBhCoARIsAMF5waidnz198PTs9DVWQiLlL0BqmFU72SQvi3eOLs-fpBZprFKf9Kp9e-MaAvA8EALw_wcB&hvadid=410039444101&hvdev=c&hvlocphy=9012094&hvnetw=g&hvqmt=e&hvrand=13543594068479732444&hvtargid=kwd-323185899&hydadcr=21872_11241665&keywords=venture+deals&qid=1643629669&sr=8-1',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  twosLink: 'https://www.twosapp.com/616d3c0813324714faf06cec',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};

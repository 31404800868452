export default {
  category: 'book',
  name: 'The Mountain Is You',
  author: 'Brianna West',
  date: '7/18/24',
  path: '/notes/the-mountain-is-you',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-mountain-is-you-brianna-west.jpg',
  tags: ['Tag'],
  time: 37,
  rating: 8.1,
  review: '',
  link: 'https://www.amazon.com/Mountain-You-Transforming-Self-Sabotage-Self-Mastery/dp/1949759229',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  twosLink: 'https://www.twosapp.com/66919ffa17ebef4695c77bf9',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};

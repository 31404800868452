export default {
  author: 'John Mongan',
  category: 'book',
  date: '7/14/17',
  link: 'https://www.amazon.com/Programming-Interviews-Exposed-Secrets-Landing/dp/1118261364',
  mediumLink: '',
  name: 'Programming Interviews Exposed',
  path: '/notes/programming-interviews-exposed',
  patreonLink: '',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-programming-interviews-exposed-john-mongan.jpg',
  podcastLink: '',
  podcastEmbed: '',
  tags: ['Programming', 'Interview'],
  time: 6,
  youtubeLink: '',
twosLink: '',
  youtubeEmbed: '',
  releaseDate: '',
  rating: 6.4,
  review:
    'Nice supplement to Cracking the Coding Interview. Copies many of the same topics with less examples. A helpful refence for programming interviews.'
};

export default {
  category: 'write',
  date: '8/19/17',
  name: 'Time',
  path: '/write/time',
  photo: 'https://twos.s3.amazonaws.com/parker-klein-time.png',
  podcastLink: '',
  youtubeLink: '',
twosLink: '',
  mediumLink: '',
  patreonLink: '',
  tags: ['Write'],
  time: 1
};

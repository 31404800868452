export default {
  category: 'book',
  name: 'Atomic Habits',
  author: 'James Clear',
  date: '3/20/21',
  path: '/notes/atomic-habits',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-atomic-habits-james-clear.jpg',
  tags: ['Health', 'Psychology', 'Self-help'],
  time: 19,
  rating: 8.2,
  review: '',
  link: 'https://www.amazon.com/Atomic-Habits-Proven-Build-Break/dp/0735211299',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
twosLink: 'https://www.TwosApp.com/604e3f58408d8d598dc76d5c',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: ''
};

export default {
  category: 'book',
  name: 'The Intelligent Investor',
  author: 'Benjamin Graham',
  date: '2/9/20',
  path: '/notes/the-intelligent-investor',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-intelligent-investor-benjamin-graham.jpg',
  tags: ['Finance', 'Money', 'Business'],
  time: 8,
  rating: 8.5,
  review:
    'Great book for all investors new and old. Strategies for defensive and aggressive investors. Many studies to learn from and tactics to utilize.',
  link: 'https://www.amazon.com/Intelligent-Investor-Definitive-Investing-Essentials/dp/0060555661',
  twosLink: 'https://www.twosapp.com/5d6933ac2ff376b60277c6ff',
  mediumLink: '',
  patreonLink: '',
  podcastLink:
    'https://anchor.fm/parkerklein/episodes/The-Intelligent-Investor-by-Benjamin-Graham-ebul1m',
  podcastEmbed:
    'https://anchor.fm/parkerklein/embed/episodes/The-Intelligent-Investor-by-Benjamin-Graham-ebul1m',
  youtubeLink: 'https://youtu.be/r-K61XOHQWA',
  youtubeEmbed: 'https://www.youtube.com/embed/r-K61XOHQWA',
  releaseDate: '3/30/20'
};

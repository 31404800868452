export default {
  author: 'J. D. Salinger',
  category: 'book',
  date: '2/22/19',
  link: 'https://amzn.to/2jElNBa',
  name: 'The Catcher in the Rye',
  path: '/notes/the-catcher-in-the-rye',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-catcher-in-the-rye-jd-salinger.jpg',
  tags: ['Fiction'],
  time: 2,
  rating: 5.4,
  review: 'Book about a young guy that thinks the world is out to get him.'
};

export default {
  author: 'George Clason',
  category: 'book',
  date: '7/23/16',
  link: 'https://amzn.to/2lxYaLh',
  name: 'The Richest Man in Babylon',
  path: '/notes/the-richest-man-in-babylon',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-richest-man-in-babylon-george-clason.jpg',
  podcastLink:
    'https://anchor.fm/parkerklein/episodes/The-Richest-Man-in-Babylon-by-George-Clason--Parker-Kleins-Notes--Book-Notes-e4t3e3',
  podcastEmbed:
    'https://anchor.fm/parkerklein/embed/episodes/The-Richest-Man-in-Babylon-by-George-Clason--Parker-Kleins-Notes--Book-Notes-e4t3e3',
  tags: ['Business', 'Finance', 'Self-help'],
  time: 2,
  youtubeLink: 'https://youtu.be/0r5n5dY2yUg',
  youtubeEmbed: 'https://www.youtube.com/embed/0r5n5dY2yUg',
  mediumLink: 'https://medium.com/twosapp/the-richest-man-in-babylon-by-george-clason-479ee5b7ef35',
  twosLink: 'https://www.TwosApp.com/579418a62ab1dc03007847b6',
  releaseDate: '8/8/19',
  rating: 8.9,
  review:
    'Short book on personal finance. Simple principles that I use to save money and give back.'
};

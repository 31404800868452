export default {
  author: 'Rhonda Byrne',
  category: 'book',
  date: '2/16/18',
  link: 'https://amzn.to/2ladvkW',
  name: 'The Secret',
  path: '/notes/the-secret',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-secret-rhonda-byrne.jpg',
  tags: ['Spirituality'],
  time: 23,
  rating: 8.1,
  review:
    'A book about manifesting your reality. It works. Figure out exactly what you want to make happen and then do it. Execute on it every day and it will happen.'
};

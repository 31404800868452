export default {
  category: 'write',
  date: '4/6/20',
  name: 'The Point',
  path: '/write/the-point',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-point.jpg',
  podcastLink: '',
  youtubeLink: '',
twosLink: '',
  mediumLink: '',
  patreonLink: '',
  tags: ['Write'],
  time: 1
};

export default {
  author: 'Spencer Johnson',
  category: 'book',
  date: '3/3/20',
  name: 'Who Moved My Cheese?',
  path: '/notes/who-moved-my-cheese',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-who-moved-my-cheese-spencer-johnson.jpg',
  tags: ['Business', 'Management'],
  time: 4,
  link: 'https://www.amazon.com/Who-Moved-My-Cheese-Mazing-ebook/dp/B004CR6AM4',
  mediumLink: '',
  patreonLink: '',
  podcastLink:
    'https://anchor.fm/parkerklein/episodes/Who-Moved-My-Cheese--by-Spencer-Johnson-ebh7j8',
  podcastEmbed:
    'https://anchor.fm/parkerklein/embed/episodes/Who-Moved-My-Cheese--by-Spencer-Johnson-ebh7j8',
  youtubeLink: 'https://youtu.be/0QDsbogOZUU',
  youtubeEmbed: 'https://www.youtube.com/embed/0QDsbogOZUU',
  twosLink: 'https://www.twosapp.com/5e5a9fe8ecc45c2b93ef7bb8',
  releaseDate: '3/23/20',
  rating: 8.9,
  review:
    'An incredible, short story about facing change and seeing it as an opportunity to learn and grow. Fear of change restricts our progress. Seek out change, embrace it, and find joy in new experiences.'
};

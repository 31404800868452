export default {
  category: 'book',
  name: 'Learning Node.js',
  author: 'Marc Wandschneider',
  date: '12/6/20',
  path: '/notes/learning-nodejs',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-learning-nodejs-marc-wandschneider.jpg',
  tags: ['Computer Science', 'Technology', 'Web Development'],
  time: 4,
  rating: 6.3,
  review: '',
  link: 'https://www.amazon.com/Learning-Node-js-Hands-Applications-JavaScript/dp/0321910575',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
twosLink: 'https://www.TwosApp.com/5fc531d4cde2ce2f9ff3af8d',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: ''
};

export default {
  category: 'write',
  date: '3/6/17',
  name: 'How to not take things personally',
  path: '/write/how-to-not-take-things-personally',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-how-to-not-take-things-personally.jpg',
  podcastLink: '',
  youtubeLink: '',
twosLink: '',
  mediumLink: '',
  patreonLink: '',
  tags: ['Write'],
  time: 1
};

import PropTypes from 'prop-types';
import React from 'react';
import { FiX } from 'react-icons/fi';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import * as actions from '../actions';
import Links from './Links';
import NewsForm from './NewsForm';

const SideMenu = ({ location, sideMenu, toggleSideMenu }) => {
  const { pathname } = location;

  return (
    <div
      className={`side-menu ${sideMenu ? 'open' : ''}`}
      itemScope
      itemType="http://schema.org/Person"
    >
      <div className="side-menu-header">
        <button
          aria-label="Close side menu"
          className="menu-button"
          onClick={e => {
            e.stopPropagation();
            toggleSideMenu();
          }}
          title="Close side menu"
        >
          <FiX />
        </button>
        <Link
          aria-label="Parker Klein's Personal Website"
          className="row-center"
          id="Parker"
          to="/"
          title="Parker Klein's Personal Website"
        >
          <img
            alt="Parker Klein"
            className="side-menu-photo"
            itemProp="image"
            src="https://twos.s3.amazonaws.com/parkerklein/parker-klein-photo-v2.jpg"
          />
          <div className="col">
            <h1>Parker Klein</h1>
            <p className="side-menu-sub-title">Creator</p>
          </div>
        </Link>
        <meta itemProp="name" content="Parker Klein" />
        <meta itemProp="birthDate" content="1993-11-05" />
        <meta itemProp="jobTitle" content="Software Developer" />
        <meta itemProp="url" content="https://www.parkerklein.com" />
        <meta
          itemProp="description"
          content="Parker Klein is the creator of Twos, Müse, Aware, 7 Levels Deep, and the Parker Klein's Notes YouTube channel and podcast. Parker Klein is a software developer, note taker, podcaster, youtuber, and writer."
        />
      </div>
      <Link
        aria-label="Parker Klein's Personal Website"
        className={`side-menu-button ${pathname === '/' ? 'selected' : ''}`}
        to="/"
        onClick={toggleSideMenu}
        id="Home"
        title="Parker Klein's Personal Website"
      >
        Home
      </Link>
      <Link
        aria-label="Parker Klein Now"
        className={`side-menu-button ${pathname === '/now' ? 'selected' : ''}`}
        to="/now"
        onClick={toggleSideMenu}
        id="Now"
        title="Parker Klein Now"
      >
        Now
      </Link>
      <Link
        aria-label="About Parker Klein"
        className={`side-menu-button ${pathname === '/about' ? 'selected' : ''}`}
        to="/about"
        onClick={toggleSideMenu}
        id="About"
        title="About Parker Klein"
      >
        About
      </Link>
      <Link
        aria-label="Parker Klein's Apps"
        className={`side-menu-button ${pathname.includes('/apps') ? 'selected' : ''}`}
        to="/apps"
        onClick={toggleSideMenu}
        id="Apps"
        title="Parker Klein's Apps"
      >
        Apps
      </Link>
      <Link
        aria-label="Parker Klein's Book Notes"
        className={`side-menu-button ${pathname.includes('/notes') ? 'selected' : ''}`}
        to="/notes"
        onClick={toggleSideMenu}
        id="Notes"
        title="Parker Klein's Book Notes"
      >
        Notes
      </Link>
      <Link
        aria-label="Parker Klein's Writings"
        className={`side-menu-button ${pathname.includes('/write') ? 'selected' : ''}`}
        to="/write"
        onClick={toggleSideMenu}
        id="Write"
        title="Parker Klein's Writings"
      >
        Write
      </Link>
      {/*<Link
        aria-label="Parker Klein's YouTube Videos"
        className={`side-menu-button ${pathname.includes('/videos') ? 'selected' : ''}`}
        to="/videos"
        onClick={toggleSideMenu}
        id="Videos"
        title="Parker Klein's Youtube Videos"
      >
        Videos
      </Link>
      <Link
        aria-label="Parker Klein's Podcast"
        className={`side-menu-button ${pathname.includes('/podcast') ? 'selected' : ''}`}
        to="/podcast"
        onClick={toggleSideMenu}
        id="Podcast"
        title="Parker Klein's Podcast"
      >
        Podcast
      </Link>*/}
      <Link
        aria-label="Parker Klein's Work"
        className={`side-menu-button ${pathname.includes('/work') ? 'selected' : ''}`}
        to="/work"
        onClick={toggleSideMenu}
        id="Work"
        title="Parker Klein's Work"
      >
        Work
      </Link>
      <Link
        aria-label="Contact Parker Klein"
        className={`side-menu-button ${pathname.includes('/contact') ? 'selected' : ''}`}
        to="/contact"
        onClick={toggleSideMenu}
        id="Contact"
        title="Contact Parker Klein"
      >
        Contact
      </Link>
      <Links />
      <NewsForm />
    </div>
  );
};

SideMenu.propTypes = {
  location: PropTypes.object.isRequired,
  sideMenu: PropTypes.bool.isRequired,
  toggleSideMenu: PropTypes.func.isRequired
};

const mapStateToProps = ({ sideMenu }) => ({
  sideMenu
});

export default withRouter(connect(mapStateToProps, { ...actions })(SideMenu));

import PropTypes from 'prop-types';
import React from 'react';

const EmptyMessage = ({ type }) => (
  <p className="empty-message">
    {`No ${type}`}
  </p>
);

EmptyMessage.propTypes = {
  type: PropTypes.string,
};

EmptyMessage.defaultProps = {
  type: 'notes',
};

export default EmptyMessage;

export default {
  category: 'book',
  name: 'The Tipping Point',
  author: 'Malcolm Gladwell',
  date: '11/21/20',
  path: '/notes/the-tipping-point',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-tipping-point-malcolm-gladwell.jpg',
  tags: ['Business', 'Marketing', 'Sales'],
  time: 1,
  rating: 7.4,
  review: '',
  link: 'https://www.amazon.com/Tipping-Point-Little-Things-Difference/dp/0316346624',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
twosLink: 'https://www.TwosApp.com/5faf697ff3c73defaf3caefe',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: ''
};

export default {
  author: 'Steven Pressfield',
  category: 'book',
  date: '12/19/17',
  link: 'https://amzn.to/2JxHI7i',
  name: 'The War of Art',
  path: '/notes/the-war-of-art',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-war-of-art-steven-pressfield.jpg',
  tags: ['Self-help'],
  time: 12,
  mediumLink: 'https://medium.com/twosapp/the-war-of-art-break-through-thee-blocks-and-win-your-inner-creative-battles-by-steven-pressfield-2429e4b7aa54',
  twosLink: 'https://www.twosapp.com/5a392667a778b90004570d1f',
  patreonLink: '',
  podcastLink: 'https://anchor.fm/parkerklein/episodes/The-War-of-Art-by-Steven-Pressfield-e8ggvp',
  podcastEmbed:
    'https://anchor.fm/parkerklein/embed/episodes/The-War-of-Art-by-Steven-Pressfield-e8ggvp',
  youtubeLink: 'https://youtu.be/4cLDL_xt84I',
  youtubeEmbed: 'https://www.youtube.com/embed/4cLDL_xt84I',
  releaseDate: '10/31/19',
  rating: 7.7,
  review:
    'A book about the struggle every artist faces while trying to create. Overcome it with pure persistence while knowing it must be overcome.'
};

export default {
  category: 'book',
  name: 'Dare to Lead',
  author: 'Brené Brown',
  date: '5/14/21',
  path: '/notes/dare-to-lead',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-dare-to-lead-brene-brown.jpg',
  tags: ['Leadership', 'Psychology', 'Self-help'],
  time: 15,
  rating: 8.2,
  review: '',
  link: 'https://www.amazon.com/Dare-Lead-Brave-Conversations-Hearts/dp/0399592520',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
twosLink: 'https://www.TwosApp.com/60860fa38cc5aaef387eef80',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: ''
};

export default {
  author: 'Michael Lewis',
  category: 'book',
  date: '1/2/16',
  name: 'Flash Boys',
  path: '/notes/flash-boys',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-flash-boys-michael-lewis.jpg',
  tags: ['History', 'Finance'],
  time: 0,
  link: 'https://www.amazon.com/Flash-Boys-Wall-Street-Revolt/dp/0393351599',
  mediumLink: '',
  patreonLink: '',
  podcastLink: '',
  podcastEmbed: '',
  youtubeLink: '',
twosLink: '',
  youtubeEmbed: '',
  releaseDate: '',
  rating: 6.9,
  review:
    'Interesting read on stock trading and how so much money can be made in fractions of a second by being faster than a competitor.'
};

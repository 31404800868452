export default {
  category: 'book',
  name: 'Contagious',
  author: 'Jonah Berger',
  date: '6/26/21',
  path: '/notes/contagious',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-contagious-jonah-berger.jpg',
  tags: ['Marketing', 'Sales', 'Business'],
  time: 5,
  rating: 7.2,
  review: '',
  link: 'https://www.amazon.com/Contagious-Things-Catch-Jonah-Berger/dp/1451686587#:~:text=In%20Contagious%2C%20Berger%20reveals%20the,of%2Dmouth%20and%20social%20transmission.&text=Contagious%20provides%20specific%2C%20actionable%20techniques,content%20that%20people%20will%20share.',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
twosLink: 'https://www.TwosApp.com/60c40791c893802bf20fe70d',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: ''
};

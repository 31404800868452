export default {
  category: 'book',
  name: 'Write It Down, Make It Happen',
  author: 'Henriette Klauser',
  date: '3/18/23',
  path: '/notes/write-it-down-make-it-happen',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-write-it-down-make-it-happen-henriette-klauser.jpg',
  tags: ['Writing', 'Self-help'],
  time: 5,
  rating: 7.3,
  review: '',
  link: 'https://www.amazon.com/Write-Down-Make-Happen-Knowing/dp/0684850028',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  twosLink: 'https://www.TwosApp.com/@Parker/Write-It-Down%2C-Make-It-Happen-by-Henriette-Klauser',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};

export default {
  author: 'Aldous Huxley',
  category: 'book',
  date: '9/24/19',
  name: 'Brave New World',
  path: '/notes/brave-new-world',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-brave-new-world-aldous-huxley.jpg',
  tags: ['Fiction'],
  time: 6,
  link: 'https://www.amazon.com/Brave-New-World-Aldous-Huxley/dp/0060850523',
  mediumLink: '',
  patreonLink: '',
  podcastLink: 'https://anchor.fm/parkerklein/episodes/Brave-New-World-by-Aldous-Huxley-efaa3g',
  podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/Brave-New-World-by-Aldous-Huxley-efaa3g',
  youtubeLink: 'https://youtu.be/eKf11B-q8jM',
  youtubeEmbed: 'https://www.youtube.com/embed/eKf11B-q8jM',
  releaseDate: '6/29/20',
  rating: 7.3,
  review:
    "Very interesting book on culture and society. They solve everyone's problems, which keep things running smoothly, but take away art and excitement. Makes you question what world you would like to live in, what problems you are actually grateful for having, and how you'd like to spend your life."
};

export default {
  category: 'book',
  name: 'Breakthrough Advertising',
  author: 'Eugene Schwartz',
  date: '8/8/23',
  path: '/notes/breakthrough-advertising',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-breakthrough-advertising-eugene-schwartz.jpg',
  tags: ['Marketing', 'Sales', 'Business'],
  time: 47,
  rating: 8.8,
  review: '',
  link: 'https://www.amazon.com/Breakthrough-Advertising-Eugene-M-Schwartz/dp/0887232981',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  twosLink: 'https://www.twosapp.com/6470499ed1ce2a3edfe60a46',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};

export default {
  category: 'book',
  name: 'Perennial Seller',
  author: 'Ryan Holiday',
  date: '1/8/24',
  path: '/notes/perennial-seller',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-perennial-seller-ryan-holiday.jpg',
  tags: ['Marketing', 'Creativity', 'Writing'],
  time: 38,
  rating: 8.1,
  review: '',
  link: 'https://www.amazon.com/Perennial-Seller-Making-Marketing-Lasts/dp/0143109014',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  twosLink: 'https://www.twosapp.com/655c07c80566c4716873c3f5',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};

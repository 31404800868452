export default {
  category: 'book',
  name: 'Amusing Ourselves to Death',
  author: 'Neil Postman',
  date: '11/29/20',
  path: '/notes/amusing-ourselves-to-death',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-amusing-ourselves-to-death-neil-postman.jpg',
  tags: ['Engineering', 'Psychology'],
  time: 19,
  rating: 0,
  review: '',
  link: 'https://www.amazon.com/Amusing-Ourselves-Death-Discourse-Business/dp/014303653X',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
twosLink: 'https://www.TwosApp.com/5fb968bad3c41d09efa08ac3',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: ''
};

export default {
  author: 'Geoffrey A. Moore',
  category: 'book',
  date: '3/19/19',
  link: 'https://amzn.to/2Y1dthe',
  name: 'Crossing the Chasm',
  path: '/notes/crossing-the-chasm',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-crossing-the-chasm-geoffrey-moore.jpg',
  tags: ['Business', 'Management', 'Leadership'],
  time: 2,
  rating: 6.8,
  review:
    'A book about product adoption and how to break into markets. It is hard, but this book has some tactics and helps you understand who your audience is.'
};

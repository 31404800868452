export default {
  category: 'book',
  name: 'The iOS Interview Guide',
  author: 'Alex Bush',
  date: '1/21/20',
  path: '/notes/the-ios-interview-guide',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-ios-interview-guide-alex-bush.png',
  tags: ['Programming', 'iOS'],
  time: 8,
  rating: 6.2,
  review:
    "Introduction to a lot of iOS Programming concepts. I haven't had an iOS interview so I'm not sure how helpful it would be. Didn't seem to go too deep on the concepts.",
  link: 'https://iosinterviewguide.com/',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
twosLink: '',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: ''
};

export default {
  category: 'write',
  date: '9/30/19',
  name: 'I Think I Can',
  path: '/write/i-think-i-can',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-i-think-i-can.jpg',
  podcastLink: '',
  youtubeLink: '',
twosLink: '',
  mediumLink: 'https://parkerklein.medium.com/i-think-i-can-754ee2b3a3ff',
  patreonLink: '',
  tags: ['Write'],
  time: 1
};

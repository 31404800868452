export default {
  author: 'Robert Henri',
  category: 'book',
  date: '8/20/19',
  link: 'https://www.amazon.com/Art-Spirit-Robert-Henri/dp/0465002633',
  name: 'The Art Spirit',
  path: '/notes/the-art-spirit',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-art-spirit-robert-henri.jpg',
  tags: ['Education', 'Teaching', 'Art', 'Photography', 'Painting'],
  time: 62,
  rating: 5.1,
  review:
    'A collection of lectures from an artist and art teacher about your inner spirit while creating art and how good art comes about.'
};

export default {
  category: 'book',
  name: 'Trust Me I’m Lying',
  author: 'Ryan Holiday',
  date: '4/2/23',
  path: '/notes/trust-me-im-lying',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-trust-me-im-lying-ryan-holiday.jpg',
  tags: ['Marketing', 'Money', 'Business', 'Media', 'Communication'],
  time: 7,
  rating: 7.8,
  review: '',
  link: 'https://www.amazon.com/Trust-Me-Lying-Confessions-Manipulator/dp/1591846285',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  twosLink: 'https://www.twosapp.com/641657273b8d2d2ee471451e',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};

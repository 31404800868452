export default {
  author: 'Aaron Hillegass',
  category: 'book',
  date: '10/22/19',
  name: 'Objective-C Programming',
  path: '/notes/objective-c-programming',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-objective-c-programming-aaron-hillegass.jpg',
  tags: ['Programming'],
  time: 10,
  link: 'https://www.amazon.com/Objective-C-Programming-Ranch-Guide-Guides/dp/0321706285',
  mediumLink: '',
  patreonLink: '',
  podcastLink: '',
  podcastEmbed: '',
  youtubeLink: '',
twosLink: '',
  youtubeEmbed: '',
  releaseDate: '',
  rating: 6.9,
  review:
    'Quality introduction and examples to learn the basics of Objective-C. Great introduction to object-oriented programming.'
};

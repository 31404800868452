export default {
  author: 'Fred Shoemaker',
  category: 'book',
  date: '6/16/18',
  link: 'https://amzn.to/2jN13Hy',
  name: 'Extraordinary Golf',
  path: '/notes/extraordinary-golf',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-extraordinary-golf-fred-shoemaker.jpg',
  podcastLink:
    'https://anchor.fm/parkerklein/episodes/Extraordinary-Golf-by-Fred-Shoemaker--Parker-Kleins-Notes--Book-Notes-e4lvl3',
  podcastEmbed:
    'https://anchor.fm/parkerklein/embed/episodes/Extraordinary-Golf-by-Fred-Shoemaker--Parker-Kleins-Notes--Book-Notes-e4lvl3',
  tags: ['Sports', 'Psychology', 'Golf'],
  time: 6,
  youtubeLink: 'https://youtu.be/8RwgUZElfUg',
  youtubeEmbed: 'https://www.youtube.com/embed/8RwgUZElfUg',
  releaseDate: '7/19/19',
  rating: 8.2,
  review:
    "Read this if you golf a lot, read this if you don't golf a lot. It applies to life, too. It's about having fun, working on those things under your control, and being present. Help yourself by getting out of the way."
};

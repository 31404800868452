export default {
  author: 'Amanda Palmer',
  category: 'book',
  date: '10/24/19',
  name: 'The Art of Asking',
  path: '/notes/the-art-of-asking',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-art-of-asking-amanda-palmer.jpg',
  tags: ['Biography', 'Art', 'Marketing'],
  time: 3,
  link: 'https://www.amazon.com/Art-Asking-Learned-Worrying-People/dp/1455581097',
  mediumLink: '',
  patreonLink: '',
  podcastLink: '',
  podcastEmbed: '',
  youtubeLink: '',
twosLink: '',
  youtubeEmbed: '',
  releaseDate: '',
  rating: 4.3,
  review:
    'Book about being a creator and not being afraid to ask for help so we can continue creating. Just do your best, share yourself with the world, and never stop creating.'
};

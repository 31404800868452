export default {
  category: 'write',
  date: '11/18/19',
  name: 'Pain and Pleasure',
  path: '/write/pain-and-pleasure',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-pain-and-pleasure.jpg',
  podcastLink: '',
  youtubeLink: '',
twosLink: '',
  mediumLink: '',
  patreonLink: '',
  tags: ['Write'],
  time: 1
};

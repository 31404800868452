export default {
  category: 'write',
  date: '1/26/20',
  name: 'See what you want to see',
  path: '/write/see-what-you-want-to-see',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-see-what-you-want-to-see.jpg',
  podcastLink: '',
  youtubeLink: '',
twosLink: '',
  mediumLink: '',
  patreonLink: '',
  tags: ['Write'],
  time: 1
};

export default {
  category: 'write',
  date: '8/7/19',
  name: '10 Reasons To Use Müse',
  path: '/write/10-reasons-to-use-muse',
  photo: 'https://twos.s3.amazonaws.com/muse1.png',
  podcastLink: '',
  youtubeLink: '',
twosLink: '',
  mediumLink: '',
  patreonLink: '',
  tags: ['Write'],
  time: 1
};

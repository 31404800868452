export default {
  category: 'book',
  name: 'The Stoic Challenege',
  author: 'William B Irvine',
  date: '1/3/21',
  path: '/notes/the-stoic-challenge',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-stoic-challenge-william-b-irvine.jpg',
  tags: ['Stoicism', 'Psychology', 'Philosophy'],
  time: 7,
  rating: 7.2,
  review: '',
  link: 'https://www.amazon.com/Stoic-Challenge-Philosophers-Becoming-Resilient/dp/0393652491',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
twosLink: 'https://www.TwosApp.com/5fe6a6b5b7f55326cf56a1c2',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: ''
};

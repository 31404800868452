export default {
  category: 'book',
  name: 'Emotional Intelligence',
  author: 'Daniel Goleman',
  date: '2/29/20',
  path: '/notes/emotional-intelligence',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-emotional-intelligence-daniel-goleman.jpg',
  tags: ['Self-help', 'Health'],
  time: 4,
  rating: 7.2,
  review:
    'Great book on becoming aware of your emotions and how you respond to them. Talks about how we develop tendencies and how we can learn about them to make better decisions in the future. Emotional intelligence is everything.',
  link: 'https://www.amazon.com/Emotional-Intelligence-Matter-More-Than/dp/055338371X',
  mediumLink: 'https://parkerklein.medium.com/emotional-intelligence-by-daniel-goleman-1df16adba021',
  patreonLink: '',
  podcastLink:
    'https://anchor.fm/parkerklein/episodes/Emotional-Intelligence-by-Daniel-Goleman-ecvrge',
  podcastEmbed:
    'https://anchor.fm/parkerklein/embed/episodes/Emotional-Intelligence-by-Daniel-Goleman-ecvrge',
    twosLink: 'https://www.twosapp.com/5e41743d51b788ad866a3399',
  youtubeLink: 'https://youtu.be/Sh8Tun6OeDE',
  youtubeEmbed: 'https://www.youtube.com/embed/Sh8Tun6OeDE',
  releaseDate: '5/4/20'
};

export default {
  author: 'Gayle Laakmann McDowell',
  category: 'book',
  date: '4/27/16',
  link: 'https://www.amazon.com/Cracking-Coding-Interview-Programming-Questions/dp/0984782850',
  name: 'Cracking the Coding Interview',
  path: '/notes/cracking-the-coding-interview',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-cracking-the-coding-interview-gayle-laakmann-mcdowell.jpg',
  tags: ['Software Development', 'Interview', 'Programming'],
  time: 6,
  rating: 7.2,
  review:
    "Great resource when preparing for job interviews. I've read and gone through the practice problems at least 3 times now. Breaks down the concepts you'll need in an interview and show you how to solve a variety of programming problems in different ways."
};

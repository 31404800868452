export default {
  author: 'Nir Eyal',
  category: 'book',
  date: '8/22/19',
  link: 'https://www.amazon.com/Hooked-How-Build-Habit-Forming-Products-ebook/dp/B00LMGLXTS',
  name: 'Hooked',
  path: '/notes/hooked',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-hooked-nir-eyal.jpg',
  tags: ['Engineering', 'Design'],
  time: 17,
  rating: 7.1,
  review:
    'A book for creators to understand how their products can become habits in peoples lives and for consumers to understand how they can protect themselves from unwanted habits.'
};

export default {
  category: 'book',
  name: 'See Do Repeat',
  author: 'Dr. Rebecca White',
  date: '6/27/22',
  path: '/notes/see-do-repeat',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-see-do-repeat-dr-rebecca-white.jpg',
  tags: ['Business', 'Money', 'Entrepreneurship'],
  time: 5,
  rating: 6.6,
  review: '',
  link: 'https://www.amazon.com/See-Do-Repeat-Practice-Entrepreneurship/dp/1736938851',
  mediumLink: 'https://parkerklein.medium.com/see-do-repeat-by-dr-rebecca-white-1b4ffc235058',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
twosLink: 'https://www.TwosApp.com/62b5013edea03606ff348a83',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};

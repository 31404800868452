export default {
  category: 'book',
  name: 'Expert Secrets',
  author: 'Russell Brunson',
  date: '3/15/20',
  path: '/notes/expert-secrets',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-expert-secrets-russell-brunson.jpg',
  tags: ['Money', 'Business', 'Sales', 'Marketing'],
  time: 5,
  rating: 5.9,
  review:
    "Starts out with a great message on utilizing what you've learned by teaching others and creating a movement. Ends with how to create a slide deck and click funnel to capitalize on other people's attention.",
  link: 'https://www.amazon.com/Expert-Secrets-Underground-Playbook-Creating/dp/1683504585',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
twosLink: '',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: ''
};

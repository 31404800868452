export default {
  author: 'Kazuo Ishiguro',
  category: 'book',
  date: '3/4/18',
  link: 'https://amzn.to/2k2hMXu',
  name: 'The Remains of the Day',
  path: '/notes/the-remains-of-the-day',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-remains-of-the-day-kazuo-ishiguro.jpg',
  podcastLink:
    'https://anchor.fm/parkerklein/episodes/The-Remains-of-the-Day-by-Kazuo-Ishiguro--Parker-Kleins-Notes--Book-Notes-e4og4t',
  podcastEmbed:
    'https://anchor.fm/parkerklein/embed/episodes/The-Remains-of-the-Day-by-Kazuo-Ishiguro--Parker-Kleins-Notes--Book-Notes-e4og4t',
  tags: ['Fiction'],
  time: 2,
  youtubeLink: 'https://youtu.be/At0NM3CUGhM',
  youtubeEmbed: 'https://www.youtube.com/embed/At0NM3CUGhM',
  releaseDate: '7/26/19',
  rating: 7.9,
  review:
    "A book about owning your craft and pleasing your customer. Very well written. Don't forget to enjoy your life, too."
};

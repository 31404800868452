export default {
  category: 'book',
  name: 'Attached',
  author: 'Amir Levine',
  date: '6/16/24',
  path: '/notes/attached',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-attached-amir-levine.jpg',
  tags: ['Self-help', 'Relationships'],
  time: 40,
  rating: 8.8,
  review: '',
  link: 'https://www.amazon.com/Attached-Science-Adult-Attachment-YouFind/dp/1585429139',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  twosLink: 'https://www.twosapp.com/66414fc2696b95780c091823',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};

export default {
  category: 'write',
  date: '11/16/19',
  name: 'What is Meaningful?',
  path: '/write/what-is-meaningful',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-what-is-meaningful.jpg',
  podcastLink: '',
  youtubeLink: '',
twosLink: '',
  mediumLink: '',
  patreonLink: '',
  tags: ['Write'],
  time: 1
};

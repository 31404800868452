export default {
  category: 'write',
  date: '8/6/17',
  name: 'Anti-laissez-faire',
  path: '/write/anti-laissez-faire',
  photo: 'https://twos.s3.amazonaws.com/parker-klein-anti-laissez-faire.png',
  podcastLink: '',
  youtubeLink: '',
twosLink: '',
  mediumLink: '',
  patreonLink: '',
  tags: ['Write'],
  time: 1
};

import React from 'react';
import { Link } from 'react-router-dom';

import Helmet from '../components/Helmet';

const NowPage = () => (
  <main className="main-content">
    <Helmet
      description="Parker Klein is living in Tampa, Florida and working on Twos Technologies, which includes Twos: a new way to remember things, Müse: daily reflection journal, Tens: Nerf basketball stats tracker, and Aware: daily activity tracker. Parker is also reading and taking notes."
      title="Now"
    />
    {/* <h2 className="title">Now</h2> */}
    <p className="date">Last updated: 9/25/21</p>
    <p className="note">
      I am building{' '}<Link aria-label="Twos" className="note-link" id="Twos" title="Twos" to="/apps/twos">
        Twos: remember *things*
      </Link>{' '}with co-founder Joseph Steilberg in Tampa, FL.
    </p>
    <p className="note">
      I am reading and taking{' '}
      <Link
        aria-label="Parker Klein's notes"
        className="note-link"
        id="Notes"
        title="Parker Klein's notes"
        to="/notes"
      >
        notes
      </Link>{' '}
      on{' '}
      <a
        aria-label="How Not to Die"
        className="note-link"
        href="https://www.amazon.com/How-Not-Die-Discover-Scientifically/dp/1250066115"
        id="HowNotToDie"
        rel="noopener noreferrer"
        target="_blank"
        title="How Not to Die by Michael Greger"
      >
        How Not to Die
      </a>{' '}
      by Michael Greger,{' '}
      <a
        aria-label="Made to Stick"
        className="note-link"
        href="https://www.amazon.com/Made-Stick-Ideas-Survive-Others/dp/1400064287"
        id="MadeToStick"
        rel="noopener noreferrer"
        target="_blank"
        title="Made to Stick by Chip Heath"
      >
        Made to Stick
      </a>{' '}
      by Chip Heath, and{' '}
      <a
        aria-label="The Daily Stoic by Ryan Holiday"
        className="note-link"
        href="https://www.amazon.com/Daily-Stoic-Meditations-Wisdom-Perseverance/dp/0735211736"
        id="TheDailyStoic"
        rel="noopener noreferrer"
        target="_blank"
        title="The Daily Stoic by Ryan Holiday"
      >
        The Daily Stoic
      </a>{' '}
      by Ryan Holiday.
    </p>
    <p className="note">
      Page inspired by{' '}
      <a
        aria-label="Derek Sivers' Website"
        className="note-link"
        href="https://sivers.org/"
        id="DerekSivers"
        rel="noopener noreferrer"
        target="_blank"
        title="Derek Sivers' Website"
      >
        Derek Sivers'
      </a>{' '}
      <a
        aria-label="NowNowNow"
        className="note-link"
        href="https://nownownow.com/"
        id="NowNowNow"
        rel="noopener noreferrer"
        target="_blank"
        title="NowNowNow"
      >
        now page
      </a>.
    </p>
  </main>
);

export default NowPage;

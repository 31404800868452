export default {
  category: 'book',
  name: 'Becoming Wise',
  author: 'Krista Tippett',
  date: '12/17/20',
  path: '/notes/becoming-wise',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-becoming-wise-krista-tippett.jpg',
  tags: ['Self-help', 'Catholicism'],
  time: 16,
  rating: 7.8,
  review: '',
  link: 'https://www.amazon.com/Becoming-Wise-Inquiry-Mystery-Living/dp/1101980311',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
twosLink: 'https://www.TwosApp.com/5fb0b3159029a806de26aa38',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: ''
};

export default {
  category: 'book',
  name: 'Love Yourself Like Your Life Depends On It',
  author: 'Kamal Ravikant',
  date: '5/28/20',
  path: '/notes/love-yourself-like-your-life-depends-on-it',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-love-yourself-like-your-life-depends-on-it-kamal-ravikant.jpg',
  tags: ['Self-help'],
  time: 2,
  rating: 7.8,
  review: 'Love yourself. Create the habit of loving yourself. It brings love into the world.',
  link: 'https://www.amazon.com/Love-Yourself-Like-Your-Depends/dp/0062968726/ref=dp_ob_title_bk',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
twosLink: '',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: ''
};

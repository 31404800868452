import React from 'react';

import Helmet from '../components/Helmet';
import NewsForm from '../components/NewsForm';

const HomePage = () => (
  <main className="main-content">
    <Helmet
      description="Sign up for Parker Klein's latest updates. Read Parker's notes, watch his videos, listen to his podcast, hear his thoughts, and ask him questions"
      title="Updates"
    />
    <h2 className="title">Updates</h2>
    <p className="note">Hi Human,</p>
    <NewsForm />
    <p className="note">Thank you,</p>
    <p className="note">Parker</p>
  </main>
);

export default HomePage;

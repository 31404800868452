export default {
  author: 'Matthew Kelly',
  category: 'book',
  date: '3/17/17',
  link: 'https://amzn.to/2lxD7IP',
  name: 'Resisting Happiness',
  path: '/notes/resisting-happiness',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-resisting-happiness-matthew-kelly.jpg',
  tags: ['Self-help', 'Religion', 'Christianity'],
  time: 3,
  rating: 7.1,
  review:
    'Why do we not do the things we know we should do? Why do we not do the things we know are best for us? The things that will allow us to become our best selves? This book is about facing those decisions and fears of what if and living our best life.'
};

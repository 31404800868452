import React, { Component, Fragment } from 'react';
import { FiArrowUp } from 'react-icons/fi';

import Breadcrumb from '../components/Breadcrumb';
import Helmet from '../components/Helmet';
import SideMenu from '../components/SideMenu';
import routes from '../routes';

class Pages extends Component {
  constructor(props) {
    super(props);

    this.state = {
      atTop: true
    };

    this.handleScroll = this.handleScroll.bind(this);
    this.handleScrollToTop = this.handleScrollToTop.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    const { atTop } = this.state;
    if (window.scrollY > 0 && atTop) {
      this.setState({ atTop: false });
      return;
    }
    if (window.scrollY <= 0 && !atTop) {
      this.setState({ atTop: true });
    }
  }

  handleScrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  render() {
    const { atTop } = this.state;

    return (
      <Fragment>
        <Helmet
          description="Parker Klein is the creator of Twos, Müse, Aware, 7 Levels Deep, and the Parker Klein's Notes YouTube channel and podcast. Parker Klein is a software developer, note taker, podcaster, youtuber, and writer."
          title="Personal Website"
        />
        <SideMenu />
        <Breadcrumb />
        <div id="content">{routes}</div>
        {!atTop && (
          <button
            aria-label="Scroll to the top"
            className="top-button"
            data-tip="Scroll to the top"
            onClick={this.handleScrollToTop}
            title="Scroll to the top"
          >
            <FiArrowUp />
          </button>
        )}
      </Fragment>
    );
  }
}

export default Pages;

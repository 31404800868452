export default {
  category: 'book',
  name: 'Tiny Habits',
  author: 'BJ Fogg',
  date: '1/24/22',
  path: '/notes/tiny-habits',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-tiny-habits-bj-fogg.jpg',
  tags: ['Self-help', 'Productivity', 'Psychology'],
  time: 3,
  rating: 8.2,
  review: '',
  link: 'https://www.amazon.com/Tiny-Habits-Changes-Change-Everything/dp/0358003326',
  mediumLink: 'https://parkerklein.medium.com/tiny-habits-by-bj-fogg-f814a96bf875',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
twosLink: 'https://www.twosapp.com/61bd2b045ac39697e307f3d6',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};

export default {
  author: 'Randy Pausch',
  category: 'book',
  date: '12/20/15',
  name: 'The Last Lecture',
  path: '/notes/the-last-lecture',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-last-lecture-randy-pausch.jpg',
  tags: ['Biography'],
  time: 0,
  link: 'https://www.amazon.com/Last-Lecture-Randy-Pausch/dp/1401323251',
  mediumLink: '',
  patreonLink: '',
  podcastLink: '',
  podcastEmbed: '',
  youtubeLink: '',
twosLink: '',
  youtubeEmbed: '',
  releaseDate: '',
  rating: 6.6,
  review:
    'A book about the last lecture a professor gave about life and how to live it up. Based on a video of the lecture, I believe.'
};

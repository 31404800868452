export default {
  author: 'Don Norman',
  category: 'book',
  date: '9/11/17',
  link: 'https://amzn.to/2ly8Zgr',
  name: 'The Design of Everyday Things',
  path: '/notes/the-design-of-everyday-things',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-design-of-everyday-things-don-norman.jpg',
  tags: ['Engineering', 'Product Design', 'Design', 'Web Design'],
  time: 17,
  rating: 7.1,
  review:
    'Discussion around why things are designed the way they are. Focusing on effective, functional, and enjoyable design.'
};

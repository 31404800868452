// src/reducers/initialState.js

export default {
  body: '',
  subject: '',
  email: '',
  emailSent: false,
  error: '',
  filters: {
    search: '',
    sort: 'date',
    tag: ''
  },
  loading: false,
  note: '',
  sideMenu: false,
  signedUp: false,
  wrote: ''
};

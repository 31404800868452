export default {
  author: 'Stephen Greenblatt',
  category: 'book',
  date: '12/30/18',
  link: 'https://amzn.to/2lA1Jk9',
  name: 'The Swerve',
  path: '/notes/the-swerve',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-swerve-stephen-greenblatt.jpg',
  tags: ['History', 'Philosophy'],
  time: 10,
  rating: 4.3,
  review: 'A book about books and the people who tracked down history and knowledge.'
};

export default {
  category: 'book',
  name: 'Memoirs of a Geisha',
  author: 'Arthur Golden',
  date: '8/28/20',
  path: '/notes/memoirs-of-a-geisha',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-memoirs-of-a-geisha-arthur-golden.jpg',
  tags: ['Fiction'],
  time: 1,
  rating: 6.8,
  review: '',
  link: 'https://www.amazon.com/Memoirs-Geisha-Novel-Arthur-Golden/dp/0679781587',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
twosLink: '',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: ''
};

export default {
  category: 'book',
  name: 'Talk Like TED',
  author: 'Carmine Gallo',
  date: '11/14/22',
  path: '/notes/talk-like-ted',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-talk-like-ted-carmine-gallo.jpg',
  tags: ['Business', 'Money', 'Public Speaking', 'Leadership'],
  time: 30,
  rating: 8.2,
  review: '',
  link: 'https://www.amazon.com/Talk-Like-TED-Public-Speaking-Secrets/dp/1250041120',
  mediumLink: 'https://medium.com/twosapp/20-lessons-from-talk-like-ted-by-carmine-gallo-9e2e8925ad55',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  twosLink: 'https://www.twosapp.com/6356782eadd5a6ccd37b9e5f',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};

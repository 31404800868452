export default {
  author: 'Bill Walsh',
  category: 'book',
  date: '1/14/18',
  link: 'https://amzn.to/2k2lwIw',
  mediumLink:
    'https://parkerklein.medium.com/the-score-takes-care-of-itself-by-bill-walsh-parker-kleins-notes-38ddecaa01c4',
  name: 'The Score Takes Care of Itself',
  path: '/notes/the-score-takes-care-of-itself',
  patreonLink: 'https://www.patreon.com/posts/29566968',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-score-takes-care-of-itself-bill-walsh.jpg',
  podcastLink:
    'https://anchor.fm/parkerklein/episodes/The-Score-Takes-Care-Of-Itself-by-Bill-Walsh--Parker-Kleins-Notes-e55m97',
  podcastEmbed:
    'https://anchor.fm/parkerklein/embed/episodes/The-Score-Takes-Care-Of-Itself-by-Bill-Walsh--Parker-Kleins-Notes-e55m97',
  tags: ['Business', 'Management', 'Leadership', 'Sports'],
  time: 3,
  youtubeLink: 'https://youtu.be/qxe1YQfBq4M',
  youtubeEmbed: 'https://www.youtube.com/embed/qxe1YQfBq4M',
  releaseDate: '8/30/19',
  rating: 8.1,
  review:
    "Focus on what you have control over and don't worry about the result. Just do your best. Book about Bill Walsh and the teams he lead to victory."
};

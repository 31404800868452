export default {
  category: 'book',
  name: 'Getting to Yes',
  author: 'Roger Fisher',
  date: '12/1/21',
  path: '/notes/getting-to-yes',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-getting-to-yes-roger-fisher.jpg',
  tags: ['Self-help', 'Relationships', 'Negotiation'],
  time: 2,
  rating: 5.5,
  review: '',
  link: 'https://www.amazon.com/Getting-Yes-Negotiating-Agreement-Without/dp/0143118757/ref=tmm_pap_swatch_0?_encoding=UTF8&qid=1638501484&sr=8-1',
  mediumLink: 'https://medium.com/twosapp/getting-to-yes-by-roger-fisher-d6f448db4ea1',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
twosLink: 'https://www.TwosApp.com/619d1a26fa32417878e21d02',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: ''
};

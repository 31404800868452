export default {
  author: 'Harper Lee',
  category: 'book',
  date: '12/15/18',
  name: 'To Kill a Mocking Bird',
  path: '/notes/to-kill-a-mocking-bird',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-to-kill-a-mocking-bird-harper-lee.jpg',
  tags: ['Fiction'],
  time: 0,
  link: '',
  mediumLink: '',
  patreonLink: '',
  podcastLink: '',
  podcastEmbed: '',
  youtubeLink: '',
twosLink: '',
  youtubeEmbed: '',
  releaseDate: '',
  rating: 8.7,
  review:
    'A great story about being honest and true to yourself. A story about what is wrong with our world and how each of us can stand up and do our part to make things right.'
};

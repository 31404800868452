export default {
  author: 'Tasha Eurich',
  category: 'book',
  date: '6/19/17',
  link: 'https://amzn.to/2lcQFJn',
  name: 'Insight',
  path: '/notes/insight',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-insight-tasha-eurich.jpg',
  tags: ['Psychology', 'Self-help'],
  time: 19,
  rating: 7.5,
  review:
    'Fascinating research behind self-awareness and what it means to know yourself. Helpful knowledge and skills for anyone who wants to improve their relationships, self-confidence, and ability to communicate effectively.'
};

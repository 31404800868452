export default {
  category: 'book',
  name: 'The Wisdom of Life',
  author: 'Arthur Schopenhauer',
  date: '6/29/21',
  path: '/notes/the-wisdom-of-life',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-wisdom-of-life-arthur-schopenhauer.jpg',
  tags: ['Philosophy'],
  time: 7,
  rating: 7.5,
  review: '',
  link: 'https://www.amazon.com/Wisdom-Life-Arthur-Schopenhauer/dp/0486435504',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
twosLink: 'https://www.TwosApp.com/60d72c350fd1dadc833faf07',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: ''
};

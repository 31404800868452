export default {
  category: 'book',
  name: 'Pour Your Heart into It',
  author: 'Howard Schultz',
  date: '9/23/23',
  path: '/notes/pour-your-heart-into-it',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-pour-your-heart-into-it-howard-schultz.jpg',
  tags: ['Biography', 'Leadership', 'Business', 'Marketing'],
  time: 13,
  rating: 7.2,
  review: '',
  link: 'https://www.amazon.com/Pour-Your-Heart-Into-Starbucks/dp/0786883561',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  twosLink: 'https://www.twosapp.com/64e9dfce1fb36c6136f79f40',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};

export default {
  author: 'Angela Duckworth',
  category: 'book',
  date: '1/27/17',
  link: 'https://amzn.to/2Y0jiLP',
  name: 'Grit',
  path: '/notes/grit',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-grit-angela-duckworth.jpg',
  tags: ['Psychology'],
  time: 3,
  mediumLink: 'https://medium.com/twosapp/grit-by-angela-duckworth-4f86537e744d',
  patreonLink: '',
  podcastLink: 'https://anchor.fm/parkerklein/episodes/Grit-by-Angela-Duckworth-e5m9ft',
  podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/Grit-by-Angela-Duckworth-e5m9ft',
  youtubeLink: 'https://youtu.be/XIDFxjdTndM',
  youtubeEmbed: 'https://www.youtube.com/embed/XIDFxjdTndM',
  twosLink: 'https://www.TwosApp.com/588b7e1152fd520400e34cc2',
  releaseDate: '9/30/19',
  rating: 7.3,
  review:
    "A book about our capability to learn and grow. You aren't static. You can learn from every failure and become stronger. Stick with it and improve."
};

export default {
  category: 'write',
  date: '10/4/19',
  name: 'The Best California Burrito in San Diego',
  path: '/write/the-best-california-burrito-in-san-diego',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-kotija-jr-california-burrito.jpg',
  podcastLink: '',
  youtubeLink: '',
twosLink: '',
  mediumLink: 'https://parkerklein.medium.com/san-diegos-best-california-burrito-31e48cb4e6d6',
  patreonLink: '',
  tags: ['Write'],
  time: 10
};

export default {
  author: 'Sebastian Junger',
  category: 'book',
  date: '10/30/20',
  name: 'Tribe',
  path: '/notes/tribe',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-tribe-sebastian-junger.jpg',
  tags: ['History', 'War'],
  time: 3,
  link: 'https://www.amazon.com/Tribe-Homecoming-Belonging-Sebastian-Junger/dp/1455566381',
  mediumLink: '',
  patreonLink: '',
  podcastLink: '',
  podcastEmbed: '',
  youtubeLink: '',
twosLink: '',
  youtubeEmbed: '',
  releaseDate: '',
  rating: 7.5,
  review: "Why we need tribes and how modern society moving further away from them"
};

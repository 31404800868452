export default {
  category: 'write',
  date: '7/13/20',
  name: 'Birthday Feels',
  path: '/write/birthday-feels',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-birthday-feels.jpg',
  podcastLink: '',
  youtubeLink: '',
twosLink: '',
  mediumLink: '',
  patreonLink: '',
  tags: ['Write'],
  time: 2
};

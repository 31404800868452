export default {
  category: 'write',
  date: '9/25/21',
  name: 'Top 10 Benefits of Writing Things Down',
  path: '/write/top-10-benefits-of-writing-things-down',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-top-10-benefits-of-writing-things-down.jpg',
  podcastLink: '',
  youtubeLink: 'https://www.youtube.com/watch?v=i1xGh0vsLCs',
  mediumLink: 'https://parkerklein.medium.com/top-10-benefits-of-writing-things-down-e297caf5b5e0',
  patreonLink: '',
  tags: ['Write'],
  time: 1
};

export default {
  category: 'book',
  name: 'Building a Second Brain',
  author: 'Tiago Forte',
  date: '8/10/22',
  path: '/notes/building-a-second-brain',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-building-a-second-brain-tiago-forte.jpg',
  tags: ['Productivity', 'Management', 'Leadership'],
  time: 26,
  rating: 7.9,
  review: '',
  link: 'https://www.amazon.com/Building-Second-Brain-Organize-Potential/dp/1982167386',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  twosLink: 'https://www.TwosApp.com/62da7354aa24313765650082',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};

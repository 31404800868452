export default {
  category: 'book',
  name: 'The 10X Rule',
  author: 'Grant Cardone',
  date: '8/26/23',
  path: '/notes/the-10x-rule',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-10x-rule-grant-cardone.jpg',
  tags: ['Business', 'Money', 'Sales', 'Management', 'Leadership', 'Self-help'],
  time: 24,
  rating: 7.8,
  review: '',
  link: 'https://www.amazon.com/10X-Rule-Difference-Between-Success/dp/0470627603',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  twosLink: 'https://www.twosapp.com/64d83b34fa96388ffce63587',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};

export default {
  category: 'book',
  name: 'Greenlights',
  author: 'Matthew McConaughey',
  date: '5/4/21',
  path: '/notes/greenlights',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-greenlights-matthew-mcconaughey.jpg',
  tags: ['Religion', 'Spirituality', 'Philosophy', 'Autobiography'],
  time: 4,
  rating: 6.7,
  review: '',
  link: 'https://www.amazon.com/Greenlights-Matthew-McConaughey/dp/0593139135',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
twosLink: 'https://www.TwosApp.com/60844fd542067940f5b1dd51',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: ''
};

export default {
  category: 'apps',
  startDate: '12/27/16',
  endDate: '3/8/17',
  name: 'Tens',
  tagLine: 'Go Ten for Ten',
  description: 'A stat tracker for a nerf basketball game called Tens.',
  screenshots: [
    'https://twos.s3.amazonaws.com/parker-klein-tens-twos-technologies-screenshot-1.jpg',
    'https://twos.s3.amazonaws.com/parker-klein-tens-twos-technologies-screenshot-2.jpg',
    'https://twos.s3.amazonaws.com/parker-klein-tens-twos-technologies-screenshot-3.jpg',
    'https://twos.s3.amazonaws.com/parker-klein-tens-twos-technologies-screenshot-4.jpg',
    'https://twos.s3.amazonaws.com/parker-klein-tens-twos-technologies-screenshot-5.jpg',
    'https://twos.s3.amazonaws.com/parker-klein-tens-twos-technologies-screenshot-6.jpg',
    'https://twos.s3.amazonaws.com/parker-klein-tens-twos-technologies-screenshot-7.jpg',
    'https://twos.s3.amazonaws.com/parker-klein-tens-twos-technologies-screenshot-8.jpg',
    'https://twos.s3.amazonaws.com/parker-klein-tens-twos-technologies-screenshot-9.jpg',
    'https://twos.s3.amazonaws.com/parker-klein-tens-twos-technologies-screenshot-10.jpg'
  ],
  iOSUrl: 'https://apps.apple.com/cz/app/tens-10-for-10/id1213386656',
  logo: 'https://twos.s3.amazonaws.com/tens-logo.png',
  url: 'https://tens.herokuapp.com/',
  path: '/apps/tens',
  tags: ['Apps'],
  tech: [
    'React',
    'React Native',
    'HTML',
    'CSS',
    'Express.js',
    'Node.js',
    'MongoDB',
    'Heroku',
    'Redux'
  ],
  future:
    'Specify arena, type of game, and create a package to sell with branded nerf hoop and ball.'
};

export default {
  category: 'write',
  date: '12/26/19',
  name: 'Resolution',
  path: '/write/resolution',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-resolution.png',
  podcastLink: '',
  youtubeLink: '',
twosLink: '',
  mediumLink: '',
  patreonLink: '',
  tags: ['Write'],
  time: 1
};

export default {
  author: 'Mihaly Csikszentmihalyi',
  category: 'book',
  date: '4/7/15',
  link: 'https://amzn.to/2lfcMz1',
  name: 'Flow',
  path: '/notes/flow',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-flow-mihaly-csikszentmihalyi.jpg',
  tags: ['Psychology'],
  time: 65,
  rating: 7.8,
  review: 'Flow is a gift for creative people. It makes our working lives more enjoyable. It helps us learn and accomplish more. Read this book to figure out how to get into flow and help yourself by prepping the environment.',
};

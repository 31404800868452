export default {
  author: 'Charles Koch',
  category: 'book',
  date: '2/25/19',
  link: 'https://amzn.to/2SibT55',
  name: 'Good Profit',
  path: '/notes/good-profit',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-good-profit-charles-koch.jpg',
  tags: ['Business', 'Biography', 'Leadership'],
  time: 13,
  rating: 8.1,
  review:
    "Wonderful book about Kock Industries. A very impressive story about a hardworking family constantly looking for the next thing and executing. Great company to work for as they pay based on the value you add. It's only fair."
};

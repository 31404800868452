export default {
  category: 'book',
  name: 'The Science of Hitting',
  author: 'Ted Williams',
  date: '3/9/20',
  path: '/notes/the-science-of-hitting',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-science-of-hitting-ted-williams.jpg',
  tags: ['Sports'],
  time: 3,
  rating: 6.7,
  review:
    'Ted Williams talks about being a great hitter. It takes dedication, constant practice, always on and learning. He says it is 50% mental.',
  link: 'https://www.amazon.com/Science-Hitting-Ted-Williams/dp/0671621033',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
twosLink: '',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: ''
};

export default {
  author: 'Shunryu Suzuki',
  category: 'book',
  date: '5/14/19',
  link: 'https://amzn.to/2ltLxkh',
  name: "Zen Mind, Beginner's Mind",
  path: '/notes/zen-mind-beginners-mind',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-zen-mind-beginners-mind-shunryu-suzuki.jpg',
  tags: ['Religion', 'Spirituality', 'Buddhism', 'Philosophy'],
  time: 46,
  rating: 9.1,
  review:
    'A book on Buddhism. Learn to go with the flow, act effortlessly, experience natural joy, and live your truth.'
};

export default {
  author: 'Andrew Grove',
  category: 'book',
  date: '10/13/18',
  link: 'https://amzn.to/2jLkwZ2',
  name: 'High Output Management',
  path: '/notes/high-output-management',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-high-output-mannagement-andrew-grove.jpg',
  tags: ['Management', 'Business', 'Leadership'],
  time: 16,
  rating: 6.5,
  review:
    'A book about how to manage, empower, motivate, and make decisions. A must read for managers who want to be perform well for their company and employees.'
};

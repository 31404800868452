export default {
  author: 'Andy Weir',
  category: 'book',
  date: '6/10/15',
  name: 'The Martian',
  path: '/notes/the-martian',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-martian-andy-weir.jpg',
  tags: ['Fiction', 'Science Fiction'],
  time: 0,
  link: 'https://www.amazon.com/Martian-Andy-Weir/dp/0553418025',
  mediumLink: '',
  patreonLink: '',
  podcastLink: '',
  podcastEmbed: '',
  youtubeLink: '',
twosLink: '',
  youtubeEmbed: '',
  releaseDate: '',
  rating: 7.3,
  review:
    'Great novel about astronauts going to Mars and the near life ending problems they encounter once they get there. Thrilling, informational, enjoyable.'
};

export default {
  author: 'Phil Knight',
  category: 'book',
  date: '11/1/17',
  name: 'Shoe Dog',
  path: '/notes/shoe-dog',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-shoe-dog-phil-knight.jpg',
  tags: ['Biography', 'Business', 'Sports', 'Entrepreneurship'],
  time: 0,
  link:
    'https://www.amazon.com/Shoe-Dog-Memoir-Creator-Nike/dp/1501135910/ref=tmm_hrd_swatch_0?_encoding=UTF8&qid=&sr=',
  mediumLink: '',
  patreonLink: '',
  podcastLink: '',
  podcastEmbed: '',
  youtubeLink: '',
twosLink: '',
  youtubeEmbed: '',
  releaseDate: '',
  rating: 8.2,
  review:
    'The story of Phil Knight and Nike. Dude worked incredibly hard and put in all on the line. Believed in the products he was creating and wanted them to be the best they could be. Truly an incredible story.'
};

export default {
  author: 'Jason Fried',
  category: 'book',
  date: '5/11/17',
  link: 'https://amzn.to/2jZ4M4U',
  name: 'Rework',
  path: '/notes/rework',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-rework-jason-fried.jpg',
  tags: ['Business', 'Entrepreneurship'],
  time: 6,
  rating: 7.3,
  review:
    "A book about executing. About taking action. About not following common ideas or structure. It'll shake you out of it so you'll be free to move forward."
};

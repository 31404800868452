import PropTypes from 'prop-types';
import React from 'react';
import Headroom from 'react-headroom';

const BreadcrumbList = ({ children }) => (
  // <nav className="nav-header">
  <Headroom>
    <ol className="nav-list" itemScope itemType="http://schema.org/BreadcrumbList">
      {children}
    </ol>
  </Headroom>
  // </nav>
);

BreadcrumbList.propTypes = {
  children: PropTypes.any
};

export default BreadcrumbList;

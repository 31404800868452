export default {
  author: 'Eric Ries',
  category: 'book',
  date: '1/6/19',
  link: 'https://amzn.to/2lzJA63',
  name: 'The Lean Startup',
  path: '/notes/the-lean-startup',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-lean-startup-eric-ries.jpg',
  tags: ['Business', 'Management'],
  time: 21,
  rating: 7.6,
  review:
    "A book about the benefits of being lean. Why to stay lean, what pieces you'll need, and what to focus on. Great book for entrepreneurs."
};

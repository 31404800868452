export default {
  category: 'book',
  name: 'The Great Gatsby',
  author: 'F. Scott Fitzgerald',
  date: '9/16/20',
  path: '/notes/the-great-gatsby',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-great-gatsby-f-scott-fitzgerald.jpg',
  tags: ['Fiction'],
  time: 1,
  rating: 7.4,
  review: '',
  link: 'https://www.amazon.com/Great-Gatsby-F-Scott-Fitzgerald/dp/0743273567',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
twosLink: '',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: ''
};

export default {
  category: 'book',
  name: 'Crush It!',
  author: 'Gary Vaynerchuk',
  date: '12/30/21',
  path: '/notes/crush-it',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-crush-it-gary-vaynerchuk.jpg',
  tags: ['Business', 'Marketing', 'Entrepreneurship'],
  time: 2,
  rating: 6.6,
  review: '',
  link: 'https://www.amazon.com/Crush-Time-Cash-Your-Passion/dp/0061914177',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
  twosLink: 'https://www.twosapp.com/61c1641e2b52a9805968bdec',
};

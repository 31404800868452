export default {
  category: 'write',
  date: '8/30/19',
  name: 'Finding Happiness',
  path: '/write/finding-happiness',
  photo: 'https://twos.s3.amazonaws.com/parker-klein-finding-happiness.png',
  podcastLink: '',
  youtubeLink: '',
twosLink: '',
  mediumLink: 'https://parkerklein.medium.com/finding-happiness-7cdc8cff78a',
  patreonLink: '',
  tags: ['Write'],
  time: 1
};

export default {
  author: 'Bill Bryson',
  category: 'book',
  date: '10/19/19',
  name: 'A Short History Of Nearly Everything',
  path: '/notes/a-short-history-of-nearly-everything',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-a-short-history-of-nearly-everything-bill-bryson.jpg',
  tags: ['Humor', 'History', 'Science'],
  time: 13,
  link: 'https://www.amazon.com/Short-History-Nearly-Everything/dp/076790818X',
  mediumLink: '',
  patreonLink: '',
  podcastLink: '',
  podcastEmbed: '',
  youtubeLink: '',
twosLink: '',
  youtubeEmbed: '',
  releaseDate: '',
  rating: 7.8,
  review:
    "The title isn't wrong, lol. Really interesting facts about the universe and the world we now live in. Makes you realize how small and unrelevant you really are. Leaves you grateful to be able to experience it all."
};

export default {
  category: 'book',
  name: 'The Way of Zen',
  author: 'Alan Watts',
  date: '7/12/24',
  path: '/notes/the-way-of-zen',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-way-of-zen-alan-watts.jpg',
  tags: ['Religion', 'Spirituality', 'Buddhism', 'Self-help', 'Philosophy'],
  time: 39,
  rating: 8.1,
  review: '',
  link: 'https://www.amazon.com/Way-Zen-Alan-W-Watts/dp/0375705104',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  twosLink: 'https://www.twosapp.com/667f47d0db9fca621ef7643e',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};

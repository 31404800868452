export default {
  category: 'book',
  name: 'Range',
  author: 'David Epstein',
  date: '1/18/20',
  path: '/notes/range',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-range-david-epstein.jpg',
  tags: ['Parenting', 'Philosophy'],
  time: 3,
  rating: 6.5,
  review:
    'Book about learning, making connections, being creative, and innovating. How specialization applies to the work environment. If you want to be creative, generalize; if you want to skillful, specialize.',
  link: 'https://www.amazon.com/Range-Generalists-Triumph-Specialized-World/dp/0735214484',
  twosLink: 'https://www.twosapp.com/5e0d4e3efc61bb04e0c3a99d',
  mediumLink: 'https://parkerklein.medium.com/range-why-generalists-triumph-in-a-specialized-world-by-david-epstein-7a1b931da359',
  patreonLink: '',
  podcastLink: 'https://anchor.fm/parkerklein/episodes/Range-by-David-Epstein-eaosgm',
  podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/Range-by-David-Epstein-eaosgm',
  youtubeLink: 'https://youtu.be/OGdm22OgUTQ',
  youtubeEmbed: 'https://www.youtube.com/embed/OGdm22OgUTQ',
  releaseDate: '2/10/20'
};

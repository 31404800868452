export default {
  category: 'book',
  name: 'Barbarian Days',
  author: 'William Finnegan',
  date: '1/23/23',
  path: '/notes/barbarian-days',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-barbarian-days-william-finnegan.jpg',
  tags: ['Fiction', 'Biography', 'Memoir', 'Sports'],
  time: 2,
  rating: 7.4,
  review: '',
  link: 'https://www.amazon.com/Barbarian-Days-Surfing-William-Finnegan/dp/0143109391',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  twosLink: 'https://www.TwosApp.com/629d50b15440754baf245c39',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};

export default {
  name: 'Why We Sleep',
  author: 'Matthew Walker, PhD',
  tags: ['Medicine', 'Science', 'Sleep'],
  path: '/notes/why-we-sleep',
  category: 'book',
  date: '9/15/19',
  time: 20,
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-why-we-sleep-matthew-walker.jpg',
  link: 'https://www.amazon.com/Why-We-Sleep-Unlocking-Dreams/dp/1501144316',
  mediumLink: '',
  patreonLink: '',
  podcastLink: '',
  podcastEmbed: '',
  youtubeLink: '',
twosLink: '',
  youtubeEmbed: '',
  releaseDate: '',
  rating: 8.2,
  review:
    "Fascinating book on why we sleep, why its important, and what happens if we don't do it. You need to sleep and this book will motivate you to do more of it. Full of science and data."
};

export default {
  category: 'book',
  name: 'The Greatest Salesman in the World',
  author: 'OG Mandino',
  date: '12/25/20',
  path: '/notes/the-greatest-salesman-in-the-world',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-greatest-salesman-in-the-world-og-mandino.jpg',
  tags: ['Christianity', 'Sales', 'Business'],
  time: 17,
  rating: 9.2,
  review: '',
  link: 'https://www.amazon.com/Greatest-Salesman-World-Og-Mandino/dp/055327757X',
  mediumLink: 'https://parkerklein.medium.com/the-greatest-salesman-in-the-world-by-og-mandino-3ab0203af7d3',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
twosLink: 'https://www.twosapp.com/5fe0128b7595e7a15ad55d36',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: ''
};

export default {
  category: 'write',
  date: '9/14/20',
  name: 'Failure',
  path: '/write/failure',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-failure.jpg',
  podcastLink: '',
  youtubeLink: '',
twosLink: '',
  mediumLink: '',
  patreonLink: '',
  tags: ['Write'],
  time: 2
};

export default {
  author: 'Plato',
  category: 'book',
  date: '7/7/17',
  link: 'https://amzn.to/2lnmD5L',
  name: 'The Allegory of the Cave',
  path: '/notes/the-allegory-of-the-cave',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-allegory-of-the-cave-plato.jpg',
  tags: ['Philosophy'],
  time: 4,
  rating: 6.2,
  review:
    'Great book about ignorance and the struggle we face when we see things as they really are. We should all seek more knowledge and truth even if it hurts.'
};

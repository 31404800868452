export default {
  author: 'Jordan B. Peterson',
  category: 'book',
  date: '9/20/18',
  link: 'https://www.amazon.com/12-Rules-Life-Antidote-Chaos/dp/0345816021',
  name: '12 Rules For Life',
  path: '/notes/12-rules-for-life',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-12-rules-for-life-jordan-b-peterson.jpg',
  tags: ['Psychology', 'Philosophy', 'Ethics'],
  time: 146,
  rating: 9.2,
  review:
    "Thicc book, but one of my favorites. Really like Jordan Peterson and the way he doesn't sugar coat things. He tells it how it is and this book has a lot of great ideas on how people should conduct their lives that are not only beneficial to themselves, but also society. Also, has a lot of studies and research to back up his rules."
};

export default {
  author: 'Jake Knapp',
  category: 'book',
  date: '3/24/19',
  link: 'https://amzn.to/2lwP2Xl',
  name: 'Make Time',
  path: '/notes/make-time',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-make-time-jake-knapp.jpg',
  tags: ['Business'],
  time: 4,
  mediumLink: '',
  patreonLink: '',
  podcastLink: 'https://anchor.fm/parkerklein/episodes/Make-Time-by-Jake-Knapp-e9a9f9',
  podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/Make-Time-by-Jake-Knapp-e9a9f9',
  youtubeLink: 'https://youtu.be/vwgf9G4x1i8',
  youtubeEmbed: 'https://www.youtube.com/embed/vwgf9G4x1i8',
  releaseDate: '12/2/19',
  rating: 5.2,
  review:
    'A book about making time for what is most important. Couple tricks and tips to limit your phone use and other forces stealing your time.'
};

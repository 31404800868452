export default {
  category: 'book',
  name: 'Made to Stick',
  author: 'Chip Heath',
  date: '9/26/21',
  path: '/notes/made-to-stick',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-made-to-stick-chip-heath.jpg',
  tags: ['Business', 'Management', 'Sales'],
  time: 6,
  rating: 8.1,
  review: '',
  link: 'https://www.amazon.com/Made-Stick-Ideas-Survive-Others/dp/1400064287',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
twosLink: 'https://www.TwosApp.com/60e0f2d0497bbe3b60f49037',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: ''
};

export default {
  category: 'book',
  name: 'Relentless',
  author: 'Tim Grover',
  date: '2/12/24',
  path: '/notes/relentless',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-relentless-tim-grover.jpg',
  tags: ['Management', 'Leadership', 'Self-help'],
  time: 35,
  rating: 8.1,
  review: '',
  link: 'https://www.amazon.com/Relentless-Unstoppable-Tim-S-Grover/dp/1476714207',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  twosLink: 'https://www.TwosApp.com/65b65c6500b2229385e4879e',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};

export default {
  category: 'write',
  date: '4/11/20',
  name: 'Why Do We Hurt Ourselves',
  path: '/write/why-do-we-hurt-ourselves',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-why-do-we-hurt-ourselves.jpg',
  podcastLink: '',
  youtubeLink: '',
twosLink: '',
  mediumLink: '',
  patreonLink: '',
  tags: ['Write'],
  time: 2
};

export default {
  category: 'book',
  name: 'How Not to Die',
  author: 'Michael Greger',
  date: '10/10/21',
  path: '/notes/how-not-to-die',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-how-not-to-die-michael-greger.jpg',
  tags: ['Food', 'Nutrition', 'Health'],
  time: 2,
  rating: 7.8,
  review: '',
  link: 'https://www.amazon.com/How-Not-Die-Discover-Scientifically/dp/1250066115',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
twosLink: 'https://www.TwosApp.com/612c3958abd50800dff40239',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: ''
};

export default {
  category: 'write',
  date: '12/29/19',
  name: "New Year's Resolutions for 2020",
  path: '/write/new-years-resolutions-for-2020',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-new-years-resolutions-for-2020.jpg',
  podcastLink: '',
  youtubeLink: '',
twosLink: '',
  mediumLink: '',
  patreonLink: '',
  tags: ['Write'],
  time: 1
};

export default {
  author: 'Donald Miller',
  category: 'book',
  date: '3/12/16',
  name: 'A Million Miles in a Thousand Years',
  path: '/notes/a-million-miles-in-a-thousand-years',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-a-million-miles-in-a-thousand-years-donald-miller.jpg',
  tags: ['Christianity', 'Self-help'],
  time: 0,
  link: 'https://www.amazon.com/Million-Miles-Thousand-Years-Learned/dp/1400202981',
  mediumLink: '',
  patreonLink: '',
  podcastLink: '',
  podcastEmbed: '',
  youtubeLink: '',
twosLink: '',
  youtubeEmbed: '',
  releaseDate: '',
  rating: 3.2,
  review:
    'Story of how Donald Miller found more meaning in his life. Good story on how to be grateful, how to find meaning in the little things, and how wonderful and interesting people are. At the time, nothing really stood out to me.'
};

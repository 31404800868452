export default {
  author: 'Steve Blank',
  category: 'book',
  date: '3/9/17',
  name: 'The Four Steps to Epiphany',
  path: '/notes/the-four-steps-to-epiphany',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-four-steps-to-epiphany-steve-blank.jpg',
  tags: ['Business', 'Management', 'Leadership'],
  time: 0,
  link: 'https://www.amazon.com/Four-Steps-Epiphany-Steve-Blank/dp/0989200507',
  mediumLink: '',
  patreonLink: '',
  podcastLink: '',
  podcastEmbed: '',
  youtubeLink: '',
twosLink: '',
  youtubeEmbed: '',
  releaseDate: '',
  rating: 6.5,
  review:
    'Solid book for strategically designing, developing, and launching a project. The "right" way to do it if there is a right way.'
};

export default {
  category: 'write',
  date: '10/9/19',
  name: 'Interesting People',
  path: '/write/interesting-people',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-interesting-people.png',
  podcastLink: '',
  youtubeLink: '',
twosLink: '',
  mediumLink: 'https://parkerklein.medium.com/interesting-people-36e8778f0a4f',
  patreonLink: '',
  tags: ['Write'],
  time: 1
};

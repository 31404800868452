export default {
  author: 'Peter Drucker',
  category: 'book',
  date: '9/5/18',
  link: 'https://amzn.to/2lmz2qD',
  name: 'The Effective Executive',
  path: '/notes/the-effective-executive',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-effective-executive-peter-drucker.jpg',
  tags: ['Business', 'Management', 'Leadership'],
  time: 10,
  rating: 6.8,
  review:
    'How to be an effective executive, lol. Questions to ask, mindsets to get in, how to lead. Helps you contribute more.'
};

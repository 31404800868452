export default {
  category: 'book',
  name: 'How to Take Smart Notes',
  author: 'Sonke Ahrens',
  date: '8/8/21',
  path: '/notes/how-to-take-smart-notes',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-how-to-take-smart-notes-sonke-ahrens.jpg',
  tags: ['Education'],
  time: 14,
  rating: 7.1,
  review: '',
  link: 'https://www.amazon.com/How-Take-Smart-Notes-Nonfiction-ebook/dp/B06WVYW33Y',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
twosLink: 'https://www.TwosApp.com/605d1feacbac652b47885fd3',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: ''
};

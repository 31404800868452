export default {
  category: 'write',
  date: '9/8/19',
  name: 'Why me?',
  path: '/write/why-me',
  photo: 'https://twos.s3.amazonaws.com/parker-klein-why-me.png',
  podcastLink: '',
  youtubeLink: '',
twosLink: '',
  mediumLink: 'https://parkerklein.medium.com/why-me-bb15cb4b2901',
  patreonLink: 'https://www.patreon.com/posts/why-me-29816216',
  tags: ['Write'],
  time: 1
};

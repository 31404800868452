export default {
  author: 'Napoleon Hill',
  category: 'book',
  date: '10/15/16',
  link: 'https://amzn.to/32sgvKy',
  name: 'Think and Grow Rich',
  path: '/notes/think-and-grow-rich',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-think-and-grow-rich-napoleon-hill.jpg',
  tags: ['Business', 'Finance', 'Self-help'],
  time: 36,
  rating: 7.8,
  review:
    'A book about figuring out exactly what you want, and then talking it into reality. Set your intentions and let them direct your actions.'
};

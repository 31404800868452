export default {
  category: 'write',
  date: '3/6/17',
  name: 'I am committed to...',
  path: '/write/i-am-committed-to',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-i-am-committed-to.jpg',
  podcastLink: '',
  youtubeLink: '',
twosLink: '',
  mediumLink: '',
  patreonLink: '',
  tags: ['Write'],
  time: 1
};

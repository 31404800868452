export default {
  category: 'write',
  date: '7/15/19',
  name: '7 Reasons Why You Should Learn How to Program',
  path: '/write/7-reasons-why-you-should-learn-how-to-program',
  photo:
    'https://twos.s3.amazonaws.com/parker-klein-7-reasons-why-you-should-learn-how-to-program.jpg',
  podcastLink: '',
  youtubeLink: '',
twosLink: '',
  mediumLink: '',
  patreonLink: '',
  tags: ['Write'],
  time: 1
};

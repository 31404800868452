export default {
  category: 'book',
  name: 'The Power Law',
  author: 'Sebastian Mallaby',
  date: '11/8/22',
  path: '/notes/the-power-law',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-power-law-sebastian-mallaby.jpg',
  tags: ['Business', 'Money', 'Finance', 'Venture Capital', 'Investing'],
  time: 1,
  rating: 7.6,
  review: '',
  link: 'https://www.amazon.com/Power-Law-Venture-Capital-Making/dp/052555999X',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  twosLink: 'https://www.TwosApp.com/62f03e08cca2dd353af1b208',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};

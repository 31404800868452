export default {
  author: 'Michael Lewis',
  category: 'book',
  date: '2/2/16',
  name: 'The Big Short',
  path: '/notes/the-big-short',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-big-short-michael-lewis.jpg',
  tags: ['Business', 'Economics', 'History', 'Finance'],
  time: 0,
  link:
    'https://www.amazon.com/Big-Short-Doomsday-tie-Tie/dp/039335315X/ref=pd_sbs_14_t_0/141-8773159-0098750?_encoding=UTF8&pd_rd_i=039335315X&pd_rd_r=78adca41-be8c-4757-acc6-2c92f99db186&pd_rd_w=tb6V1&pd_rd_wg=IQsnZ&pf_rd_p=5cfcfe89-300f-47d2-b1ad-a4e27203a02a&pf_rd_r=KAHZT4D0TW1TX3ECW00T&psc=1&refRID=KAHZT4D0TW1TX3ECW00T',
  mediumLink: '',
  patreonLink: '',
  podcastLink: '',
  podcastEmbed: '',
  youtubeLink: '',
twosLink: '',
  youtubeEmbed: '',
  releaseDate: '',
  rating: 5.4,
  review:
    'Interesting book about what caused the housing crisis. People are always looking to make a quick buck.'
};

export default {
  category: 'book',
  name: 'Fat Loss Forever',
  author: 'Layne Norton',
  date: '1/26/22',
  path: '/notes/fat-loss-forever',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-fat-loss-forever-layne-norton.jpg',
  tags: ['Health', 'Diet'],
  time: 15,
  rating: 8.4,
  review: '',
  link: 'https://www.amazon.com/Fat-Loss-Forever-Lose-KEEP-ebook/dp/B07MYWGSL5',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
twosLink: 'https://www.TwosApp.com/61bff91c63c5cd3a5bbf02a5',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};

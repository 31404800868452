export default {
  author: 'David Flanagan',
  category: 'book',
  date: '8/14/17',
  link: 'https://www.amazon.com/JavaScript-Definitive-Guide-Activate-Guides/dp/0596805527',
  mediumLink: '',
  // eslint-disable-next-line
  name: 'JavaScript: The Definitive Guide',
  path: '/notes/javascript-the-definitive-guide',
  patreonLink: '',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-javascript-the-definitive-guide-david-flanagan.jpg',
  podcastLink: '',
  podcastEmbed: '',
  tags: ['Programming', 'JavaScript'],
  time: 6,
  youtubeLink: '',
twosLink: '',
  youtubeEmbed: '',
  releaseDate: '',
  rating: 6.6,
  review:
    'A great introduction to new programmers using the JavaScript language. Teaches you the basics of programming while teaching you specifics about the JavaScript language.'
};

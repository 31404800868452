export default {
  category: 'write',
  date: '1/25/20',
  name: 'Sacrifice',
  path: '/write/sacrifice',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-sacrifice.png',
  podcastLink: '',
  youtubeLink: '',
twosLink: '',
  mediumLink: '',
  patreonLink: '',
  tags: ['Write'],
  time: 1
};

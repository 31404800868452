export default {
  category: 'book',
  name: 'Letters from a Stoic',
  author: 'Seneca',
  date: '11/4/23',
  path: '/notes/letters-from-a-stoic',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-letters-from-a-stoic-seneca.jpg',
  tags: ['Philosophy', 'Stoicism', 'Ethics'],
  time: 40,
  rating: 8.1,
  review: '',
  link: 'https://www.amazon.com/Letters-Penguin-Classics-Lucius-Annaeus/dp/0140442103',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  twosLink: 'https://www.twosapp.com/650350a5a1ebf57e226ce2fa',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};

export default {
  category: 'book',
  name: 'Smarter Faster Better',
  author: 'Charles Duhigg',
  date: '4/28/20',
  path: '/notes/smarter-faster-better',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-smarter-faster-better-charles-duhigg.jpg',
  tags: ['Business', 'Management', 'Psychology'],
  time: 2,
  rating: 5.4,
  review:
    'Good book for a manager wanting to get the most out of their direct reports. Not too much in it for the individual.',
  link:
    'https://www.amazon.com/Smarter-Faster-Better-Transformative-Productivity-ebook/dp/B00Z3FRYB0',
  mediumLink: 'https://parkerklein.medium.com/smarter-faster-better-the-transformative-power-of-real-productivity-by-charles-duhigg-b405cace28cd',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
twosLink: 'https://www.twosapp.com/5e9b880da644d0bf01882843',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: ''
};

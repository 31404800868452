export default {
  category: 'book',
  name: 'The Celestine Prophecy',
  author: 'James Redfield',
  date: '5/12/24',
  path: '/notes/the-celestine-prophecy',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-celestine-prophecy-james-redfield.jpg',
  tags: ['Fiction', 'Spirituality', 'Religion'],
  time: 43,
  rating: 8.7,
  review: '',
  link: 'https://www.amazon.com/Celestine-Prophecy-Adventure-James-Redfield/dp/0446671002',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  twosLink: 'https://www.TwosApp.com/662ce61d2a614846ddac74d0',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};

export default {
  category: 'book',
  name: 'Traction',
  author: 'Gabriel Weinberg',
  date: '12/18/21',
  path: '/notes/traction',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-traction-gabriel-weinberg.jpg',
  tags: ['Business', 'Marketing', 'Finance'],
  time: 19,
  rating: 8.1,
  review: '',
  link: 'https://www.amazon.com/Traction-Startup-Achieve-Explosive-Customer/dp/1591848369',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
twosLink: 'https://www.TwosApp.com/612c455e252240a8076fb421',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: ''
};

export default {
  category: 'book',
  name: 'Backable',
  author: 'Suneel Gupta',
  date: '9/14/23',
  path: '/notes/backable',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-backable-suneel-gupta.jpg',
  tags: ['Money', 'Investing', 'Leadership', 'Business'],
  time: 7,
  rating: 7.4,
  review: '',
  link: 'https://www.amazon.com/Backable-Surprising-Behind-People-Chance/dp/0316494518',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  twosLink: 'https://www.twosapp.com/64f9bbd2c369ad1d005f68e1',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};

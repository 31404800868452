export default {
  author: 'Jon Bentley',
  category: 'book',
  date: '8/1/17',
  link: 'https://www.amazon.com/Programming-Pearls-2nd-Jon-Bentley/dp/0201657880',
  mediumLink: '',
  name: 'Programming Pearls',
  path: '/notes/programming-pearls',
  patreonLink: '',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-programming-pearls-jon-bentley.jpg',
  podcastLink: '',
  podcastEmbed: '',
  tags: ['Programming'],
  time: 4,
  youtubeLink: '',
twosLink: '',
  youtubeEmbed: '',
  releaseDate: '',
  rating: 5.6,
  review: 'Helpful tips and practices for designing programs to be simple and maintainable.'
};

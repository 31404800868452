export default {
  category: 'book',
  name: 'The Innovators',
  author: 'Walter Isaacson',
  date: '4/24/21',
  path: '/notes/the-innovators',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-innovators-walter-isaacson.jpg',
  tags: ['Engineering'],
  time: 4,
  rating: 6.7,
  review: 'A full history of how computing, computers, software, and the web were made. Very cool to see how how ideas build on each other and how big a part collaboration plays in innovation.',
  link: 'https://www.amazon.com/Innovators-Hackers-Geniuses-Created-Revolution/dp/1476708703',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
twosLink: 'https://www.TwosApp.com/6056aff41d4f4ecede48d103',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: ''
};

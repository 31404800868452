export default {
  author: 'Anthony de Mello',
  category: 'book',
  date: '10/3/19',
  name: 'Awareness',
  path: '/notes/awareness',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-awareness-anthony-de-mello.jpg',
  tags: ['Catholicism', 'Spirituality'],
  time: 20,
  link: 'https://www.amazon.com/Awareness-Opportunities-Reality-Anthony-Mello/dp/0385249373',
  mediumLink: '',
  patreonLink: '',
  podcastLink: '',
  podcastEmbed: '',
  youtubeLink: '',
twosLink: '',
  youtubeEmbed: '',
  releaseDate: '',
  rating: 9.5,
  review:
    'Another book that really blew my mind. Definitely had to take breaks to take it all in. Makes you aware of all the prejudices and labels you put on everything, and the magic of being mindful and experiencing everything as new and full of opportunity.'
};

import React from 'react';
import {
  FaAmazon,
  FaApple,
  FaCoffee,
  FaEnvelope,
  FaFacebookF,
  FaGithub,
  FaInstagram,
  FaLinkedin,
  FaMedium,
  FaPatreon,
  FaPodcast,
  FaSpotify,
  FaTwitter,
  FaYoutube
} from 'react-icons/fa';
import { SiSubstack } from 'react-icons/si';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import Helmet from '../components/Helmet';
import NewsForm from '../components/NewsForm';
import {
  apps,
  books,
  // podcasts,
  // videos,
  work,
  writings,
} from '../data';
import { sortData } from '../helpers';

const app = apps[0];
const job = work[0];
const book = sortData(books, 'date')[0];
const write = sortData(writings, 'date')[0];
// const video = sortData(videos, 'releaseDate')[0];
// const podcast = sortData(podcasts, 'releaseDate')[0];

const HomePage = () => (
  <main className="main-content">
    <ReactTooltip effect="solid" />
    <Helmet
      description="Welcome to Parker Klein's homepage. Explore Parker's notes, writings, videos, podcasts, experience, and what he is up to now."
      title="Home Page"
    />
    {/* <h2 className="title">Home</h2> */}
    {/* <p className="form-label">Pages</p> */}
    <div className="home-content">
      <Link
        aria-label="Parker Klein Now"
        className="home-button"
        to="/now"
        id="Now"
        title="Parker Klein Now"
      >
        <img
          alt="Parker Klein Now"
          src="https://twos.s3.amazonaws.com/twos-thread-300.png"
        />
        <h3 className="title button-title">Now</h3>
      </Link>
      <Link
        aria-label="About Parker Klein"
        className="home-button"
        to="/about"
        id="About"
        title="About Parker Klein"
      >
        <img
          alt="Parker Klein"
          src="https://twos.s3.amazonaws.com/parkerklein/parker-klein-photo-v2.jpg"
        />
        <h3 className="title button-title">About</h3>
      </Link>
      <Link
        aria-label="Parker Klein's Apps"
        className="home-button"
        to="/apps"
        id="Apps"
        title="Parker Klein's Apps"
      >
        <img alt={`${app.name} | ${app.tagline}`} src={app.logo} />
        <h3 className="title button-title">Apps</h3>
      </Link>
      <Link
        aria-label="Parker Klein's Notes"
        className="home-button"
        to="/notes"
        id="Notes"
        title="Parker Klein's Notes"
      >
        <img alt={`${book.name} by ${book.author}`} src={book.photo} />
        <h3 className="title button-title">Notes</h3>
      </Link>
      <Link
        aria-label="Parker Klein's Podcast"
        className="home-button"
        to="/write"
        id="Podcast"
        title="Parker Klein's Podcast"
      >
        <img alt={`${write.name} by Parker Klein`} src={write.photo} />
        <h3 className="title button-title">Write</h3>
      </Link>
      {/*<Link
        aria-label="Parker Klein's YouTube Videos"
        className="home-button"
        to="/videos"
        id="Videos"
        title="Parker Klein's Youtube Videos"
      >
        <img alt={`${video.name} by ${video.author}`} src={video.photo} />
        <h3 className="title button-title">Videos</h3>
      </Link>
      <Link
        aria-label="Parker Klein's Podcast"
        className="home-button"
        to="/podcast"
        id="Podcast"
        title="Parker Klein's Podcast"
      >
        <img alt={`${podcast.name} by ${podcast.author}`} src={podcast.photo} />
        <h3 className="title button-title">Podcast</h3>
      </Link>*/}
      <Link
        aria-label="Parker Klein's Work"
        className="home-button"
        to="/work"
        id="Work"
        title="Parker Klein's Work"
      >
        <img alt={job.name} src={job.logo} />
        <h3 className="title button-title">Work</h3>
      </Link>
      <Link
        aria-label="Contact Parker"
        className="home-button"
        to="/contact"
        id="Contact"
        title="Contact Parker"
      >
        <div className="home-icon">
          <FaEnvelope />
        </div>
        <h3 className="title button-title">Contact</h3>
      </Link>
    </div>
    <p className="form-label">Links</p>
    <div className="home-links">
      <a
        aria-label="Parker Klein's YouTube channel"
        className="icon-link youtube"
        data-tip="YouTube"
        href="https://www.youtube.com/channel/UCmVKO9VkVgRmF5We3mF0YWg"
        id="YouTube"
        rel="noopener noreferrer"
        target="_blank"
        title="Parker Klein's YouTube channel"
      >
        <FaYoutube />
      </a>
      <a
        aria-label="Parker Klein's Instagram page"
        className="icon-link instagram"
        data-tip="Instagram"
        href="https://www.instagram.com/twosballer"
        id="Instagram"
        rel="noopener noreferrer"
        target="_blank"
        title="Parker Klein's Instagram page"
      >
        <FaInstagram />
      </a>
      <a
        aria-label="Parker Klein's Patreon page"
        className="icon-link patreon"
        data-tip="Patreon"
        href="https://www.patreon.com/parkerklein"
        id="Patreon"
        rel="noopener noreferrer"
        target="_blank"
        title="Parker Klein's Patreon page"
      >
        <FaPatreon />
      </a>
      <a
        aria-label="Parker Klein's SubStack account"
        className="icon-link substack"
        data-tip="SubStack"
        href="https://parkerklein.substack.com/"
        rel="noopener noreferrer"
        target="_blank"
        title="Parker Klein's SubStack account"
      >
        <SiSubstack />
      </a>
      <a
        aria-label="Parker Klein's Buy Me A Coffee page"
        className="icon-link coffee"
        data-tip="Buy Me A Coffee"
        href="https://www.buymeacoffee.com/xJUZB6b"
        id="BuyMeACoffee"
        rel="noopener noreferrer"
        target="_blank"
        title="Parker Klein's Buy Me A Coffee page"
      >
        <FaCoffee />
      </a>
      <a
        aria-label="Parker Klein's Amazon affiliate link"
        className="icon-link amazon"
        data-tip="Amazon"
        href="https://amzn.to/2JUTDuQ"
        id="Amazon"
        rel="noopener noreferrer"
        target="_blank"
        title="Parker Klein's Amazon affiliate link"
      >
        <FaAmazon />
      </a>
      <a
        aria-label="Parker Klein's Notes Spotify page"
        className="icon-link spotify"
        data-tip="Spotify"
        href="https://open.spotify.com/show/0vpDlWIKLgD1Myk6VjTawH"
        id="Spotify"
        rel="noopener noreferrer"
        target="_blank"
        title="Parker Klein's Notes Spotify page"
      >
        <FaSpotify />
      </a>
      <a
        aria-label="Parker Klein's Twitter account"
        className="icon-link twitter"
        data-tip="Twitter"
        href="http://twitter.com/twosballer"
        rel="noopener noreferrer"
        target="_blank"
        title="Parker Klein's Twitter account"
      >
        <FaTwitter />
      </a>
      <a
        aria-label="Parker Klein's LinkedIn account"
        className="icon-link linkedin"
        data-tip="LinkedIn"
        href="http://www.linkedin.com/in/parkeraklein"
        id="LinkedIn"
        rel="noopener noreferrer"
        target="_blank"
        title="Parker Klein's LinkedIn account"
      >
        <FaLinkedin />
      </a>
      <a
        aria-label="Parker Klein's Facebook account"
        className="icon-link facebook"
        data-tip="Facebook"
        href="https://www.facebook.com/parker.klein.9/"
        rel="noopener noreferrer"
        target="_blank"
        title="Parker Klein's Facebook account"
      >
        <FaFacebookF />
      </a>
      <a
        aria-label="Parker Klein's Notes Podcast"
        className="icon-link podcast"
        data-tip="Podcast"
        href="https://anchor.fm/parkerklein"
        id="Anchor"
        rel="noopener noreferrer"
        target="_blank"
        title="Parker Klein's Notes Podcast"
      >
        <FaPodcast />
      </a>
      <a
        aria-label="Parker Klein's iOS Developer Account"
        className="icon-link apple"
        data-tip="Apple"
        href="https://itunes.apple.com/us/developer/twos-technologies-llc/id1097350933"
        id="Apple"
        rel="noopener noreferrer"
        target="_blank"
        title="Parker Klein's iOS Developer Account"
      >
        <FaApple />
      </a>
      <a
        aria-label="Parker Klein's Github page"
        className="icon-link github"
        data-tip="Github"
        href="http://github.com/SanKlein"
        id="Github"
        rel="noopener noreferrer"
        target="_blank"
        title="Parker Klein's Github page"
      >
        <FaGithub />
      </a>
      <a
        aria-label="Parker Klein's Medium page"
        className="icon-link medium"
        data-tip="Medium"
        href="https://parkerklein.medium.com"
        id="Medium"
        rel="noopener noreferrer"
        target="_blank"
        title="Parker Klein's Medium page"
      >
        <FaMedium />
      </a>
      <a
        aria-label="Parker Klein's Twos profile"
        className="icon-link twos"
        data-tip="Twos"
        href="https://www.TwosApp.com/@parker"
        id="Twos"
        rel="noopener noreferrer"
        target="_blank"
        title="Parker Klein's Twos profile"
      >
        <img
          alt="Twos: days, lists, & things"
          src="https://twos.s3.amazonaws.com/twos-thread-background-transparent-300.png"
          height="34px"
          width="23px"
        />
      </a>
    </div>
    <NewsForm />
  </main>
);

export default HomePage;

export default {
  author: 'Leonard Koren',
  category: 'book',
  date: '7/12/19',
  link: 'https://amzn.to/2l8JHoO',
  name: 'Wabi-Sabi',
  path: '/notes/wabi-sabi',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-wabi-sabi-leonard-koren.jpg',
  tags: ['Art', 'Design'],
  time: 3,
  rating: 6.8,
  review:
    'There is beauty if things being imperfect. In leaving things unfinished. Doing great work and then letting them live. Not suffocating them.',
  mediumLink: 'https://medium.com/twosapp/wabi-sabi-by-leonard-koren-ce67f12929b6',
  twosLink: 'https://www.twosapp.com/5d2940a57cfe4a6fb53ded94',
};

export default {
  author: 'Ed Catmull',
  category: 'book',
  date: '6/19/15',
  name: 'Creativity, Inc.',
  path: '/notes/creativity-inc',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-creativity-inc-ed-catmull.jpg',
  tags: ['Business', 'Art'],
  time: 0,
  link: 'https://www.amazon.com/Creativity-Inc-Overcoming-Unseen-Inspiration/dp/0812993012',
  mediumLink: '',
  patreonLink: '',
  podcastLink: '',
  podcastEmbed: '',
  youtubeLink: '',
twosLink: '',
  youtubeEmbed: '',
  releaseDate: '',
  rating: 7.1,
  review:
    'Shows you how intense and hard-working the people are at Pixar. A great look into their creative process, which is everything takes multiple drafts. They were extremely risky and this book is very inspirational.'
};

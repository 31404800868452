export default {
  category: 'book',
  name: 'The Road to Character',
  author: 'David Brooks',
  date: '5/2/23',
  path: '/notes/the-road-to-character',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-road-to-character-david-brooks.jpg',
  tags: ['Social Sciences', 'Philosophy', 'Self-help'],
  time: 15,
  rating: 8.8,
  review: '',
  link: 'https://www.amazon.com/Road-Character-David-Brooks/dp/0812983416',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  twosLink: 'https://www.twosapp.com/642a1e667a97759c72c426bf',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};

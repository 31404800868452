export default {
  category: 'write',
  date: '1/29/20',
  name: "Here's the thing tho",
  path: '/write/heres-the-thing-tho',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-heres-the-thing-tho.png',
  podcastLink: '',
  youtubeLink: '',
twosLink: '',
  mediumLink: '',
  patreonLink: '',
  tags: ['Write'],
  time: 1
};

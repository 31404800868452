export default {
  category: 'book',
  name: 'Masters of Doom',
  author: 'David Kushner',
  date: '2/16/23',
  path: '/notes/masters-of-doom',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-masters-of-doom-david-kushner.jpg',
  tags: ['Business', 'Money', 'Biography'],
  time: 4,
  rating: 7.8,
  review: '',
  link: 'https://www.amazon.com/Masters-Doom-Created-Transformed-Culture/dp/0812972155',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  twosLink: 'https://www.TwosApp.com/63d0912853941cf381ab4237',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};

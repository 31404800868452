export default {
  category: 'book',
  name: 'Originals',
  author: 'Adam Grant',
  date: '8/29/21',
  path: '/notes/originals',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-originals-adam-grant.jpg',
  tags: ['Business', 'Psychology', 'Management', 'Leadership'],
  time: 7,
  rating: 5.6,
  review: '',
  link: 'https://www.amazon.com/Originals-How-Non-Conformists-Move-World/dp/014312885X',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
twosLink: 'https://www.TwosApp.com/60cd349c8543219d374e2ce4',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: ''
};

export default {
  category: 'book',
  name: 'The Seven Spiritual Laws of Success',
  author: 'Deepak Chopra',
  date: '4/2/20',
  path: '/notes/the-seven-spiritual-laws-of-success',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-seven-spiritual-laws-of-success-deepak-chopra.jpg',
  tags: ['Spirituality'],
  time: 6,
  rating: 8.9,
  review:
    "Short book about understanding your true nature, the universe's true nature, and how best those can co-exist. Life changer.",
  link: 'https://www.amazon.com/Seven-Spiritual-Laws-Success-Pocketbook/dp/1878424718',
  mediumLink: '',
  patreonLink: '',
  podcastLink:
    'https://anchor.fm/parkerklein/episodes/The-Seven-Spiritual-Laws-of-Success-by-Deepak-Chopra-ed0i07',
  podcastEmbed:
    'https://anchor.fm/parkerklein/embed/episodes/The-Seven-Spiritual-Laws-of-Success-by-Deepak-Chopra-ed0i07',
  youtubeLink: 'https://youtu.be/C0LSjN0vjEQ',
  youtubeEmbed: 'https://www.youtube.com/embed/C0LSjN0vjEQ',
  releaseDate: '4/27/20'
};

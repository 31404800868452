export default {
  author: 'Bruno Munari',
  category: 'book',
  date: '10/31/19',
  name: 'Design as Art',
  path: '/notes/design-as-art',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-design-as-art-bruno-munari.jpg',
  tags: ['Art', 'Design'],
  time: 6,
  link: 'https://www.amazon.com/Design-As-Art-Bruno-Munari/dp/0141035811',
  mediumLink: '',
  patreonLink: '',
  podcastLink: '',
  podcastEmbed: '',
  youtubeLink: '',
twosLink: '',
  youtubeEmbed: '',
  releaseDate: '',
  rating: 5.2,
  review:
    'Discusses how modern art is functional. We rely less on elaborate art and more on useful design.'
};

export default {
  category: 'book',
  name: 'Purple Cow',
  author: 'Seth Godin',
  date: '5/3/20',
  path: '/notes/purple-cow',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-purple-cow-seth-godin.jpg',
  tags: ['Marketing', 'Design', 'Business'],
  time: 1,
  rating: 7.1,
  review:
    'What makes your product stand out in the crowd? Think outside the box and take risks. Solid read.',
  link: 'https://www.amazon.com/Purple-Cow-New-Transform-Remarkable/dp/1591843170',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
twosLink: '',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: ''
};

export default {
  author: 'Antoine de Saint-Exupéry',
  category: 'book',
  date: '2/25/18',
  link: 'https://amzn.to/2la8R6u',
  name: 'The Little Prince',
  path: '/notes/the-little-prince',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-little-prince-antoine-de-saint-exupery.jpg',
  tags: ['Fiction'],
  time: 2,
  rating: 6.9,
  review:
    'A book about a little prince traveling through outer space. Short book with a lot of big lessons.'
};

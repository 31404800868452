export default {
  author: 'Meg Jay',
  category: 'book',
  date: '4/5/16',
  name: 'The Defining Decade',
  path: '/notes/the-defining-decade',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-defining-decade-meg-jay.jpg',
  tags: ['Self-help', 'Psychology'],
  time: 0,
  link: 'https://www.amazon.com/Defining-Decade-Your-Twenties-Matter/dp/0446561754',
  mediumLink: '',
  patreonLink: '',
  podcastLink: '',
  podcastEmbed: '',
  youtubeLink: '',
twosLink: '',
  youtubeEmbed: '',
  releaseDate: '',
  rating: 4.8,
  review:
    "Book about your 20s and how they shape much of the later years of your life. I tend to believe they aren't that big of a deal and you can always change and evolve, but it does help you focus more on the opportunities you have, which is always good :)."
};

export default {
  category: 'book',
  name: 'Essentialism',
  author: 'Greg McKeown',
  date: '2/9/20',
  path: '/notes/essentialism',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-essentialism-greg-mckeown.jpg',
  tags: ['Business', 'Management', 'Leadership'],
  time: 2,
  rating: 6.4,
  review:
    'Focus on the biggest movers. Focus on things within your control and exercise your control. You can make decisions and foccus on the things that are most important. Less but better.',
  link: 'https://www.amazon.com/Essentialism-Disciplined-Pursuit-Greg-McKeown/dp/0804137382',
  mediumLink: '',
  patreonLink: '',
  podcastLink: 'https://anchor.fm/parkerklein/episodes/Essentialism-by-Greg-McKeown-eb7764',
  podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/Essentialism-by-Greg-McKeown-eb7764',
  youtubeLink: 'https://youtu.be/H94ei3-cURQ',
  youtubeEmbed: 'https://www.youtube.com/embed/H94ei3-cURQ',
  releaseDate: '3/2/20'
};

export default {
  author: 'James Allen',
  category: 'book',
  date: '4/18/20',
  link: 'https://amzn.to/2xMRlIt',
  name: 'As a Man Thinketh 2',
  path: '/notes/as-a-man-thinketh-2',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-as-a-man-thinketh-james-allen.jpg',
  tags: ['Spirituality'],
  time: 2,
  mediumLink: '',
  patreonLink: '',
  podcastLink: 'https://anchor.fm/parkerklein/episodes/As-a-Man-Thinketh-by-James-Allen-v2-eeq0n2',
  podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/As-a-Man-Thinketh-by-James-Allen-v2-eeq0n2',
  youtubeLink: 'https://youtu.be/Ee0BpJ4C_hc',
  youtubeEmbed: 'https://www.youtube.com/embed/Ee0BpJ4C_hc',
  releaseDate: '6/8/20',
  rating: 9.4,
  review:
    'Second time reading this. A must read for everyone. So short and a slap in the face to take control of your thoughts and be intentional with your actions. You create your reality.'
};

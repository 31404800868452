export default {
  category: 'apps',
  startDate: '6/26/15',
  endDate: '',
  name: 'Twos',
  tagLine: 'Remember *things*',
  description: 'With Twos, you can stop forgetting *things* by quickly writing down *things* you want to remember.',
  position: 'Creator',
  screenshots: [
    'https://twos.s3.amazonaws.com/parker-klein-twos-notes-and-lists-screenshot-1.png',
    'https://twos.s3.amazonaws.com/parker-klein-twos-notes-and-lists-screenshot-2.png',
    'https://twos.s3.amazonaws.com/parker-klein-twos-notes-and-lists-screenshot-3.png',
    'https://twos.s3.amazonaws.com/parker-klein-twos-notes-and-lists-screenshot-4.png',
    'https://twos.s3.amazonaws.com/parker-klein-twos-notes-and-lists-screenshot-5.png',
    'https://twos.s3.amazonaws.com/parker-klein-twos-notes-and-lists-screenshot-6.png',
    'https://twos.s3.amazonaws.com/parker-klein-twos-notes-and-lists-screenshot-7.png',
    'https://twos.s3.amazonaws.com/parker-klein-twos-notes-and-lists-screenshot-8.png',
    'https://twos.s3.amazonaws.com/parker-klein-twos-notes-and-lists-screenshot-9.png',
    'https://twos.s3.amazonaws.com/parker-klein-twos-notes-and-lists-screenshot-10.png',
  ],
  androidUrl: 'https://play.google.com/store/apps/details?id=com.twosnativev3',
  iOSUrl: 'https://itunes.apple.com/us/app/twos-lists-and-notes/id1097350934?mt=8',
  logo: 'https://twos.s3.amazonaws.com/twos-thread-300.png',
  url: 'https://www.twosapp.com',
  linkedInLink: 'https://www.linkedin.com/company/17874767',
  facebookLink: 'https://www.facebook.com/twosapp/',
  twitterLink: 'https://twitter.com/twosapp',
  instagramLink: 'https://www.instagram.com/twosapp',
  youtubeLink: 'https://www.youtube.com/channel/UCCBTvOM09lHcYNRRgeyL2aA',
  path: '/apps/twos',
  tags: ['Apps'],
  tech: [
    'React',
    'React Native',
    'HTML',
    'CSS',
    'Express.js',
    'Node.js',
    'MongoDB',
    'Styled Components',
    'Heroku',
    'AWS',
    'Redux'
  ],
  future: 'UX improvements, bring back social sharing and collaboration, and expand note types.'
};

export default {
  category: 'book',
  name: 'Conscious',
  author: 'Annaka Harris',
  date: '5/25/20',
  path: '/notes/conscious',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-conscious-annaka-harris.jpg',
  tags: ['Science', 'Psychology', 'Philosophy'],
  time: 1,
  rating: 3.8,
  review:
    "Our consciousness makes up our experience. That is about all we know. Short book with a lot of questions and discussion about what we don't know",
  link: 'https://www.amazon.com/Conscious-Brief-Guide-Fundamental-Mystery/dp/0062906712',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
twosLink: '',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: ''
};

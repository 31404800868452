export default {
  author: 'Ichiro Kishimi',
  category: 'book',
  date: '6/8/19',
  link: 'https://amzn.to/2lxrjGs',
  name: 'The Courage To Be Disliked',
  path: '/notes/the-courage-to-be-disliked',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-courage-to-be-disliked-ichiro-kishimi.jpg',
  tags: ['Health', 'Philosophy'],
  time: 17,
  rating: 7.4,
  review:
    "Have the courage to be yourself. Don't worry about what other people think of you. Do what resonates and feels good."
};

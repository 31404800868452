export default {
  author: 'Dale Carnegie',
  category: 'book',
  date: '7/9/17',
  link: 'https://amzn.to/2lhTMQv',
  name: 'How to Win Friends and Influence People',
  path: '/notes/how-to-win-friends-and-influence-people',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-how-to-win-friends-and-influence-people-dale-carnegie.jpg',
  tags: ['Business', 'Self-help'],
  twosLink: 'https://www.TwosApp.com/5962fa820170d200044fa735',
  time: 27,
  rating: 9.1,
  review:
    "A classic and a must read. Learn how to be thoughtful and get people to like you. You'll communicate with people for effectively. Guarunteed to make your interactions with people more enjoyable."
};

export default {
  category: 'book',
  name: 'Infinite Jest',
  author: 'David Foster Wallace',
  date: '8/13/23',
  path: '/notes/infinite-jest',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-infinite-jest-david-foster-wallace.jpg',
  tags: ['Fiction', 'Comedy'],
  time: 5,
  rating: 6.4,
  review: '',
  link: 'https://www.amazon.com/Infinite-Jest-David-Foster-Wallace/dp/0316066524',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  twosLink: 'https://www.twosapp.com/5e1d44788d278c95585c0d96',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};

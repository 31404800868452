export default {
  author: 'Sam Harris',
  category: 'book',
  date: '8/18/19',
  link: 'https://www.amazon.com/Waking-Up-Spirituality-Without-Religion/dp/1451636024',
  name: 'Waking Up',
  path: '/notes/waking-up',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-waking-up-sam-harris.jpg',
  tags: ['Spirituality', 'Religion', 'Philosophy', 'Psychedelics'],
  time: 20,
  rating: 7.7,
  review:
    'Waking up to reality and the nature of the world. Allowing things to be and loving it. Helps bring you peace and accept yourself. Makes life easier. Psychedelics help.'
};

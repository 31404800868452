export default {
  author: 'Benjamin Hoff',
  category: 'book',
  date: '10/11/18',
  link: 'https://amzn.to/2lo8fKy',
  name: 'The Tao of Pooh',
  path: '/notes/the-tao-of-pooh',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-tao-of-pooh-benjamin-hoff.jpg',
  tags: ['Fiction', 'Religion', 'Taoism'],
  time: 26,
  rating: 8.4,
  review:
    'Explaining philosophy with the characters of Winnie the Pooh. Explains the basics of Taoism. Be still, see what is, it is what it is. Now work with that.'
};

export default {
  category: 'book',
  name: "Can't Hurt Me",
  author: 'David Goggins',
  date: '12/15/19',
  path: '/notes/cant-hurt-me',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-cant-hurt-me-david-goggins.jpg',
  tags: ['Psychology', 'Self-help', 'Health', 'Autobiography'],
  time: 2,
  rating: 7.3,
  review:
    'David Goggins is the toughest human on the planet. Dude is a savage and will make you feel like a baby. He tells the story of his life and teaches you to lean into the pain.',
  link: 'https://www.amazon.com/Cant-Hurt-Me-Master-Your/dp/1544512287',
  mediumLink: '',
  patreonLink: '',
  podcastLink: 'https://anchor.fm/parkerklein/episodes/Cant-Hurt-Me-by-David-Goggins-ebul1k',
  podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/Cant-Hurt-Me-by-David-Goggins-ebul1k',
  youtubeLink: 'https://youtu.be/Oek2OOUBFNc',
  youtubeEmbed: 'https://www.youtube.com/embed/Oek2OOUBFNc',
  releaseDate: '4/6/20'
};

export default {
  category: 'book',
  name: 'Raving Fans!',
  author: 'Ken Blanchard',
  date: '9/14/22',
  path: '/notes/raving-fans',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-raving-fans-ken-blanchard.jpg',
  tags: ['Business', 'Marketing', 'Money', 'Management', 'Leadership'],
  time: 7,
  rating: 7.7,
  review: '',
  link: 'https://www.amazon.com/Raving-Fans-Revolutionary-Approach-Customer/dp/0688123163/ref=tmm_hrd_swatch_0?_encoding=UTF8&qid=&sr=',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  twosLink: 'https://www.TwosApp.com/631a8499eb14ac5f6c575cbf',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};

export default {
  category: 'book',
  name: 'Peace Is Every Step',
  author: 'Thich Nhat Hanh',
  date: '4/27/24',
  path: '/notes/peace-is-every-step',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-peace-is-every-step-thich-nhat-hanh.jpg',
  tags: ['Buddhism', 'Spirituality', 'Religion', 'Meditation', 'Self-help'],
  time: 46,
  rating: 8.6,
  review: '',
  link: 'https://www.amazon.com/Peace-Every-Step-Mindfulness-Everyday/dp/0553351397',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  twosLink: 'https://www.TwosApp.com/6625aa9f19e9dd09b95888f1',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};

export default {
  category: 'book',
  name: 'The First and Last Freedom',
  author: 'Jiddu Krishnamurti',
  date: '2/27/21',
  path: '/notes/the-first-and-last-freedom',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-first-and-last-freedom-jiddu-krishnamurti.jpg',
  tags: ['Spirituality', 'Philosophy'],
  time: 73,
  rating: 9.4,
  review: '',
  link: 'https://www.amazon.com/First-Last-Freedom-J-Krishnamurti/dp/0060648317',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
twosLink: 'https://www.TwosApp.com/6009e0a66f221a0fb7cbcfb7',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: ''
};

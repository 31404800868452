export default {
  author: 'Seneca',
  category: 'book',
  date: '4/27/18',
  link: 'https://amzn.to/2lwIuYI',
  name: 'On the Shortness of Life',
  path: '/notes/on-the-shortness-of-life',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-on-the-shortness-of-life-seneca.jpg',
  tags: ['Philosophy', 'Stoicism'],
  time: 7,
  mediumLink: 'https://parkerklein.medium.com/on-the-shortness-of-life-life-is-long-if-you-know-how-to-use-it-by-lucius-annaeus-seneca-bbee8bef26c7',
  twosLink: 'https://www.twosapp.com/5ae3b9c2dcb9010004533d96',
  patreonLink: '',
  podcastLink: 'https://anchor.fm/parkerklein/episodes/On-the-Shortness-of-Life-by-Seneca-e5ilt3',
  podcastEmbed:
    'https://anchor.fm/parkerklein/embed/episodes/On-the-Shortness-of-Life-by-Seneca-e5ilt3',
  youtubeLink: 'https://youtu.be/-gB0Hy7iUGE',
  youtubeEmbed: 'https://www.youtube.com/embed/-gB0Hy7iUGE',
  releaseDate: '9/25/19',
  rating: 8.6,
  review:
    'A great reminder of how long life can be if you live with intent. Focus on the things within your power.'
};

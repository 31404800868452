export default {
  category: 'write',
  date: '10/12/19',
  name: 'You are you because of you',
  path: '/write/you-are-you-because-of-you',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-you-are-you-because-of-you.jpg',
  podcastLink: '',
  youtubeLink: '',
twosLink: '',
  mediumLink: '',
  patreonLink: '',
  tags: ['Write'],
  time: 1
};

export default {
  category: 'book',
  name: 'Exit Right',
  author: 'Mark Achler',
  date: '5/21/22',
  path: '/notes/exit-right',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-exit-right-mark-achler.jpg',
  tags: ['Business', 'Money', 'Entrepreneurship'],
  time: 7,
  rating: 7.6,
  review: '',
  link: 'https://www.amazon.com/Exit-Right-Startup-Maximize-Return-ebook/dp/B09PRH3VF2',
  mediumLink: 'https://parkerklein.medium.com/exit-right-by-mark-achler-mert-iseri-188157040a2a',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
twosLink: 'https://www.TwosApp.com/6254d8042701cd589da11b8c',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};

export default {
  category: 'write',
  date: '10/1/19',
  name: 'Read This If You Are Stuck',
  path: '/write/read-this-if-you-are-stuck',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-read-this-if-you-are-stuck.jpg',
  podcastLink: '',
  youtubeLink: '',
twosLink: '',
  mediumLink: '',
  patreonLink: '',
  tags: ['Write'],
  time: 1
};

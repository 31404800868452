export default {
  category: 'book',
  name: 'Secret Sauce',
  author: 'Austen Allred',
  date: '10/23/22',
  path: '/notes/secret-sauce',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-secret-sauce-austen-allred.jpg',
  tags: ['Marketing', 'Growth Hacking', 'Sales'],
  time: 30,
  rating: 8.6,
  review: '',
  link: 'https://www.amazon.com/Secret-Sauce-Ultimate-Growth-Hacking/dp/1635877393',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  twosLink: 'https://www.twosapp.com/634697397045872cfea64a4d',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};

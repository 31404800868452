export default {
  author: 'Stephen Batchelor',
  category: 'book',
  date: '3/27/17',
  link: 'https://amzn.to/2jKExz1',
  name: 'Buddhism Without Beliefs',
  path: '/notes/buddhism-without-beliefs',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-buddhism-without-beliefs-stephen-batchelor.jpg',
  tags: ['Religion', 'Spirituality', 'Buddhism'],
  youtubeLink: 'https://youtu.be/a8j2CqSR5-E',
  podcastLink:
    'https://anchor.fm/parkerklein/episodes/Buddhism-Without-Beliefs-by-Stephen-Batchelor-e5a6i7',
  podcastEmbed:
    'https://anchor.fm/parkerklein/embed/episodes/Buddhism-Without-Beliefs-by-Stephen-Batchelor-e5a6i7',
  patreonLink: 'https://www.patreon.com/posts/29833713',
  mediumLink:
    'https://parkerklein.medium.com/buddhism-without-beliefs-by-stephen-batchelor-cf4dd476513',
  youtubeEmbed: 'https://www.youtube.com/embed/a8j2CqSR5-E',
  releaseDate: '9/9/19',
  time: 5,
  rating: 6.3,
  review:
    'Speaks on the topics of awareness, mindfulness, and compassion. Teaches you how to be less judgemental and live in accordance with the natural flow of things.'
};

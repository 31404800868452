export default {
  category: 'write',
  date: '2/16/20',
  name: 'Born Again',
  path: '/write/born-again',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-born-again.jpg',
  podcastLink: '',
  youtubeLink: '',
twosLink: '',
  mediumLink: 'https://parkerklein.medium.com/born-again-db0f7917ea25',
  patreonLink: '',
  tags: ['Write'],
  time: 1
};

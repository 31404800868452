export default {
  category: 'write',
  date: '9/29/19',
  name: 'Favorite',
  path: '/write/favorite',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-favorite.jpg',
  podcastLink: '',
  youtubeLink: '',
twosLink: '',
  mediumLink: 'https://parkerklein.medium.com/favorite-7e689301241a',
  patreonLink: '',
  tags: ['Write'],
  time: 1
};

export default {
  author: 'Stoyan Stefanov',
  category: 'book',
  date: '8/29/17',
  link:
    'https://www.amazon.com/Object-oriented-JavaScript-Powerful-Approach-Development-ebook/dp/B00E7NCAYA',
  mediumLink: '',
  name: 'Object-Oriented JavaScript',
  path: '/notes/object-oriented-javascript',
  patreonLink: '',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-object-oriented-javascript-stoyan-stefanov.jpg',
  podcastLink: '',
  podcastEmbed: '',
  tags: ['Programming', 'JavaScript'],
  time: 2,
  youtubeLink: '',
twosLink: '',
  youtubeEmbed: '',
  releaseDate: '',
  rating: 5.4,
  review: 'Basic intro to object-oriented programming and concepts with JavaScript.'
};

export default {
  author: 'Hermann Hesse',
  category: 'book',
  date: '7/17/17',
  link: 'https://amzn.to/2k0hksO',
  name: 'Siddhartha',
  path: '/notes/siddhartha',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-siddartha-hermann-hesse.jpg',
  tags: ['Religion', 'Spirituality', 'Fiction'],
  time: 9,
  rating: 8.2,
  review:
    'A story about the buddha and his path to enlightenment. Wherever we go, there we are. Being at peace with ourselves and our actions is the way.',
  mediumLink: '',
  patreonLink: '',
  podcastLink: 'https://anchor.fm/parkerklein/episodes/Siddhartha-by-Hermann-Hesse-eaafp8',
  podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/Siddhartha-by-Hermann-Hesse-eaafp8',
  youtubeLink: 'https://youtu.be/jByku74ymOo',
  youtubeEmbed: 'https://www.youtube.com/embed/jByku74ymOo',
  releaseDate: '1/20/20'
};

export default {
  category: 'book',
  name: 'The Old Man and the Sea',
  author: 'Ernest Hemingway',
  date: '3/5/24',
  path: '/notes/the-old-man-and-the-sea',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-old-man-and-the-sea-ernest-hemingway.jpg',
  tags: ['Fiction'],
  time: 1,
  rating: 7.6,
  review: '',
  link: 'https://www.amazon.com/Old-Man-Sea-Ernest-Hemingway/dp/0684801221/ref=asc_df_0684801221/?tag=hyprod-20&linkCode=df0&hvadid=312057607832&hvpos&hvnetw=g&hvrand=9893911707003189277&hvpone&hvptwo&hvqmt&hvdev=c&hvdvcmdl&hvlocint&hvlocphy=9012094&hvtargid=pla-333774780623&psc=1&mcid=9d2c1ca3f3c03f1fa0f79b14b2db3532',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  twosLink: 'https://www.twosapp.com/65dbf6757352cf5c36b53142',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};

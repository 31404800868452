export default {
  author: 'Jake Heilbrunn',
  category: 'book',
  date: '10/2/16',
  name: 'Off The Beaten Trail',
  path: '/notes/off-the-beaten-trail',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-off-the-beaten-trail-jake-heilbrunn.jpg',
  tags: ['Biography', 'Travel'],
  time: 0,
  link: 'https://www.amazon.com/Off-Beaten-Trail-Soul-Searching-Journey/dp/0997761202',
  mediumLink: '',
  patreonLink: '',
  podcastLink: '',
  podcastEmbed: '',
  youtubeLink: '',
twosLink: '',
  youtubeEmbed: '',
  releaseDate: '',
  rating: 4.6,
  review:
    'Story of my friend Jake who left the common path of college followed by a 9-5 job to travel and volunteer.'
};

export default {
  author: 'Mo Gawdat',
  category: 'book',
  date: '4/16/17',
  link: 'https://amzn.to/2lxQ8Cb',
  name: 'Solve For Happy',
  path: '/notes/solve-for-happy',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-solve-for-happy-mo-gawdat.jpg',
  tags: ['Self-help'],
  time: 2,
  mediumLink: '',
  patreonLink: '',
  podcastLink: 'https://anchor.fm/parkerklein/episodes/Solve-For-Happy-by-Mo-Gawdat-e7ku6n',
  podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/Solve-For-Happy-by-Mo-Gawdat-e7ku6n',
  youtubeLink: 'https://youtu.be/mM_scodfl6Y',
  youtubeEmbed: 'https://www.youtube.com/embed/mM_scodfl6Y',
  releaseDate: '10/13/19',
  rating: 6.8,
  review:
    'A book about happiness and an equation for happiness. Happiness = reality - expectations. Expect less and accept reality.'
};

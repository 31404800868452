import PropTypes from 'prop-types';
import React from 'react';
import { FaAmazon, FaMedium, FaPatreon, FaPodcast, FaYoutube } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import EmptyMessage from '../components/EmptyMessage';
import Helmet from '../components/Helmet';
import Search from '../components/Search';
import { writings } from '../data';
import { filterData, sortDate } from '../helpers';

const WritingsPage = ({ search }) => {
  const writingCollection = [];
  writings.map(writing => writingCollection.push(`${writing.name}`));

  let filteredWritings = filterData(writings, search);
  filteredWritings = filteredWritings.sort(sortDate);

  return (
    <main className="main-content">
      <ReactTooltip effect="solid" />
      <Helmet
        description={`Parker Klein's collection of writings. Writings on ${writingCollection.join(
          ', '
        )}`}
        title="Write"
      />
      {/* <h2 className="title">Wrote</h2> */}
      <Search noFilters />
      <p className="note">
        I write in{' '}
        <a
          aria-label="Parker on Twos"
          className="note-link"
          href="https://www.twosapp.com/@parker"
          id="Parker"
          rel="noopener noreferrer"
          target="_blank"
          title="Parker on Twos"
        >
          Twos
        </a> and post my blogs on{' '}
        <a
          aria-label="Parker Klein on Medium"
          className="note-link"
          href="https://medium.com/@parkerklein"
          id="Medium"
          rel="noopener noreferrer"
          target="_blank"
          title="Parker Klein on Medium"
        >
          Medium
        </a> now.
      </p>
      <ul aria-labelledby="write-header">
        {filteredWritings.length === 0 && <EmptyMessage type="writings" />}
        {filteredWritings.map(write => (
          <li
            className="list-item row"
            key={write.path}
            itemScope
            itemType="http://schema.org/Blog"
          >
            {write.photo && (
              <Link
                className="photo"
                aria-label={`${write.name} by Parker Klein`}
                to={write.path}
                tabIndex={-1}
                title={`${write.name} by Parker Klein`}
              >
                <img
                  alt={`${write.name} by Parker Klein`}
                  itemProp="image"
                  src={write.photo}
                  height={100}
                  width={100}
                />
              </Link>
            )}
            <div className="column">
              <Link
                aria-label={`${write.name} by Parker Klein`}
                to={write.path}
                title={`${write.name} by Parker Klein`}
              >
                <h2 className="title" itemProp="name">
                  {write.name}
                </h2>
                <p className="date" itemProp="dateCreated">
                  {write.date}
                </p>
                <meta itemProp="author" content="Parker Klein" />
                <meta itemProp="url" content={`https://www.parkerklein.com${write.path}`} />
              </Link>
              <div className="row links">
                {write.twosLink && (
                  <a
                    aria-label={`Read ${write.name} by Parker Klein on Twos`}
                    className="icon-link twos"
                    data-tip={`${write.name} by Parker Klein on Twos`}
                    href={write.twosLink}
                    itemProp="url"
                    rel="noopener noreferrer"
                    target="_blank"
                    title={`Read ${write.name} by Parker Klein on Twos`}
                  >
                    <img
                      alt="Twos: days, lists, & things"
                      src="https://twos.s3.amazonaws.com/twos-thread-background-transparent-300.png"
                      height="28px"
                      width="20px"
                    />
                  </a>
                )}
                {write.youtubeLink && (
                  <a
                    aria-label={`Watch ${write.name} by Parker Klein on YouTube`}
                    className="icon-link youtube"
                    data-tip={`Watch ${write.name} on YouTube`}
                    href={write.youtubeLink}
                    itemProp="url"
                    rel="noopener noreferrer"
                    target="_blank"
                    title={`Watch ${write.name} by Parker Klein on YouTube`}
                  >
                    <FaYoutube />
                  </a>
                )}
                {write.patreonLink && (
                  <a
                    aria-label={`Support ${write.name} by Parker Klein on Patreon`}
                    className="icon-link patreon"
                    data-tip={`${write.name} on Patreon`}
                    href={write.patreonLink}
                    itemProp="url"
                    rel="noopener noreferrer"
                    target="_blank"
                    title={`Support ${write.name} by Parker Klein on Patreon`}
                  >
                    <FaPatreon />
                  </a>
                )}
                {write.link && (
                  <a
                    aria-label={`Buy ${write.name} by Parker Klein on Amazon`}
                    className="icon-link amazon"
                    data-tip={`Buy ${write.name} on Amazon`}
                    href={write.link}
                    itemProp="url"
                    rel="noopener noreferrer"
                    target="_blank"
                    title={`Buy ${write.name} by Parker Klein on Amazon`}
                  >
                    <FaAmazon />
                  </a>
                )}
                {write.podcastLink && (
                  <a
                    aria-label={`Listen to ${write.name} by Parker Klein`}
                    className="icon-link podcast"
                    data-tip={`Listen to ${write.name}`}
                    href={write.podcastLink}
                    itemProp="url"
                    rel="noopener noreferrer"
                    target="_blank"
                    title={`Listen to ${write.name} by Parker Klein`}
                  >
                    <FaPodcast />
                  </a>
                )}
                {write.mediumLink && (
                  <a
                    aria-label={`Read ${write.name} by Parker Klein on Medium`}
                    className="icon-link medium"
                    data-tip={`Read ${write.name} on Medium`}
                    href={write.mediumLink}
                    itemProp="url"
                    rel="noopener noreferrer"
                    target="_blank"
                    title={`Read ${write.name} by Parker Klein on Medium`}
                  >
                    <FaMedium />
                  </a>
                )}
              </div>
            </div>
          </li>
        ))}
      </ul>
    </main>
  );
};

WritingsPage.propTypes = {
  search: PropTypes.string.isRequired
};

const mapStateToProps = ({ filters: { search, sort, tag } }) => ({ search });

export default connect(mapStateToProps)(WritingsPage);

export default {
  category: 'write',
  date: '12/2/21',
  name: 'Why I Left My Dream Job at Google for Twos',
  path: '/write/why-i-left-my-dream-job-at-google-for-twos',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-why-i-left-my-dream-job-at-google-for-twos.jpg',
  podcastLink: '',
  youtubeLink: 'https://www.youtube.com/watch?v=y1qDKrAFEnw',
  mediumLink: 'https://medium.com/twosapp/why-i-left-my-dream-job-at-google-for-twos-b098eef26c53',
  patreonLink: '',
  tags: ['Write'],
  time: 2
};

export default {
  category: 'write',
  date: '4/10/20',
  name: 'The Optimal State',
  path: '/write/the-optimal-state',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-optimal-state.jpg',
  podcastLink: '',
  youtubeLink: '',
twosLink: '',
  mediumLink: '',
  patreonLink: '',
  tags: ['Write'],
  time: 1
};

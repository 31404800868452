export default {
  author: 'Steve McConnell',
  category: 'book',
  date: '8/20/17',
  name: 'Code Complete',
  path: '/notes/code-complete',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-code-complete-steve-mcconnell.jpg',
  tags: ['Software Development'],
  time: 0,
  link: 'https://www.amazon.com/Code-Complete-Practical-Handbook-Construction/dp/0735619670',
  mediumLink: '',
  patreonLink: '',
  podcastLink: '',
  podcastEmbed: '',
  youtubeLink: '',
twosLink: '',
  youtubeEmbed: '',
  releaseDate: '',
  rating: 6.5,
  review:
    'Goes through a ton of the basics of programming and helps you wrap your head around the main programming concepts. Worth a read for anyone starting out. Lots of timeless knowledge.'
};

export default {
  author: 'Kenya Hara',
  category: 'book',
  date: '12/29/18',
  link: 'https://amzn.to/2xQlh6O',
  name: 'White',
  path: '/notes/white',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-white-kenya-hara.jpg',
  tags: ['Design', 'Philosophy'],
  time: 5,
  rating: 6.1,
  review:
    'A book about space, silence, simplicity, and beauty. An enjoyable read that brings you peace and appreciation for elegance.'
};

export default {
  author: 'John Wooden',
  category: 'book',
  date: '7/8/16',
  link: 'https://amzn.to/2k2XrRT',
  name: 'Wooden',
  path: '/notes/wooden',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-wooden-john-wooden.jpg',
  tags: ['Sports', 'Biography', 'Psychology'],
  time: 14,
  rating: 9.2,
  review:
    'One of, if not the, greatest coaches of all time. Do everything right and you have nothing to worry about. Hold yourself to a high standard and execcute. Do your best. Do it all the time. Find the joy in that.',
  mediumLink: '',
  patreonLink: '',
  podcastLink: 'https://anchor.fm/parkerklein/episodes/Wooden-by-John-Wodden-eafjdt',
  podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/Wooden-by-John-Wodden-eafjdt',
  youtubeLink: 'https://youtu.be/6cQQxJj6C8Q',
  youtubeEmbed: 'https://www.youtube.com/embed/6cQQxJj6C8Q',
  releaseDate: '1/27/20'
};

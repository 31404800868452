export default {
  category: 'write',
  date: '2/26/20',
  name: 'You can learn anything',
  path: '/write/you-can-learn-anything',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-you-can-learn-anything.jpg',
  podcastLink: '',
  youtubeLink: '',
twosLink: '',
  mediumLink: '',
  patreonLink: '',
  tags: ['Write'],
  time: 1
};

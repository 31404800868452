export default {
  author: 'Tony Robbins',
  category: 'book',
  date: '8/3/17',
  link: 'https://amzn.to/2JwIvp0',
  name: 'Awaken the Giant Within',
  path: '/notes/awaken-the-giant-within',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-awaken-the-giant-within-tony-robbins.jpg',
  tags: ['Business', 'Self-help'],
  time: 9,
  mediumLink: '',
  patreonLink: '',
  podcastLink:
    'https://anchor.fm/parkerklein/episodes/Awaken-the-Giant-Within-by-Tony-Robbins-e8uq36',
  podcastEmbed:
    'https://anchor.fm/parkerklein/embed/episodes/Awaken-the-Giant-Within-by-Tony-Robbins-e8uq36',
  youtubeLink: 'https://youtu.be/34jisLuloh0',
  youtubeEmbed: 'https://www.youtube.com/embed/34jisLuloh0',
  releaseDate: '11/11/19',
  rating: 5.5,
  review:
    'Was a little underwhelmed with this book given all the hype around Tony Robbins. A few really solid takeaways around activities we associate pain and pleasure with and the control we have over that in order to make different actions more habitual and likely. Great book to help you become more intentional and take action.'
};

export default {
  category: 'book',
  name: 'Lone Survivor',
  author: 'Marcus Luttrell',
  date: '12/23/14',
  path: '/notes/lone-survivor',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-lone-survivor-marcus-luttrell.jpg',
  tags: ['Biography', 'Leadership', 'History'],
  time: 0,
  rating: 7.8,
  review:
    "Incredible, true story about SEAL team 10's mission in Afghanistan. Everything goes wrong and the author, Marcus Luttrell, is left wounded and has to survive on his own. This book got me into reading and is why my phone is still on military time.",
  link: 'https://www.amazon.com/Lone-Survivor-Eyewitness-Account-Operation/dp/0316067598',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
twosLink: '',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: ''
};

export default {
  author: 'Marijn Haverbeke',
  category: 'book',
  date: '8/29/17',
  link:
    'https://www.amazon.com/Eloquent-JavaScript-3rd-Introduction-Programming/dp/1593279507/ref=pd_lpo_sbs_14_img_0?_encoding=UTF8&psc=1&refRID=G2WSBERHYTEH2FCBK4KN',
  name: 'Eloquent JavaScript',
  path: '/notes/eloquent-javascript',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-eloquent-javascript-marijn-haverbeke.jpg',
  tags: ['Web Development', 'Programming', 'JavaScript'],
  time: 7,
  rating: 7.4,
  review:
    'Great book for anyone learning or wanting to learn JavaScript. Even if you already know JavaScript, you will find something new in this book. Fun and useful read.'
};

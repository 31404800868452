export default {
  category: 'book',
  name: 'Design Patterns by Tutorials',
  author: 'Joshua Greene',
  date: '10/25/20',
  path: '/notes/design-patterns-by-tutorials',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-design-patterns-by-tutorials-joshua-greene.jpg',
  tags: ['Programming', 'Swift'],
  time: 8,
  rating: 5.4,
  review: '',
  link: 'https://www.amazon.com/Design-Patterns-Tutorials-Third-Learning/dp/1950325059',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
twosLink: '',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: ''
};

export default {
  category: 'book',
  name: 'The Art of the Deal',
  author: 'Donald Trump',
  date: '5/25/23',
  path: '/notes/the-art-of-the-deal',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-art-of-the-deal-donald-trump.jpg',
  tags: ['Biography', 'Leadership', 'Money', ],
  time: 3,
  rating: 3.8,
  review: '',
  link: 'https://www.amazon.com/Trump-Art-Deal-Donald-J/dp/0399594493',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  twosLink: 'https://www.TwosApp.com/6451bac3249ee18b5086d3b5',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};

export default {
  category: 'book',
  name: 'Home is Where We Start From',
  author: 'D.W. Winnicott',
  date: '10/16/23',
  path: '/notes/home-is-where-we-start-from',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-home-is-where-we-start-from-d-w-winnicott.jpg',
  tags: ['Psychology', 'Psychoanalysis', 'Parenting'],
  time: 8,
  rating: 6.5,
  review: '',
  link: 'https://www.amazon.com/Home-Where-We-Start-Psychoanalyst/dp/0393306674',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  twosLink: 'https://www.TwosApp.com/651f5001af41fbee4a16c3e6',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};

export default {
  author: 'Yuval Noah Harari',
  category: 'book',
  date: '6/23/17',
  link: 'https://amzn.to/2k2TLiS',
  name: 'Sapiens',
  path: '/notes/sapiens',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-sapiens-yuval-noah-harari.jpg',
  tags: ['History'],
  time: 35,
  rating: 9.2,
  review:
    'A book about us. Our evolution. How we became who we are and where we are going. Why are things the way they are set up. Why do we do the things we do. A lot of theory in this book, but it all makes incredible sense and will make you think differently about the world and the structures we have in place.'
};

import React, { useState } from 'react';
import { FaAmazon, FaMedium, FaPatreon, FaPodcast, FaYoutube } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import Helmet from '../components/Helmet';
import { podcasts } from '../data';
import { sortReleaseDate } from '../helpers';

const PodcastPage = () => {
  const podcastsCollection = [];
  podcasts.map(podcast => podcastsCollection.push(`${podcast.name}`));

  const filteredPodcasts = podcasts.sort(sortReleaseDate);

  const [show, setShow] = useState(0);

  return (
    <main className="main-content">
      <ReactTooltip effect="solid" />
      <Helmet
        description={`Parker Klein's Notes podcast episodes. Podcasts on ${podcastsCollection.join(
          ', '
        )}`}
        title="Podcast"
      />
      {/* <h2 className="title">Podcast</h2> */}
      <ul aria-labelledby="write-header">
        {filteredPodcasts.map((podcast, index) => (
          <li
            className="list-item column"
            key={podcast.path}
            itemScope
            itemType="http://schema.org/Book"
          >
            {index <= show ? (
              <iframe
                title={podcast.name}
                src={podcast.podcastEmbed}
                height="102px"
                width="343px"
                frameBorder="0"
                scrolling="no"
                onLoad={() => {
                  if (show < filteredPodcasts.length) setShow(show + 1);
                }}
              />
            ) : (
              <div style={{ height: 118, width: 343 }} />
            )}
            <Link
              aria-label={`${podcast.name} by Parker Klein`}
              to={podcast.path}
              title={`${podcast.name} by Parker Klein`}
            >
              <h2 className="title" itemProp="name">
                {podcast.name}
              </h2>
              <h3 className="author" itemProp="author">
                {podcast.author}
              </h3>
              <div className="date">{podcast.releaseDate}</div>
              <meta itemProp="url" content={`https://www.parkerklein.com${podcast.path}`} />
            </Link>
            <div className="row">
              {podcast.twosLink && (
                <a
                  aria-label={`Read ${podcast.name} by ${podcast.author} on Twos`}
                  className="icon-link twos"
                  data-tip={`${podcast.name} on Twos`}
                  href={podcast.twosLink}
                  itemProp="url"
                  rel="noopener noreferrer"
                  target="_blank"
                  title={`Read ${podcast.name} by ${podcast.author} on Twos`}
                >
                  <img
                    alt="Twos: days, lists, & things"
                    src="https://twos.s3.amazonaws.com/twos-thread-background-transparent-300.png"
                    height="28px"
                    width="20px"
                  />
                </a>
              )}
              {podcast.youtubeLink && (
                <a
                  aria-label={`Watch Parker Klein's Notes on ${podcast.name} by ${podcast.author} on YouTube`}
                  className="icon-link youtube"
                  data-tip={`Watch ${podcast.name} on YouTube`}
                  href={podcast.youtubeLink}
                  itemProp="url"
                  rel="noopener noreferrer"
                  target="_blank"
                  title={`Watch Parker Klein's Notes on ${podcast.name} by ${podcast.author} on YouTube`}
                >
                  <FaYoutube />
                </a>
              )}
              {podcast.patreonLink && (
                <a
                  aria-label={`Support Parker Klein's notes on ${podcast.name} by ${podcast.author} on Patreon`}
                  className="icon-link patreon"
                  data-tip={`${podcast.name} on Patreon`}
                  href={podcast.patreonLink}
                  itemProp="url"
                  rel="noopener noreferrer"
                  target="_blank"
                  title={`Support Parker Klein's notes on ${podcast.name} by ${podcast.author} on Patreon`}
                >
                  <FaPatreon />
                </a>
              )}
              {podcast.link && (
                <a
                  aria-label={`Buy ${podcast.name} by ${podcast.author} on Amazon`}
                  className="icon-link amazon"
                  data-tip={`Buy ${podcast.name} on Amazon`}
                  href={podcast.link}
                  itemProp="url"
                  rel="noopener noreferrer"
                  target="_blank"
                  title={`Buy ${podcast.name} by ${podcast.author} on Amazon`}
                >
                  <FaAmazon />
                </a>
              )}
              {podcast.podcastLink && (
                <a
                  aria-label={`Listen to Parker Klein's Notes podcast on ${podcast.name} by ${podcast.author}`}
                  className="icon-link podcast"
                  data-tip={`Listen to ${podcast.name}`}
                  href={podcast.podcastLink}
                  itemProp="url"
                  rel="noopener noreferrer"
                  target="_blank"
                  title={`Listen to Parker Klein's Notes podcast on ${podcast.name} by ${podcast.author}`}
                >
                  <FaPodcast />
                </a>
              )}
              {podcast.mediumLink && (
                <a
                  aria-label={`Read Parker Klein's notes on ${podcast.name} by ${podcast.author} on Medium`}
                  className="icon-link medium"
                  data-tip={`Read ${podcast.name} on Medium`}
                  href={podcast.mediumLink}
                  itemProp="url"
                  rel="noopener noreferrer"
                  target="_blank"
                  title={`Read Parker Klein's notes on ${podcast.name} by ${podcast.author} on Medium`}
                >
                  <FaMedium />
                </a>
              )}
            </div>
          </li>
        ))}
      </ul>
    </main>
  );
};

export default PodcastPage;

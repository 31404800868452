export default {
  author: 'Dan Harris',
  category: 'book',
  // date: '10/24/15',
  date: '6/18/20',
  name: '10% Happier',
  path: '/notes/ten-percent-happier',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-10-happier-dan-harris.jpg',
  tags: ['Self-help', 'Meditation', 'Memoir'],
  time: 2,
  link: 'https://www.amazon.com/10-Happier-Self-Help-Actually-Works/dp/0062265431',
  mediumLink: '',
  patreonLink: '',
  podcastLink: 'https://anchor.fm/parkerklein/episodes/10-Happier-by-Dan-Harris-egdt6v',
  podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/10-Happier-by-Dan-Harris-egdt6v',
  youtubeLink: 'https://youtu.be/pJGgHeZ7MYA',
  youtubeEmbed: 'https://www.youtube.com/embed/pJGgHeZ7MYA',
  releaseDate: '7/13/20',
  rating: 8.9,
  review: 'A great introduction to meditation and its benefits. The story of Dan Harris.'
};

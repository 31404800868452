export default {
  author: 'Tony Hsieh',
  category: 'book',
  date: '5/14/17',
  link: 'https://amzn.to/2jLJtUk',
  name: 'Delivering Happiness',
  path: '/notes/delivering-happiness',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-delivering-happiness-tony-hsieh.jpg',
  tags: ['Business', 'Management', 'Leadership'],
  time: 8,
  rating: 6.7,
  review:
    'The story of Zappos and what their CEO Tony Hsieh learned along the way. Lots of references to poker. Talks about what is valuable and determines success.',
  mediumLink: 'https://parkerklein.medium.com/delivering-happiness-by-tony-hsieh-49e1051f07f4',
  patreonLink: '',
  podcastLink: 'https://anchor.fm/parkerklein/episodes/Delivering-Happiness-by-Tony-Hsieh-e9odvr',
  podcastEmbed:
    'https://anchor.fm/parkerklein/embed/episodes/Delivering-Happiness-by-Tony-Hsieh-e9odvr',
  youtubeLink: 'https://youtu.be/yZuAHhAZTXc',
  youtubeEmbed: 'https://www.youtube.com/embed/yZuAHhAZTXc',
  twosLink: 'https://www.TwosApp.com/5918b951d90a140400a285dc',
  releaseDate: '12/23/19'
};

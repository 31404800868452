export default {
  author: 'Ray Dalio',
  category: 'book',
  date: '6/26/17',
  link: 'https://amzn.to/2lvQl8W',
  name: 'Principles',
  path: '/notes/principles',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-principles-ray-dalio.jpg',
  tags: ['Business', 'Management', 'Leadership'],
  time: 22,
  rating: 7.3,
  review:
    'Big book of work and life principles. Principles to conduct your business and personal relationships by. Helps you make decisions.'
};

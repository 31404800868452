import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FiSend } from 'react-icons/fi';
import { connect } from 'react-redux';

import * as actions from '../actions';
import { checkEmail } from '../helpers';

class NewsForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      done: props.signedUp,
      loaded: false
    };
  }

  componentDidMount() {
    this.props.setError('');

    this.setState({ loaded: true });
  }

  render() {
    const { changeEmail, email, error, loading, setError, signedUp, signUp } = this.props;
    const { done, loaded } = this.state;

    return done || !loaded ? null : signedUp ? (
      <div className="side-menu-form">
        <p>Thank you for your interest :)</p>
      </div>
    ) : (
      <div className="side-menu-form">
        <form
          onSubmit={e => {
            e.preventDefault();

            if (!email) {
              setError('Please enter your email.');
              return;
            }

            if (!checkEmail(email)) {
              setError('Please enter a valid email.');
              return;
            }

            signUp(email);
          }}
        >
          <p className="label">Want updates?</p>
          {error && <div className="error">{error}</div>}
          <div className="row">
            <input
              aria-label="Search"
              className="search"
              onChange={e => {
                setError('');
                changeEmail(e.target.value);
              }}
              placeholder="Enter your email"
              value={email}
              type="email"
            />
            {email ? (
              loading ? (
                <p className="loading">Loading...</p>
              ) : (
                <button
                  aria-label="Send"
                  className="send-button"
                  onClick={() => {
                    if (!email) {
                      setError('Please enter your email.');
                      return;
                    }

                    if (!checkEmail(email)) {
                      setError('Please enter a valid email.');
                      return;
                    }

                    signUp(email);
                  }}
                  title="Send"
                >
                  <FiSend />
                </button>
              )
            ) : null}
          </div>
        </form>
      </div>
    );
  }
}

NewsForm.propTypes = {
  changeEmail: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  setError: PropTypes.func.isRequired,
  signedUp: PropTypes.bool.isRequired,
  signUp: PropTypes.func.isRequired
};

const mapStateToProps = ({ email, error, loading, sideMenu, signedUp }) => ({
  email,
  error,
  loading,
  sideMenu,
  signedUp
});

export default connect(mapStateToProps, { ...actions })(NewsForm);

export default {
  category: 'book',
  name: 'Shape Up',
  author: 'Ryan Singer',
  date: '3/13/24',
  path: '/notes/shape-up',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-shape-up-ryan-singer.jpg',
  tags: ['Business', 'Computer Science', 'Entrepreneurship', 'Engineering', 'Design', 'Management', 'Product', 'Programming', 'Product Design', 'Software Development'],
  time: 23,
  rating: 8.1,
  review: '',
  link: 'https://basecamp-goods.com/products/shapeup',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  twosLink: 'https://www.twosapp.com/65e51c6f44ad4ea80b352a02',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};

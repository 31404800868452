export default {
  author: 'Douglas Adams',
  category: 'book',
  date: '9/26/19',
  name: "The Hitchhiker's Guide to the Galaxy",
  path: '/notes/the-hitchhikers-guide-to-the-galaxy',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-hitchhikers-guide-to-the-galaxy-douglas-adams.jpg',
  tags: ['Fiction', 'Science Fiction'],
  time: 1,
  link: 'https://www.amazon.com/Hitchhikers-Guide-Galaxy-Douglas-Adams/dp/0345391802',
  mediumLink: '',
  patreonLink: '',
  podcastLink: '',
  podcastEmbed: '',
  youtubeLink: '',
twosLink: '',
  youtubeEmbed: '',
  releaseDate: '',
  rating: 4.9,
  review:
    'Book about the Earth getting destoryed and an earthlings adventure through the galaxy with his alien friend.'
};

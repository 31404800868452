export default {
  author: 'Ken Blanchard',
  category: 'book',
  date: '9/24/18',
  link: 'https://amzn.to/2k1hgsN',
  name: 'The One Minute Manager',
  path: '/notes/the-one-minute-manager',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-one-minute-manager-ken-blanchard.jpg',
  tags: ['Business', 'Entrepreneurship', 'Self-help'],
  time: 7,
  rating: 7.7,
  review:
    'A book on effective leadership and management. Allow your employees to come up with their work. Help them set a direction, but they can come up with what to do and how to do it.',
    mediumLink: '',
    patreonLink: '',
    podcastLink: 'https://anchor.fm/parkerklein/episodes/The-One-Minute-Manager-by-Ken-Blanchard-eeq0nl',
    podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/The-One-Minute-Manager-by-Ken-Blanchard-eeq0nl',
    youtubeLink: 'https://youtu.be/SuOtcn56oGg',
    youtubeEmbed: 'https://www.youtube.com/embed/SuOtcn56oGg',
    releaseDate: '6/15/20'
};

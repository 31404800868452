export default {
  category: 'book',
  name: 'A New Earth',
  author: 'Eckhart Tolle',
  date: '6/28/20',
  path: '/notes/a-new-earth',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-a-new-earth-eckhart-tolle.jpg',
  tags: ['Spirituality', 'Self-help'],
  time: 4,
  rating: 8.5,
  review: '',
  link: 'https://www.amazon.com/New-Earth-Awakening-Purpose-Selection/dp/0452289963',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
twosLink: '',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: ''
};

export default {
  category: 'book',
  name: 'Building A StoryBrand',
  author: 'Donald Miller',
  date: '12/10/22',
  path: '/notes/building-a-storybrand',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-building-a-storybrand-donald-miller.jpg',
  tags: ['Business', 'Marketing', 'Sales'],
  time: 1,
  rating: 8.8,
  review: '',
  link: 'https://www.amazon.com/Building-StoryBrand-Clarify-Message-Customers/dp/0718033329',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  twosLink: 'https://www.TwosApp.com/6370fc79b4a65be5dbf79ef1',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};

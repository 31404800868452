export default {
  author: 'Milan Kundera',
  category: 'book',
  date: '8/20/18',
  link: 'https://amzn.to/2k58qdB',
  name: 'The Unbearable Lightness of Being',
  path: '/notes/the-unbearable-lightness-of-being',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-unbearable-lightness-of-being.jpg',
  tags: ['Fiction'],
  time: 23,
  rating: 5.8,
  review:
    "Go with the way of the world. Don't make things harder than they need to be. This is the downfall of many people."
};

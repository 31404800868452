export default {
  category: 'book',
  name: 'Radical Hope',
  author: 'Jonathan Lear',
  date: '10/20/21',
  path: '/notes/radical-hope',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-radical-hope-jonathan-lear.jpg',
  tags: ['Politics', 'Social Science', 'Ethics'],
  time: 4,
  rating: 6.6,
  review: '',
  link: 'https://www.amazon.com/Radical-Hope-Ethics-Cultural-Devastation/dp/0674027469',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
twosLink: 'https://www.TwosApp.com/61639056f07b701f1f7120ec',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: ''
};

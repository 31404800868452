export default {
  author: 'Marty Cagan',
  category: 'book',
  date: '12/13/18',
  link: 'https://amzn.to/2l7zNnq',
  name: 'Inspired',
  path: '/notes/inspired',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-inspired-marty-cagan.jpg',
  tags: ['Product Design', 'Business', 'Management'],
  time: 11,
  rating: 7.2,
  review:
    'Required reading for people developing products. Gives you steps to follow for product development and important aspects to consider when creating a product and providing value to your customers.'
};

export default {
  category: 'book',
  name: 'Titles Ruin Everything',
  author: 'Aubrey Graham',
  date: '7/3/23',
  path: '/notes/titles-ruin-everything',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-titles-ruin-everything-aubrey-graham.jpg',
  tags: ['Poetry'],
  time: 5,
  rating: 7.9,
  review: '',
  link: 'https://drakerelated.com/products/titles-ruin-everything',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  twosLink: 'https://www.twosapp.com/64a60769763798494cf9e88f',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};

export default {
  category: 'write',
  date: '9/27/19',
  name: 'Solitude',
  path: '/write/solitude',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-solitude.png',
  podcastLink: '',
  youtubeLink: '',
twosLink: '',
  mediumLink: 'https://parkerklein.medium.com/solitude-267b657982fa',
  patreonLink: '',
  tags: ['Write'],
  time: 1
};

export default {
  category: 'book',
  name: 'Who',
  author: 'Geoff Smart',
  date: '6/27/22',
  path: '/notes/who',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-who-geoff-smart.jpg',
  tags: ['Management', 'Leadership', 'Business', 'Money'],
  time: 15,
  rating: 8.1,
  review: '',
  link: 'https://www.amazon.com/Who-Geoff-Smart/dp/0345504194',
  mediumLink: 'https://parkerklein.medium.com/who-by-geoff-smart-12e41c9105c7',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
twosLink: 'https://www.twosapp.com/62a0760dfe460c58cc6990d7',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};

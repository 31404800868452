export default {
  author: 'Urban Meyer',
  category: 'book',
  date: '7/10/16',
  link: 'https://amzn.to/2XOl92h',
  name: 'Above the Line',
  path: '/notes/above-the-line',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-above-the-line-urban-meyer.jpg',
  tags: ['Leadership'],
  time: 11,
  rating: 6.8,
  review:
    'This book was given to my brother by the company he works for, Southwest Capital. I was on vacation with him, picked it up, and knocked it out in a few days. Really good book in individual and team mentality. Focusing on what is in your control and giving it your effort regardless of the outcome.',
  mediumLink: '',
  patreonLink: '',
  podcastLink: 'https://anchor.fm/parkerklein/episodes/Above-The-Line-by-Urban-Meyer-eb239v',
  podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/Above-The-Line-by-Urban-Meyer-eb239v',
  youtubeLink: 'https://youtu.be/C9z59FhPXrw',
  youtubeEmbed: 'https://www.youtube.com/embed/C9z59FhPXrw',
  releaseDate: '2/24/20'
};

export default {
  category: 'book',
  name: 'The Third Door',
  author: 'Alex Banayan',
  date: '2/22/24',
  path: '/notes/the-third-door',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-third-door-alex-banayan.jpg',
  tags: ['Business', 'Leadership', 'Self-help', 'Memoir'],
  time: 27,
  rating: 8.2,
  review: '',
  link: 'https://www.amazon.com/Third-Door-Uncover-Successful-Launched/dp/0804136661',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  twosLink: 'https://www.TwosApp.com/65cabda596aa7486f06316df',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};

export default {
  author: 'John E. Sarno',
  category: 'book',
  date: '2/4/19',
  link: 'https://amzn.to/2lb8HM6',
  name: 'Healing Back Pain',
  path: '/notes/healing-back-pain',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-healing-back-pain-john-sarno.jpg',
  tags: ['Health', 'Medicine'],
  time: 8,
  rating: 6.8,
  twosLink: 'https://www.twosapp.com/5c58fc4bc89a0bcc8261e17b',
  mediumLink: 'https://medium.com/twosapp/healing-back-pain-by-john-e-sarno-ee6bdea2cc21',
  review:
    'A book about how our body reflects our internals. If you are stressed and worried, your body will show signs of pain. All pain in linked to an internal issue.'
};

export default {
  author: 'Jon Duckett',
  category: 'book',
  date: '4/10/18',
  link: 'https://amzn.to/2lgnLbn',
  name: 'HTML & CSS',
  path: '/notes/html-css',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-html-css-jon-duckett.jpg',
  tags: ['Web Design', 'Web Development', 'HTML', 'CSS'],
  time: 3,
  rating: 5.8,
  review:
    "Lays out the basics of HTML and CSS. If you're interested, and you've never read a book about them before, you'll be sure to learn something new. A nice refresher of the languages."
};

export default {
  category: 'book',
  name: 'The 48 Laws of Power',
  author: 'Robert Greene',
  date: '8/7/21',
  path: '/notes/the-48-laws-of-power',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-48-laws-of-power-robert-greene.jpg',
  tags: ['Politics', 'Leadership'],
  time: 47,
  rating: 8.1,
  review: '',
  link: 'https://www.amazon.com/48-Laws-Power-Robert-Greene/dp/0140280197',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
twosLink: 'https://www.TwosApp.com/602adedc19e9502dd7c1d698',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: ''
};

export default {
  category: 'book',
  name: 'Effective Objective-C 2.0',
  author: 'Matt Galloway',
  date: '3/18/20',
  path: '/notes/effective-objective-c-2-0',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-effective-objective-c-2-0-matt-galloway.jpg',
  tags: ['Programming', 'Objective-C'],
  time: 6,
  rating: 6.5,
  review:
    'Not my favorite programming book, but a quality book on objective-c and some best practices to follow.',
  link: 'https://www.amazon.com/Effective-Objective-C-2-0-Specific-Development/dp/0321917014',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
twosLink: '',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: ''
};

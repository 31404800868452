export default {
  author: 'Brian W. Kernighan',
  category: 'book',
  date: '2/11/18',
  link: 'https://amzn.to/2lwAWoS',
  name: 'The Elements of Programming Style',
  path: '/notes/the-elements-of-programming-style',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-elements-of-programming-style-brian-kernighan.jpg',
  tags: ['Software Development'],
  time: 4,
  rating: 7.3,
  review:
    'Good tips on how to write readible and efficient code. Always something to learn and aspire to do better.'
};

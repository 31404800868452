export default {
  author: 'Jiddu Krishnamurti',
  category: 'book',
  date: '4/29/18',
  link: 'https://amzn.to/2l78z0d',
  name: 'Think on These Things',
  path: '/notes/think-on-these-things',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-think-on-these-things-jiddu-krishnamurti.jpg',
  tags: ['Philosophy'],
  time: 74,
  rating: 8.6,
  review:
    "Huge collection of lessons and topics about life, how we go about it, and how we can improve and be at peace. We create our own problems, and don't listen to the nature of the world."
};

import initialState from './initialState';
import ActionTypes from '../actions/actionTypes';

const errorReducer = (state = initialState.error, action) => {
  switch (action.type) {
    case ActionTypes.CHANGE_EMAIL:
    case ActionTypes.CHANGE_BODY:
    case ActionTypes.CHANGE_SUBJECT:
    case ActionTypes.CLEAR_ERROR:
    case ActionTypes.SEND_EMAIL_REQUEST:
    case ActionTypes.SEND_EMAIL_SUCCESS:
    case ActionTypes.SIGN_UP_REQUEST:
    case ActionTypes.SIGN_UP_SUCCESS:
      return '';

    case ActionTypes.SET_ERROR:
    case ActionTypes.SEND_EMAIL_FAILURE:
    case ActionTypes.SIGN_UP_FAILURE:
      return action.error;

    default:
      return state;
  }
};

export default errorReducer;

export default {
  author: 'Robin Sharma',
  category: 'book',
  date: '4/29/17',
  link: 'https://amzn.to/2k1cnQr',
  name: 'The Monk Who Sold His Ferrari',
  path: '/notes/the-monk-who-sold-his-ferrari',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-monk-who-sold-his-ferrari-robin-sharma.jpg',
  tags: ['Fiction', 'Spirituality'],
  time: 22,
  rating: 8.5,
  review:
    'One of my favorite books. A story about a monk who comes back and tries to teach his friend. Great title, great lessons.',
    mediumLink: '',
    patreonLink: '',
    podcastLink: 'https://anchor.fm/parkerklein/episodes/The-Monk-Who-Sold-His-Ferrari-by-Robin-Sharma-efa9sm',
    podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/The-Monk-Who-Sold-His-Ferrari-by-Robin-Sharma-efa9sm',
    youtubeLink: 'https://youtu.be/-CG9gAA2lq0',
    youtubeEmbed: 'https://www.youtube.com/embed/-CG9gAA2lq0',
    releaseDate: '6/22/20'
};

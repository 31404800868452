export default {
  author: 'Frederick P. Brooks Jr.',
  category: 'book',
  date: '2/25/18',
  link: 'https://amzn.to/2luKnFh',
  name: 'Mythical Man Month',
  path: '/notes/mythical-man-month',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-mythical-man-month-frederick-brooks-jr.jpg',
  tags: ['Management', 'Software Development'],
  time: 1,
  mediumLink: '',
  patreonLink: '',
  podcastLink:
    'https://anchor.fm/parkerklein/episodes/Mythical-Man-Month-by-Frederick-P--Brooks-Jr-e7pi87',
  podcastEmbed:
    'https://anchor.fm/parkerklein/embed/episodes/Mythical-Man-Month-by-Frederick-P--Brooks-Jr-e7pi87',
  youtubeLink: 'https://youtu.be/VjNLopIn5lY',
  youtubeEmbed: 'https://www.youtube.com/embed/VjNLopIn5lY',
  releaseDate: '10/16/19',
  rating: 7.7,
  review:
    'Short book on managing software projects and understanding how team size effects timing. Required reading for anyone managing a software team or project.'
};

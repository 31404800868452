import initialState from './initialState';
import ActionTypes from '../actions/actionTypes';

const signedUpReducer = (state = initialState.signedUp, action) => {
  switch (action.type) {
    case ActionTypes.SIGN_UP_SUCCESS:
      return true;

    default:
      return state;
  }
};

export default signedUpReducer;

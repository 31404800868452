export default {
  category: 'write',
  date: '10/3/19',
  name: 'Heaven',
  path: '/write/heaven',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-heaven.jpg',
  podcastLink: '',
  youtubeLink: '',
twosLink: '',
  mediumLink: 'https://parkerklein.medium.com/heaven-5b6e2f26a4e1',
  patreonLink: '',
  tags: ['Write'],
  time: 1
};

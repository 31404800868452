import React, { useState } from 'react';
import { FaAmazon, FaMedium, FaPatreon, FaPodcast, FaYoutube } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import Helmet from '../components/Helmet';
import { videos } from '../data';
import { sortReleaseDate } from '../helpers';

const VideosPage = () => {
  const videosCollection = [];
  videos.map(video => videosCollection.push(`${video.name}`));

  const filteredVideos = videos.sort(sortReleaseDate);

  const [show, setShow] = useState(0);

  return (
    <main className="main-content">
      <ReactTooltip effect="solid" />
      <Helmet
        description={`Parker Klein's YouTube videos. Videos on ${videosCollection.join(', ')}`}
        title="Videos"
      />
      {/* <h2 className="title">Videos</h2> */}
      <ul aria-labelledby="write-header">
        {filteredVideos.map((video, index) => (
          <li
            className="list-item column"
            key={video.path}
            itemScope
            itemType="http://schema.org/Book"
          >
            {index <= show ? (
              <iframe
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                frameBorder="0"
                height="160"
                id={video.youtubeEmbed}
                src={video.youtubeEmbed}
                title={video.name}
                width="288"
                onLoad={() => {
                  if (show < filteredVideos.length) setShow(show + 1);
                }}
              />
            ) : (
              <div style={{ height: 209, width: 343 }} />
            )}
            <Link
              aria-label={`${video.name} by ${video.author}`}
              to={video.path}
              title={`${video.name} by ${video.author}`}
            >
              <h2 className="title" itemProp="name">
                {video.name}
              </h2>
              <h3 className="author" itemProp="author">
                {video.author}
              </h3>
              <div className="date" itemProp="dateCreated">
                {video.releaseDate}
              </div>
              <meta itemProp="genre" content={video.tags.join(', ')} />
              <meta itemProp="url" content={`https://www.parkerklein.com${video.path}`} />
            </Link>
            <div className="row">
              {video.twosLink && (
                <a
                  aria-label={`Read ${video.name} by ${video.author} on Twos`}
                  className="icon-link twos"
                  data-tip={`${video.name} on Twos`}
                  href={video.twosLink}
                  itemProp="url"
                  rel="noopener noreferrer"
                  target="_blank"
                  title={`Read ${video.name} by ${video.author} on Twos`}
                >
                  <img
                    alt="Twos: days, lists, & things"
                    src="https://twos.s3.amazonaws.com/twos-thread-background-transparent-300.png"
                    height="28px"
                    width="20px"
                  />
                </a>
              )}
              {video.youtubeLink && (
                <a
                  aria-label={`Watch Parker Klein's Notes on ${video.name} by ${video.author} on YouTube`}
                  className="icon-link youtube"
                  data-tip={`Watch ${video.name} on YouTube`}
                  href={video.youtubeLink}
                  itemProp="url"
                  rel="noopener noreferrer"
                  target="_blank"
                  title={`Watch Parker Klein's Notes on ${video.name} by ${video.author} on YouTube`}
                >
                  <FaYoutube />
                </a>
              )}
              {video.patreonLink && (
                <a
                  aria-label={`Support Parker Klein's notes on ${video.name} by ${video.author} on Patreon`}
                  className="icon-link patreon"
                  data-tip={`${video.name} on Patreon`}
                  href={video.patreonLink}
                  itemProp="url"
                  rel="noopener noreferrer"
                  target="_blank"
                  title={`Support Parker Klein's notes on ${video.name} by ${video.author} on Patreon`}
                >
                  <FaPatreon />
                </a>
              )}
              {video.link && (
                <a
                  aria-label={`Buy ${video.name} by ${video.author} on Amazon`}
                  className="icon-link amazon"
                  data-tip={` Buy ${video.name} on Amazon`}
                  href={video.link}
                  itemProp="url"
                  rel="noopener noreferrer"
                  target="_blank"
                  title={`Buy ${video.name} by ${video.author} on Amazon`}
                >
                  <FaAmazon />
                </a>
              )}
              {video.podcastLink && (
                <a
                  aria-label={`Listen to Parker Klein's Notes podcast on ${video.name} by ${video.author}`}
                  className="icon-link podcast"
                  data-tip={`Listen to ${video.name}`}
                  href={video.podcastLink}
                  itemProp="url"
                  rel="noopener noreferrer"
                  target="_blank"
                  title={`Listen to Parker Klein's Notes podcast on ${video.name} by ${video.author}`}
                >
                  <FaPodcast />
                </a>
              )}
              {video.mediumLink && (
                <a
                  aria-label={`Read Parker Klein's notes on ${video.name} by ${video.author} on Medium`}
                  className="icon-link medium"
                  data-tip={`Read ${video.name} on Medium`}
                  href={video.mediumLink}
                  itemProp="url"
                  rel="noopener noreferrer"
                  target="_blank"
                  title={`Read Parker Klein's notes on ${video.name} by ${video.author} on Medium`}
                >
                  <FaMedium />
                </a>
              )}
            </div>
          </li>
        ))}
      </ul>
    </main>
  );
};

export default VideosPage;

export default {
  category: 'book',
  name: 'Hatching Twitter',
  author: 'Nick Bilton',
  date: '1/28/24',
  path: '/notes/hatching-twitter',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-hatching-twitter-nick-bilton.jpg',
  tags: ['Business', 'Leadership', 'Biography', 'Management', 'Technology'],
  time: 7,
  rating: 8.2,
  review: '',
  link: 'https://www.amazon.com/Hatching-Twitter-Story-Friendship-Betrayal/dp/1591847087',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  twosLink: 'https://www.TwosApp.com/65a0ad79e8bb8763226c8e09',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};

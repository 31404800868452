export default {
  category: 'write',
  date: '11/4/19',
  name: 'Goals for 26',
  path: '/write/goals-for-26',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-goals-for-26.jpg',
  podcastLink: '',
  youtubeLink: '',
twosLink: '',
  mediumLink: '',
  patreonLink: '',
  tags: ['Write'],
  time: 1
};

export default {
  category: 'book',
  name: 'Already Free',
  author: 'Bruce Tift',
  date: '11/23/21',
  path: '/notes/already-free',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-already-free-bruce-tift.jpg',
  tags: ['Psychology', 'Buddhism'],
  time: 12,
  rating: 6.5,
  review: '',
  link: 'https://www.amazon.com/Already-Free-Buddhism-Psychotherapy-Liberation/dp/1622034112',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
twosLink: 'https://www.TwosApp.com/6170e8e1c297a78bd315ebb2',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: ''
};

export default {
  category: 'book',
  name: 'The Ruthless Elimination of Hurry',
  author: 'John Mark Comer',
  date: '2/26/23',
  path: '/notes/the-ruthless-elimination-of-hurry',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-ruthless-elimination-of-hurry-john-mark-comer.jpg',
  tags: ['Christianity', 'Self-help'],
  time: 14,
  rating: 8.5,
  review: '',
  link: 'https://www.amazon.com/Ruthless-Elimination-Hurry-Emotionally-Spiritually/dp/0525653090',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  twosLink: 'https://www.twosapp.com/63dbb8f5b310579a78531df7',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};

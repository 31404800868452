export default {
  category: 'book',
  name: 'The Culture Code',
  author: 'Clotaire Rapaille',
  date: '3/3/24',
  path: '/notes/the-culture-code',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-culture-code-clotaire-rapaille.jpg',
  tags: ['Business', 'Money', 'Marketing'],
  time: 40,
  rating: 8.6,
  review: '',
  link: 'https://www.amazon.com/Culture-Code-Ingenious-Understand-People/dp/0767920570',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  twosLink: 'https://www.twosapp.com/65d7fa76b3b36d0659855e54',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};

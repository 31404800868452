export default {
  author: 'William Golding',
  category: 'book',
  date: '11/25/19',
  name: 'Lord of the Flies',
  path: '/notes/lord-of-the-flies',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-load-of-the-flies-william-golding.jpg',
  tags: ['Fiction'],
  time: 1,
  link: 'https://www.amazon.com/Lord-Flies-William-Golding/dp/0399501487',
  mediumLink: '',
  patreonLink: '',
  podcastLink: '',
  podcastEmbed: '',
  youtubeLink: '',
twosLink: '',
  youtubeEmbed: '',
  releaseDate: '',
  rating: 5.5,
  review: 'A great story about boys stranded on an island and their struggle with power and fear.'
};

export default {
  author: 'Carol Dweck',
  category: 'book',
  date: '1/1/19',
  link: 'https://amzn.to/2lwtGcB',
  name: 'Mindset',
  path: '/notes/mindset',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-mindset-carol-dweck.jpg',
  tags: ['Psychology'],
  time: 12,
  rating: 8.5,
  review:
    'A book about the difference between a fixed and a growth mindset and the benefits of a growth mindset. Worth reading if you are unfamiliar with the concept or if you believe your mindset may be fixed.',
  mediumLink: 'https://parkerklein.medium.com/mindset-by-carol-dweck-9625004d9b84',
  patreonLink: '',
  podcastLink: 'https://anchor.fm/parkerklein/episodes/Mindset-by-Carol-Dweck-ebd22c',
  podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/Mindset-by-Carol-Dweck-ebd22c',
  twosLink: 'https://www.twosapp.com/5c2bb77a47328234cef6d758',
  youtubeLink: 'https://youtu.be/97a7k_wyp4E',
  youtubeEmbed: 'https://www.youtube.com/embed/97a7k_wyp4E',
  releaseDate: '3/9/20'
};

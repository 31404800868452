export default {
  author: 'Sally Field',
  category: 'book',
  date: '4/12/19',
  link: 'https://amzn.to/2lhaaAI',
  name: 'In Pieces',
  path: '/notes/in-pieces',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-in-pieces-sally-field.jpg',
  tags: ['Biography', 'Memoir'],
  time: 14,
  rating: 6.8,
  review:
    'The story of Sally Field. An actress with a tough childhood and her struggle to become successful.'
};

export default {
  author: 'Jake Knapp',
  category: 'book',
  date: '4/23/19',
  link: 'https://amzn.to/30uglAw',
  name: 'Sprint',
  path: '/notes/sprint',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-sprint-jake-knapp.jpg',
  tags: ['Business', 'Management', 'Leadership', 'Product Design'],
  twosLink: 'https://www.TwosApp.com/5cbf66d4d1ba641383dc622c',
  mediumLink: 'https://parkerklein.medium.com/sprint-by-jake-knapp-547603ce86e4',
  time: 5,
  rating: 5.8,
  review:
    'A book about getting to solutions for a problem in one week. Walks you through the exercise any company to implement to come up with solutions.'
};

export default {
  author: 'Walter Issacson',
  category: 'book',
  date: '11/16/19',
  name: 'Benjamin Franklin',
  path: '/notes/benjamin-franklin',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-benjamin-franklin-walter-issacson.jpg',
  tags: ['Biography', 'Leadership', 'History'],
  time: 8,
  link: 'https://www.amazon.com/Benjamin-Franklin-American-Walter-Isaacson/dp/074325807X',
  mediumLink: 'https://parkerklein.medium.com/benjamin-franklin-by-walter-issacson-f08407baf35f',
  patreonLink: '',
  podcastLink: '',
  podcastEmbed: '',
  youtubeLink: '',
twosLink: '',
  youtubeEmbed: '',
  releaseDate: '',
  rating: 4.8,
  review:
    'Benjamin Franklin is a beast. Dude worked forever, super creative, founding father of our country. He was an entreprenuer at heart and had his hand inn everything. Very long book.'
};

export default {
  category: 'book',
  name: "Why We're Polarized",
  author: 'Ezra Klein',
  date: '7/14/22',
  path: '/notes/why-were-polarized',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-why-were-polarized-ezra-klein.jpg',
  tags: ['Politics'],
  time: 3,
  rating: 6.6,
  review: '',
  link: 'https://www.amazon.com/Why-Were-Polarized-Ezra-Klein/dp/147670032X',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  twosLink: 'https://www.TwosApp.com/6292c6b9750bad0a7116ba7b',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};

export default {
  author: 'Seth Godin',
  category: 'book',
  date: '12/4/19',
  name: 'The Dip',
  path: '/notes/the-dip',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-dip-seth-godin.jpg',
  tags: ['Business', 'Management', 'Productivity'],
  time: 2,
  link: 'https://www.amazon.com/Dip-Little-Book-Teaches-Stick/dp/1591841666',
  rating: 6.6,
  review:
    'Short book on whether or not you should stick with ideas or let them go. Everyone is going to go through a dip, if you keep going it has a better chance of success.',
  // mediumLink: '',
  // patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
twosLink: 'https://www.TwosApp.com/5de5b041ebe5af4a51181762',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  // releaseDate: ''
};

export default {
  category: 'book',
  name: 'This is Marketing',
  author: 'Seth Godin',
  date: '8/22/21',
  path: '/notes/this-is-marketing',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-this-is-marketing-seth-godin.jpg',
  tags: ['Marketing', 'Business', 'Sales'],
  time: 9,
  rating: 7.8,
  review: '',
  link: 'https://www.amazon.com/This-Marketing-Cant-Until-Learn/dp/0525540830',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
twosLink: 'https://www.TwosApp.com/60ff56f8bd4ceb27862f4e11',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: ''
};

export default {
  author: 'Clayton Christensen',
  category: 'book',
  date: '4/9/18',
  link: 'https://amzn.to/2SbRb75',
  name: "The Innovator's Dilemma",
  path: '/notes/the-innovators-dilemma',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-innovators-dilemma-clayton-christensen.jpg',
  tags: ['Business', 'Management', 'Leadership'],
  time: 5,
  rating: 5.3,
  review:
    'A book about technology disruption. What causes it and why. How to look out for it and how to prepare for it.'
};

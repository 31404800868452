export default {
  author: 'Pema Chodron',
  category: 'book',
  date: '8/18/18',
  link: 'https://amzn.to/2XUic09',
  name: 'When Things Fall Apart',
  path: '/notes/when-things-fall-apart',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-when-things-fall-apart-pema-chodron.jpg',
  tags: ['Buddhism', 'Self-help'],
  time: 49,
  rating: 8.3,
  review:
    'Book about dealing with failure and pain. Shows you how to overcome fear by explaining what it really is. It is all part of the path of life and they are what make life what it is.'
};

export default {
  category: 'write',
  date: '10/20/19',
  name: "When You're Feeling Down",
  path: '/write/when-youre-feeling-down',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-when-youre-feeling-down.png',
  podcastLink: '',
  youtubeLink: '',
twosLink: '',
  mediumLink: '',
  patreonLink: '',
  tags: ['Write'],
  time: 1
};

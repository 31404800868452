export default {
  author: 'Jules Marcoux',
  category: 'book',
  date: '4/12/16',
  name: 'The Marketing Blueprint',
  path: '/notes/the-marketing-blueprint',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-marketing-blueprint-jules-marcoux.jpg',
  tags: ['Business', 'Sales', 'Marketing'],
  time: 0,
  link:
    'https://www.amazon.com/Marketing-Blueprint-Lessons-Market-Anything/dp/1514625768/ref=tmm_pap_swatch_0?_encoding=UTF8&qid=&sr=',
  mediumLink: '',
  patreonLink: '',
  podcastLink: '',
  podcastEmbed: '',
  youtubeLink: '',
twosLink: '',
  youtubeEmbed: '',
  releaseDate: '',
  rating: 5.1,
  review:
    "Short book on marketing tactics. Don't remember any of the concepts, but I may revisit soon."
};

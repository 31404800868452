export default {
  category: 'write',
  date: '7/16/17',
  name: "Beginner's Mind",
  path: '/write/beginners-mind',
  photo: 'https://twos.s3.amazonaws.com/parker-klein-beginners-mind.jpg',
  podcastLink: '',
  youtubeLink: '',
twosLink: '',
  mediumLink: '',
  patreonLink: '',
  tags: ['Write'],
  time: 1
};

import initialState from './initialState';
import ActionTypes from '../actions/actionTypes';

const subjectReducer = (state = initialState.subject, action) => {
  switch (action.type) {
    case ActionTypes.CHANGE_SUBJECT:
      return action.subject;

    case ActionTypes.SEND_EMAIL_SUCCESS:
    case ActionTypes.RESET_EMAIL:
      return '';

    default:
      return state;
  }
};

export default subjectReducer;

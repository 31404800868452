export default {
  category: 'book',
  name: 'The Doors of Perception',
  author: 'Aldous Huxley',
  date: '8/29/21',
  path: '/notes/the-doors-of-perception',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-doors-of-perception-aldous-huxley.jpg',
  tags: ['Medicine', 'Philosophy'],
  time: 6,
  rating: 7.2,
  review: '',
  link: 'https://www.amazon.com/Doors-Perception-Heaven-Hell/dp/0061729078',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
twosLink: 'https://www.TwosApp.com/6129b9e503f63e286ccbb876',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: ''
};

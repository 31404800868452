export default {
  author: 'Tim Ferriss',
  category: 'book',
  date: '11/3/19',
  name: 'Tools of Titans',
  path: '/notes/tools-of-titans',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-tools-of-titans-tim-ferriss.jpg',
  tags: ['Business', 'Money', 'Health', 'Leadership'],
  time: 17,
  link: 'https://www.amazon.com/Tools-Titans-Billionaires-World-Class-Performers/dp/1328683788',
  mediumLink: '',
  patreonLink: '',
  podcastLink: '',
  podcastEmbed: '',
  youtubeLink: '',
twosLink: '',
  youtubeEmbed: '',
  releaseDate: '',
  rating: 7.1,
  review:
    'Big collection of tips, habits, quotes, and lessons from top performers, business people, and creators. Love learning from a wide variety of people and hearing what they enjoy and do.'
};

export default {
  category: 'book',
  name: 'Four Thousand Weeks',
  author: 'Oliver Burkeman',
  date: '10/4/23',
  path: '/notes/four-thousand-weeks',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-four-thousand-weeks-oliver-burkeman.jpg',
  tags: ['Self-help', 'Philosophy', 'Death', 'Mental health', 'Productivity'],
  time: 18,
  rating: 5.5,
  review: '',
  link: 'https://www.amazon.com/Four-Thousand-Weeks-Management-Mortals/dp/0374159122',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  twosLink: 'https://www.twosapp.com/650f0e1b54c129add73486e2',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};

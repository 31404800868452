export default {
  author: 'Brian Christian',
  category: 'book',
  date: '8/10/17',
  name: 'Algorithms to Live By',
  path: '/notes/algorithms-to-live-by',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-algorithms-to-live-by-brian-christian.jpg',
  tags: ['Science', 'Psychology', 'Computer Science'],
  time: 0,
  link: 'https://www.amazon.com/Algorithms-Live-Computer-Science-Decisions/dp/1627790365',
  mediumLink: '',
  patreonLink: '',
  podcastLink: '',
  podcastEmbed: '',
  youtubeLink: '',
twosLink: '',
  youtubeEmbed: '',
  releaseDate: '',
  rating: 3.2,
  review:
    'Interesting book on how computer programming algorithms show up in our lives and how we can use them to optimize our decisions. I got pretty bored. Could be for you.'
};

export default {
  author: 'Timothy Gallwey',
  category: 'book',
  date: '2/12/17',
  link: 'https://amzn.to/2k1RwfS',
  name: 'The Inner Game of Tennis',
  path: '/notes/the-inner-game-of-tennis',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-inner-game-of-tennis-timothy-gallwey.jpg',
  podcastLink:
    'https://anchor.fm/parkerklein/episodes/The-Inner-Game-of-Tennis-by-W--Timothy-Gallwey--Parker-Kleins-Notes--Book-Notes-e50k58',
  podcastEmbed:
    'https://anchor.fm/parkerklein/embed/episodes/The-Inner-Game-of-Tennis-by-W--Timothy-Gallwey--Parker-Kleins-Notes--Book-Notes-e50k58',
  tags: ['Sports', 'Self-help', 'Psychology'],
  time: 2,
  youtubeLink: 'https://youtu.be/GwZ9gUc4CzY',
  youtubeEmbed: 'https://www.youtube.com/embed/GwZ9gUc4CzY',
  releaseDate: '8/18/19',
  rating: 7.8,
  review:
    'Book about getting out of your own way. Trusting in your ability and skill to do what it knows how to do. Still your mind and perform naturally. Can be applied to all aspects of life and will bring you peace and presence.'
};

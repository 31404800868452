export default {
  category: 'book',
  name: 'Zen to Done',
  author: 'Leo Babauta',
  date: '11/22/22',
  path: '/notes/zen-to-done',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-zen-to-done-leo-babauta.jpg',
  tags: ['Business', 'Productivity', 'Management', 'Self-help'],
  time: 12,
  rating: 8.2,
  review: '',
  link: 'https://www.amazon.com/Zen-Done-Ultimate-Simple-Productivity/dp/1434103188',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  twosLink: 'https://www.twosapp.com/637cb3ef7045664640cb43d0',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};

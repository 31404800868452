export default {
  author: 'Seung Sahn',
  category: 'book',
  date: '4/21/19',
  link: 'https://amzn.to/2lgGvYp',
  name: 'Dropping Ashes on the Buddha',
  path: '/notes/dropping-ashes-on-the-buddha',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-dropping-ashes-on-the-buddha-seung-sahn.jpg',
  tags: ['Religion', 'Spirituality', 'Buddhism'],
  time: 36,
  rating: 6.8,
  review:
    'Fun book written from the perspective of a buddhist monk teaching his student and the conversations and letters they exchanged. Discusses buddist philosophy.'
};

export default {
  author: 'Robert Martin',
  category: 'book',
  date: '11/24/19',
  name: 'Clean Code',
  path: '/notes/clean-code',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-clean-code-robert-martin.jpg',
  tags: ['Programming', 'Software Development'],
  time: 5,
  link: 'https://www.amazon.com/Clean-Code-Handbook-Software-Craftsmanship/dp/0132350882',
  mediumLink: '',
  patreonLink: '',
  podcastLink: '',
  podcastEmbed: '',
  youtubeLink: '',
twosLink: '',
  youtubeEmbed: '',
  releaseDate: '',
  rating: 6.8,
  review:
    'Book about how to keep your code clean, lol. A lot of good stuff around formatting and naming. Best practices to set up when working with a team. Best advice was to think long term so you and your team members will be able to understand what you write in the future.'
};

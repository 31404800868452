export default {
  author: 'Cormac McCarthy',
  category: 'book',
  date: '5/20/18',
  link: 'https://amzn.to/2lsCPTn',
  name: 'The Road',
  path: '/notes/the-road',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-road-cormac-mccarthy.jpg',
  podcastLink:
    'https://anchor.fm/parkerklein/episodes/The-Road-by-Cormac-McCarthy--Parker-Kleins-Notes--Book-Notes-e4vdtb',
  podcastEmbed:
    'https://anchor.fm/parkerklein/embed/episodes/The-Road-by-Cormac-McCarthy--Parker-Kleins-Notes--Book-Notes-e4vdtb',
  tags: ['Fiction'],
  time: 2,
  youtubeLink: 'https://youtu.be/D7Rf0pEw0Xw',
  youtubeEmbed: 'https://www.youtube.com/embed/D7Rf0pEw0Xw',
  releaseDate: '8/14/19',
  rating: 8.6,
  review: 'Great story about a father and his son as they attempt to survive and zombie apocolypse.'
};

export default {
  category: 'book',
  name: 'Quiet',
  author: 'Susan Cain',
  date: '4/19/24',
  path: '/notes/quiet',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-quiet-susan-cain.jpg',
  tags: ['Self-help', 'Relationships', 'Psychology', 'Leadership'],
  time: 32,
  rating: 8.1,
  review: '',
  link: 'https://www.amazon.com/Quiet-Power-Introverts-World-Talking/dp/0307352153',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  twosLink: 'https://www.twosapp.com/6607f9e1eb85b9a136ea23b1',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};

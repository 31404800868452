export default {
  category: 'write',
  date: '9/13/19',
  name: 'Consistency',
  path: '/write/consistency',
  photo: 'https://twos.s3.amazonaws.com/parker-klein-consistency.png',
  podcastLink: '',
  youtubeLink: '',
twosLink: '',
  mediumLink: 'https://parkerklein.medium.com/consistency-fe9e426eb371',
  patreonLink: '',
  tags: ['Write'],
  time: 1
};

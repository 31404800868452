export default {
  category: 'book',
  name: 'Dopamine Nation',
  author: 'Anna Lembke ',
  date: '3/15/23',
  path: '/notes/dopamine-nation',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-dopamine-nation-anna-lembke.jpg',
  tags: ['Health', 'Diet', 'Psychology', 'Medicine'],
  time: 6,
  rating: 8.6,
  review: '',
  link: 'https://www.amazon.com/Dopamine-Nation-Finding-Balance-Indulgence/dp/152474672X',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  twosLink: 'https://www.TwosApp.com/@Parker/Dopamine-Nation-by-Anna-Lembke-',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};

export default {
  author: 'Charles Duhigg',
  category: 'book',
  date: '7/12/16',
  name: 'The Power of Habit',
  path: '/notes/the-power-of-habit',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-power-of-habit-charles-duhigg.jpg',
  tags: ['Health', 'Psychology'],
  time: 0,
  link: 'https://www.amazon.com/Power-Habit-What-Life-Business/dp/081298160X',
  mediumLink: '',
  patreonLink: '',
  podcastLink: '',
  podcastEmbed: '',
  youtubeLink: '',
twosLink: '',
  youtubeEmbed: '',
  releaseDate: '',
  rating: 7.8,
  review:
    "How to stick to habits. Actually works and explains why you can or can't get habits to stick."
};

export default {
  category: 'write',
  date: '8/19/17',
  name: 'Be',
  path: '/write/be',
  photo: 'https://twos.s3.amazonaws.com/parker-klein-be.jpg',
  podcastLink: '',
  youtubeLink: '',
twosLink: '',
  mediumLink: '',
  patreonLink: '',
  tags: ['Write'],
  time: 1
};

export default {
  date: '3/9/18',
  author: 'Jim Collins',
  category: 'book',
  link: 'https://amzn.to/2lfheOf',
  name: 'Good to Great',
  path: '/notes/good-to-great',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-good-to-great-jim-collins.jpg',
  tags: ['Business', 'Leadership'],
  time: 9,
  twosLink: 'https://www.TwosApp.com/5aa338c7bc5c200004877511',
  rating: 7.9,
  review:
    "Really like Jim Collins. Thought this book was better than another one of his, 'Built to Last.' Talks about what companies that last long and dominate their industries did different than their competitors. Talks about being a great leader, motivating people, and the meaning behind your action."
};

export default {
  author: 'Peter Thiel',
  category: 'book',
  date: '5/14/19',
  link: 'https://amzn.to/2lATbcN',
  name: 'Zero to One',
  path: '/notes/zero-to-one',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-zero-to-one-peter-thiel.jpg',
  tags: ['Business'],
  time: 9,
  rating: 7.9,
  review:
    'An opinionated book on running a start up. My favorite book on the topic. How to create value, how to build a great product, how to stay productive, and how to launch.'
};

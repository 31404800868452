export default {
  category: 'book',
  name: 'Courage Is Calling',
  author: 'Ryan Holiday',
  date: '11/3/22',
  path: '/notes/courage-is-calling',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-courage-is-calling-ryan-holiday.jpg',
  tags: ['Business', 'Leadership', 'Philosophy', 'Management', 'Self-help'],
  time: 13,
  rating: 8.1,
  review: '',
  link: 'https://www.amazon.com/Courage-Calling-Fortune-Favors-Brave/dp/0593191676',
  mediumLink: 'https://parkerklein.medium.com/courage-is-calling-by-ryan-holiday-1a49c91b6fe6',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  twosLink: 'https://www.twosapp.com/632a7d76ba97a857bc9e62db',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};

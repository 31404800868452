export default {
  author: 'Thomas H. Cormen',
  category: 'book',
  date: '8/20/17',
  link: 'https://www.amazon.com/Introduction-Algorithms-3rd-MIT-Press/dp/0262033844',
  mediumLink: '',
  name: 'Introduction to Algorithms',
  path: '/notes/introduction-to-algorithms',
  patreonLink: '',
  photo:
    'https://twos.s3.amazonaws.com/parkerklein/parker-klein-introduction-to-algorithms-thomas-h-cormen.jpg',
  podcastLink: '',
  podcastEmbed: '',
  tags: ['Computer Science', 'Programming'],
  time: 6,
  youtubeLink: '',
twosLink: '',
  youtubeEmbed: '',
  releaseDate: '',
  rating: 6.7,
  review:
    'Very deep dive into software algorithms. Explains all the basics very well and will tie in tradeoffs and examples to help you learn. I read this while preparing for job interviews and found the repitition to be very helpful for solidifying key concepts.'
};

export default {
  author: 'Duncan Clark',
  category: 'book',
  date: '11/22/18',
  link: 'https://amzn.to/2l6MTkV',
  name: 'Alibaba',
  path: '/notes/alibaba',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-alibaba-duncan-clark.jpg',
  tags: ['Biography'],
  time: 1,
  mediumLink: 'https://parkerklein.medium.com/alibaba-by-duncan-clark-25c1811a65a1',
  patreonLink: '',
  podcastLink: 'https://anchor.fm/parkerklein/episodes/Alibaba-by-Duncan-Clark-e91f2a',
  podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/Alibaba-by-Duncan-Clark-e91f2a',
  youtubeLink: 'https://youtu.be/2hzvohcqkXM',
  youtubeEmbed: 'https://www.youtube.com/embed/2hzvohcqkXM',
  releaseDate: '11/16/19',
  rating: 6.8,
  review:
    'Jack Ma is a savage. Dude is just relentless. Worked his butt off. Build a powerhouse against adversity.'
};

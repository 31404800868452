export default {
  category: 'book',
  name: 'The 4-Hour Chef',
  author: 'Tim Ferriss',
  date: '6/12/20',
  path: '/notes/the-4-hour-chef',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-4-hour-chef-tim-ferriss.jpg',
  tags: ['Cooking'],
  time: 2,
  rating: 5.4,
  review: '',
  link: 'https://www.amazon.com/4-Hour-Chef-Cooking-Learning-Anything/dp/0547884591',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
twosLink: '',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: ''
};

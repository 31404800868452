import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { FaAppStore, FaGooglePlay } from 'react-icons/fa';
import { FiExternalLink } from 'react-icons/fi';
import ReactTooltip from 'react-tooltip';

import Helmet from '../components/Helmet';
import { work } from '../data';
import { formatDate } from '../helpers';

const Job = ({ location }) => {
  const { pathname } = location;

  const job = work.find(job => job.path === pathname) || {};

  return (
    <main className="main-content" itemScope itemType="http://schema.org/Organization">
      <ReactTooltip effect="solid" />
      <Helmet
        description={`${job.name} - ${job.tagLine}. ${job.description}`}
        title={`${job.name} - ${job.position}`}
      />
      <div className="column">
        <div className="item mobile-column">
          <img
            className="photo"
            alt={job.name}
            height={200}
            itemProp="logo"
            src={job.logo}
            width={200}
          />
          <div className="column">
            <h2 className="title" itemProp="name">
              {job.name}
            </h2>
            <h3 className="author">{job.position}</h3>
            <h4 className="time">{job.location}</h4>
            <p className="date">
              {`${formatDate(job.startDate)} - ${job.endDate
                ? formatDate(job.endDate)
                : 'Present'}`}
            </p>
            <div className="row">
              {job.url && (
                <a
                  aria-label={`${job.name} website`}
                  className="icon-link"
                  data-tip={`${job.name}'s website`}
                  href={job.url}
                  id="url"
                  itemProp="url"
                  rel="noopener noreferrer"
                  target="_blank"
                  title={`${job.name} website`}
                >
                  <FiExternalLink />
                </a>
              )}
              {job.iOSUrl && (
                <a
                  aria-label={`${job.name} on the iOS App Store`}
                  className="icon-link app-store"
                  data-tip={`Download ${job.name}`}
                  href={job.url}
                  id="iOSAppStoreUrl"
                  itemProp="url"
                  rel="noopener noreferrer"
                  target="_blank"
                  title={`${job.name} on the iOS App Store`}
                >
                  <FaAppStore />
                </a>
              )}
              {job.androidUrl && (
                <a
                  aria-label={`${job.name} on the Google Play Store`}
                  className="icon-link play-store"
                  data-tip={`Download ${job.name}`}
                  href={job.url}
                  id="googlePlayUrl"
                  itemProp="url"
                  rel="noopener noreferrer"
                  target="_blank"
                  title={`${job.name} on the Google Play Store`}
                >
                  <FaGooglePlay />
                </a>
              )}
            </div>
            <meta itemProp="author" content="Parker Klein" />
          </div>
        </div>
        <div>
          <p className="note">{job.accomplishments.join(' ')}</p>
          <p className="note">Tech: {job.tech.join(', ')}</p>
        </div>
      </div>
    </main>
  );
};

Job.propTypes = {
  location: PropTypes.object.isRequired
};

export default withRouter(Job);

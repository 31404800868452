export default {
  author: 'Sir Edwin Arnold',
  category: 'book',
  date: '5/14/18',
  link: 'https://amzn.to/2Jz6ynr',
  name: 'Bhagavad Gita',
  path: '/notes/bhagavad-gita',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-bhagavad-gita-edwin-arnold.jpg',
  tags: ['Religion', 'Spirituality', 'Hinduism'],
  time: 43,
  rating: 6.9,
  review:
    'Great book for spirituality. Makes you feel connected to everything and helps you to see the onesness of nature and the world. Everything is connected and helps you see how your small role is still meaningful.'
};

export default {
  category: 'book',
  name: 'Calypso',
  author: 'David Sedaris',
  date: '12/20/19',
  path: '/notes/calypso',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-calypso-david-sedaris.jpg',
  tags: ['Humor', 'Autobiography'],
  time: 1,
  rating: 7.1,
  review:
    'Wonderfully raw, real, and hilarious. So much to relate to as a human being. Will get you laughing, but leads you to contemplating your eventual death and the life you have left.',
  link: 'https://www.amazon.com/Calypso-David-Sedaris/dp/0316392383',
  mediumLink: '',
  patreonLink: '',
  podcastLink: 'https://anchor.fm/parkerklein/episodes/Calypso-by-David-Sedaris-ea1cge',
  podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/Calypso-by-David-Sedaris-ea1cge',
  youtubeLink: 'https://youtu.be/ptSrtM5Ukco',
  youtubeEmbed: 'https://www.youtube.com/embed/ptSrtM5Ukco',
  releaseDate: '1/6/20'
};

export default {
  category: 'book',
  name: "Don't Worry, Make Money",
  author: 'Richard Carlson',
  date: '8/8/24',
  path: '/notes/dont-worry-make-money',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-dont-worry-make-money-richard-carlson.jpg',
  tags: ['Business', 'Money', 'Finance', 'Self-help'],
  time: 44,
  rating: 7.8,
  review: '',
  link: 'https://www.amazon.com/Dont-Worry-Make-Money-Spiritual/dp/0786863218',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  twosLink: 'https://www.twosapp.com/6661c61d201896d7a4162357',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};
